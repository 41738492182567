import React, {Component} from 'react'
import BtnWhatsapp from './whatsapp'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons"
import {faCheck, faTimes, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons"
import connectWithStore from "../../Stores/connectWithStore"
import {login, logout} from "../../Actions/user"
import {abreModal, alertaErro, setErros, setToast} from "../../Actions/util"
import {patch} from "../../Helpers/request"
import CircularProgress from "@material-ui/core/CircularProgress"
import MaskedInput from "react-text-mask/dist/reactTextMask"
import {maskTelefone} from "../../Helpers/util"

class BtnAddWhatapp extends Component {
	_isMounted = false
	state = {
		loading: false,
		erroMensagem: undefined,
		whatsapp: this.props.user.whatsapp || '',
		showInput: false,
	}

	showInput = (e) => {
		e && e.preventDefault() && e.stopPropagation()
		this.props.abreModal('input', {
			campo: 'whatsapp',
			mask: 'telefone',
			placeholder: 'Seu Whatsapp',
		})
		/*this.setState(() => ({
			showInput: true
		}))*/
	}

	hideInput = (e) => {
		e && e.preventDefault()
		this.setState(() => ({
			showInput: false,
			whatsapp: this.props.user.whatsapp || '',
		}))
	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	onChange = (e) => {
		const obj = {}
		obj[e.target.name] = e.target.value
		this.setState(() => (obj))
	}

	onSubmit = (e) => {
		e && e.preventDefault()

		this.setState(() => ({
			loading: true,
			erroMensagem: undefined,
		}))
		const dados = {
			whatsapp: this.state.whatsapp,
		}
		patch('users', dados)
			.then(({data}) => {
				if (data.status) {
					const user = {...this.props.user}
					user.whatsapp = this.state.whatsapp
					this.props.login(user)
					this._isMounted && this.hideInput()

					if (this.props.erros) {
						const novosErros = Object.assign({}, this.props.erros)
						delete novosErros.whatsapp
						this.props.setErros(novosErros)
					}
				}
				else {
					this._isMounted && this.props.alertaErro("", data.message)
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					this.props.setToast('Sua sessão expirou', 'error')
					this.props.logout()
					return
				}
				//erro controlado
				if (err.response) {
					this._isMounted && this.props.alertaErro("", err.response.data.message)
				}
				//erro aleatório
				else {
					this._isMounted && this.props.alertaErro(err.response && err.response.data.message ? err.response.data.message : err.message)
				}
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})
	}

	render() {
		return (
			<>
				{this.state.loading
					? (<CircularProgress className="circle-loading" size={20}/>)
					: (
						<>
							{!this.props.user.whatsapp && !this.state.showInput &&
								<button
									className="btnAdd btnAdd--whatsapp"
									onClick={this.showInput}>
									<span><FontAwesomeIcon icon={faWhatsapp}/></span>
									Adicionar <br/> número
									{this.props.erros && this.props.erros.whatsapp && (<FontAwesomeIcon icon={faExclamationTriangle}/>)}
								</button>
							}
							{this.state.showInput
								? (
									<div className="show-input-whatsapp">
										<form onSubmit={this.onSubmit}>
											<MaskedInput
												type="text"
												name="whatsapp"
												id="whatsapp_num"
												placeholder="Digite seu número"
												className="inputDefault inputDefault--edicao-dados-pessoais"
												value={this.state.whatsapp}
												mask={maskTelefone}
												guide={false}
												onChange={this.onChange}
												autoFocus
											/>
											<button
												type="submit"
												className="btn-save-input">
												<FontAwesomeIcon icon={faCheck}/>
											</button>
											<button
												type="button"
												className="btn-fechar-input"
												onClick={this.hideInput}>
												<FontAwesomeIcon icon={faTimes}/>
											</button>
										</form>
									</div>
								)
								: (
									this.props.user.whatsapp && !this.state.showInput &&
									<span onClick={this.showInput}>
										<BtnWhatsapp numero={this.props.user.whatsapp}/>
									</span>

								)
							}
						</>
					)
				}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
	erros: state.util.erros,
})

const mapDispatchToProps = (dispatch) => ({
	login: (user) => dispatch(login(user)),
	alertaErro: (alertaTitulo, alertaMensagem) => dispatch(alertaErro(alertaTitulo, alertaMensagem)),
	abreModal: (tipo, modalExtra) => dispatch(abreModal(tipo, modalExtra)),
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
	logout: () => dispatch(logout()),
	setErros: (erros) => dispatch(setErros(erros)),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(BtnAddWhatapp)
