import React, {Component} from 'react'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
	faEnvelope,
	faPhoneAlt,
	faMapMarkerAlt,
	faBirthdayCake,
	faSuitcase,
	faGraduationCap
} from "@fortawesome/free-solid-svg-icons"

import PropTypes from "prop-types"
import moment from "moment"
import {labelDicAuxiliarCandidato} from "../../../Helpers/util"

class InfoBioCandidatoCompartilhado extends Component {
	componentDidMount() {

	}

	render() {
		return (
			<div className="dados">
				<h2>{this.props.user.first_name}</h2>

				<ul>
					<li>
						<FontAwesomeIcon icon={faEnvelope}/>
						<strong>E-mail:</strong>
						{this.props.user.email}
					</li>
					<li>
						<FontAwesomeIcon icon={faPhoneAlt}/>
						<strong>Telefone:</strong>
						{this.props.user.telefone}
					</li>
					<li>
						<FontAwesomeIcon icon={faMapMarkerAlt}/>
						<strong>Localização:</strong>
						{this.props.user.cidade}, {this.props.user.estado}
					</li>
				</ul>
				<ul>
					<li>
						<FontAwesomeIcon icon={faBirthdayCake}/>
						<strong>Nascimento:</strong>
						{moment(this.props.user.data_nascimento, 'YYYY-MM-DD').format('DD/MM/YYYY')}
					</li>
					<li>
						<FontAwesomeIcon icon={faSuitcase}/>
						<strong>Profissão:</strong>
						{this.props.user.profissao}
					</li>
					<li>
						<FontAwesomeIcon icon={faGraduationCap}/>
						<strong>Escolaridade:</strong>
						{labelDicAuxiliarCandidato('escolaridade', this.props.user.escolaridade)}
					</li>
				</ul>
			</div>
		)
	}
}

InfoBioCandidatoCompartilhado.propTypes = {
	user: PropTypes.object.isRequired
}

export default InfoBioCandidatoCompartilhado
