import React from 'react'
import ScrollIntoView from './Components/ScrollView'
import { BrowserRouter } from 'react-router-dom'
import Routes from './Routes/'

import './scss/include.scss'

const App = () => {
  return (
    <BrowserRouter>
      <ScrollIntoView>
        <Routes />
      </ScrollIntoView>
    </BrowserRouter>
  )
}

export default App