import Modal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import React, { Component } from 'react'
import { post } from '../../Helpers/request'
import { isLoggedIn, isRecrutador } from '../../Helpers/user'
import Erro from './Erro'
import CircularProgress from '@material-ui/core/CircularProgress'
import Sucesso from './Sucesso'
import { abreModal, fechaModal, setToast } from '../../Actions/util'
import connectWithStore from '../../Stores/connectWithStore'
import { Tooltip } from '@material-ui/core'

class Cadastro extends Component {
  _isMounted = false
  state = {
    first_name: '',
    password: '',
    password_confirm: '',
    email: '',
    chk_lgpd_cadastro: false,
    loading: false,
    erroMensagem: undefined,
    erroCampos: {},
    sucessoMensagem: undefined,
  }

  onChange = e => {
    const obj = {}
    obj[e.target.name] = e.target.value
    this.setState(() => obj)
  }

  onChangeCheckbox = e => {
    const obj = {}
    obj[e.target.name] = e.target.checked ? e.target.value : false
    this.setState(() => obj)
  }

  onSubmit = e => {
    e.preventDefault()
    if (
      this.state.password.length > 0 &&
      this.state.password !== this.state.password_confirm
    ) {
      return this.setState(() => ({
        erroCampos: {
          password_confirm:
            'O campo "Confirmar senha" deve ser igual ao campo "Senha"',
        },
      }))
    }
    this.setState(() => ({
      loading: true,
      erroMensagem: undefined,
      erroCampos: {},
    }))
    const dados = {
      first_name: this.state.first_name,
      email: this.state.email,
      password: this.state.password,
    }
    if (this.props.match.params.id) {
      dados.id_vaga = this.props.match.params.id
    }
    post('users', dados)
      .then(({ data }) => {
        if (data.status) {
          if (this._isMounted) {
            this.setState(() => ({
              sucessoMensagem: data.message,
            }))
          } else {
            this.props.setToast(data.message, 'success')
          }
        } else {
          this.setState(() => ({
            erroMensagem: data.erros ? undefined : data.message,
            erroCampos: data.erros || {},
          }))
        }
      })
      .catch(err => {
        //erro controlado
        if (err.response) {
          this._isMounted &&
            this.setState(() => ({
              erroMensagem: err.response.data.erros
                ? undefined
                : err.response.data.message,
              erroCampos: err.response.data.erros || {},
            }))
        }
        //erro aleatório
        else {
          this._isMounted &&
            this.setState(() => ({
              erroMensagereturnm:
                err.response && err.response.data.message
                  ? err.response.data.message
                  : err.message,
            }))
        }
      })
      .finally(() => {
        this._isMounted &&
          this.setState(() => ({
            loading: false,
          }))
      })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isLoggedIn()) {
      if (!isRecrutador()) {
        this.props.onRequestClose()
      }
    }
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    return (
      <Modal
        {...this.props}
        isOpen
        className="Modal Modal__cadastro"
        overlayClassName="Overlay"
      >
        <div className="modalContent">
          <button className="close-modal" onClick={this.props.onRequestClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          {this.state.sucessoMensagem ? (
            <Sucesso titulo={this.state.sucessoMensagem} />
          ) : (
            <>
              <h1 className="modalContent__titulo">Cadastre-se agora</h1>
              <form className="modal-form" onSubmit={this.onSubmit}>
                <div className="modal-form__item">
                  <label htmlFor="nome" className="labelDefault">
                    Nome completo*
                  </label>
                  <input
                    type="text"
                    id="nome"
                    name="first_name"
                    className={`inputDefault${
                      this.state.erroCampos.first_name ? ' erro' : ''
                    }`}
                    placeholder="Digite seu nome"
                    maxLength={50}
                    value={this.state.first_name}
                    onChange={this.onChange}
                  />
                  {this.state.erroCampos.first_name && (
                    <Erro mensagem={this.state.erroCampos.first_name} />
                  )}
                </div>
                <div className="modal-form__item">
                  <label htmlFor="email" className="labelDefault">
                    E-mail*
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className={`inputDefault${
                      this.state.erroCampos.email ? ' erro' : ''
                    }`}
                    placeholder="Digite seu e-mail"
                    maxLength={60}
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                  {this.state.erroCampos.email && (
                    <Erro mensagem={this.state.erroCampos.email} />
                  )}
                </div>
                <div className="modal-form__item">
                  <label htmlFor="tel" className="labelDefault">
                    Senha*
                  </label>
                  <input
                    type="password"
                    id="senha"
                    name="password"
                    className={`inputDefault${
                      this.state.erroCampos.password ? ' erro' : ''
                    }`}
                    placeholder="*******"
                    maxLength={20}
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                  {this.state.erroCampos.password && (
                    <Erro mensagem={this.state.erroCampos.password} />
                  )}
                </div>
                <div className="modal-form__item">
                  <label htmlFor="tel" className="labelDefault">
                    Confirmar senha*
                  </label>
                  <input
                    type="password"
                    id="confirmar_senha"
                    name="password_confirm"
                    className={`inputDefault${
                      this.state.erroCampos.password_confirm ? ' erro' : ''
                    }`}
                    placeholder="*******"
                    maxLength={20}
                    value={this.state.password_confirm}
                    onChange={this.onChange}
                  />
                  {this.state.erroCampos.password_confirm && (
                    <Erro mensagem={this.state.erroCampos.password_confirm} />
                  )}
                </div>
                <div className="modal-form__item">
                  <label htmlFor="chk-lgpd-cadastro" className="labelDefault">
                    <input
                      type="checkbox"
                      id="chk-lgpd-cadastro"
                      className=""
                      name="chk_lgpd_cadastro"
                      value="1"
                      onChange={this.onChangeCheckbox}
                    />
                    {` `}Ao enviar este formulário estou de acordo com a{` `}
                    <a
                      href="https://www.momentum.com.br/politica-de-privacidade"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Política de Privacidade
                    </a>{' '}
                    da Momentum.
                  </label>
                </div>

                {this.state.erroMensagem && (
                  <div className="modal-form__item">
                    <Erro mensagem={this.state.erroMensagem} />
                  </div>
                )}
                <div className="modal-form__item submitWrap">
                  {this.state.chk_lgpd_cadastro ? (
                    <button
                      type="submit"
                      className="btnDefault btnDefault--btn4 btnDefault--loading"
                      disabled={this.state.loading}
                    >
                      {this.state.loading ? (
                        <>
                          <CircularProgress
                            className="circle-loading"
                            size={20}
                          />
                          Registrando
                        </>
                      ) : (
                        'Registrar'
                      )}
                    </button>
                  ) : (
                    <Tooltip
                      title={
                        'Antes de enviar, confirme estar de acordo com a nossa Política de Privacidade.'
                      }
                      arrow
                      placement="top"
                    >
                      <span>
                        <button
                          type="submit"
                          className="btnDefault btnDefault--btn4 btnDefault--loading btnDefault--tooltip"
                          disabled={true}
                        >
                          {this.state.loading ? (
                            <>
                              <CircularProgress
                                className="circle-loading"
                                size={20}
                              />
                              Registrando
                            </>
                          ) : (
                            'Registrar'
                          )}
                        </button>
                      </span>
                    </Tooltip>
                  )}
                </div>
                <div className="modal-form__item">
                  <p
                    className="sem-cadastro"
                    onClick={() => this.props.abreModal('login')}
                  >
                    <strong>Já tenho uma conta</strong>
                  </p>
                </div>
              </form>
            </>
          )}
        </div>
      </Modal>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setToast: (toastMessage, toastType, onToastClose) =>
    dispatch(setToast(toastMessage, toastType, onToastClose)),
  onRequestClose: () => dispatch(fechaModal()),
  abreModal: (tipo, modalExtra) => dispatch(abreModal(tipo, modalExtra)),
})

export default connectWithStore(null, mapDispatchToProps)(Cadastro)
