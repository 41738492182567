import React, {Component} from 'react'

import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import VideoDashboard from '../../Video/video-dashboard'
import connectWithStore from "../../../Stores/connectWithStore"
import {setToast} from "../../../Actions/util"
import {urlArquivo} from "../../../Helpers/util"
import {patch} from "../../../Helpers/request"
import {setVagaCandidato} from "../../../Actions/vagaCandidato"
import Loading from "../../Loading"
import Erro from "../../ErrorMessage"
import {logout} from "../../../Actions/user"

const TabPanel = (props) => {
	const {children, value, index, ...other} = props

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

const a11yProps = (index) => {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	}
}

class VideosCandidato extends Component {
	_isMounted = false
	state = {
		value: 0,
		loading: false,
		error: undefined,
	}
	handleChange = (event, newValue) => {
		if (!this.state.loading) {
			this.setState(() => ({
				value: newValue
			}))
		}
	}

	regravarResposta = (e, i) => {
		e && e.preventDefault()

		this.setState(() => ({
			error: undefined,
			loading: true,
		}))

		const dados = {
			id_vaga_questionario: this.props.vagaCandidato.vaga.pergunta[i].id
		}

		patch(`recrutador/vaga/${this.props.vagaCandidato.vaga.id}/candidato/${this.props.vagaCandidato.id}/regravar-resposta`, dados)
			.then(() => {
				this.props.setToast(`Solicitação de regravação de resposta enviada com sucesso`, 'success')
				if (this.props.vagaCandidato.respostas[i]) {
					const vagaCandidato = {...this.props.vagaCandidato}
					vagaCandidato.respostas[i].permite_regravar = 'sim'
					vagaCandidato.status = 'pendente'
					this._isMounted && this.props.setVagaCandidato(vagaCandidato)
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					this.props.setToast('Sua sessão expirou', 'error')
					this.props.logout()
					return
				}
				this._isMounted && this.setState(() => ({
					error: err.response && err.response.data.message ? err.response.data.message : err.message
				}))
			})
			.finally(() => {
				//this.props.setLoading(false)
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})
	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		const {respostas} = this.props.vagaCandidato
		const {pergunta} = this.props.vagaCandidato.vaga

		return (
			<section className="wrap-video-candidato">
				<div>
					<AppBar position="static" color="default">
						<Tabs
							value={this.state.value}
							onChange={this.handleChange}
							variant="scrollable"
							scrollButtons="auto"
							aria-label="Tabs vídeo candidatos">
							{pergunta.map((_, index) => (
								<Tab key={index} label={`Pergunta ${index + 1}`} {...a11yProps(index)} />))}
						</Tabs>
					</AppBar>
					{pergunta.map((item, index) => {
						return (
							<TabPanel value={this.state.value} index={index} key={index}>
								<h2 className="wrap-video-candidato__titulo-pergunta">
									{index + 1} - {item.pergunta}
								</h2>
								{respostas[index]
									? (
										<VideoDashboard class="wrap-video-candidato__embed-video"
										                url={urlArquivo(respostas[index].resposta)}/>
									) : (
										<Erro
											mensagem="Esta pergunta foi adicionada depois que a candidatura foi feita."/>
									)}

								{!this.props.readOnly && (
									<div className="wrap-video-candidato__regravar">
										{respostas[index] && (
											<p>
												{respostas[index].permite_regravar === 'sim'
													? 'Já existe uma solicitação para a regravação desta resposta.'
													: 'Vídeo não ficou bom ou apresentou algum erro?'}
											</p>
										)}
										{this.state.loading
											? (<Loading/>)
											: (
												this.state.error
													? (<Erro mensagem={this.state.error}
													         tryAgainHandler={(e) => this.regravarResposta(e, index)}/>)
													: (
														<button
															type="button"
															className="btnDefault btnDefault--btn1"
															onClick={(e) => this.regravarResposta(e, index)}
														>
															{respostas[index] && respostas[index].permite_regravar === 'sim'
																? 'Enviar novamente a solicitação'
																: 'Nova chance para gravar'}
														</button>
													)
											)
										}
									</div>
								)}
							</TabPanel>
						)
					})}
				</div>
			</section>
		)
	}
}

VideosCandidato.defaultProps = {
	readOnly: false
}
VideosCandidato.propTypes = {
	readOnly: PropTypes.bool
}

const mapStateToProps = (state) => ({
	vagaCandidato: state.vagaCandidato
})

const mapDispatchToProps = (dispatch) => ({
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
	setVagaCandidato: (vagaCandidato) => dispatch(setVagaCandidato(vagaCandidato)),
	logout: () => dispatch(logout()),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(VideosCandidato)
