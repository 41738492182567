import React, {Component} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons"
import {get} from "../../../Helpers/request"
import Loading from "../../Loading"
import Erro from "../../ErrorMessage"
import moment from "moment"
import {labelDicAuxiliarVaga} from "../../../Helpers/util"
import PropTypes from "prop-types"
import {NavLink} from "react-router-dom"
import parse from 'html-react-parser'
import {setToast} from "../../../Actions/util"
import connectWithStore from "../../../Stores/connectWithStore"
import {logout} from "../../../Actions/user"
import Tooltip from '@material-ui/core/Tooltip'

class DetalheVagaContent extends Component {
	_isMounted = false
	state = {
		vaga: undefined,
		loadingVaga: true,
		errorVaga: undefined,
		redirect: undefined,
		showDescription: false
	}

	getVaga = (e) => {
		e && e.preventDefault()

		this.setState(() => ({
			errorVaga: false,
			loadingVaga: true,
		}))

		get(`recrutador/vaga/${this.props.id}`)
			.then(({data}) => {
				this._isMounted && this.setState(() => ({
					vaga: data.vaga
				}))
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					this.props.setToast('Sua sessão expirou', 'error')
					this.props.logout()
					return
				}
				if (err.response && err.response.status === 404) {
					this.props.setToast(err.response.data.message, 'error')
					this._isMounted && this.setState(() => ({
						redirect: '/recrutador/vagas'
					}))
				}
				else {
					this._isMounted && this.setState(() => ({
						errorVaga: err.response && err.response.data.message ? err.response.data.message : err.message
					}))
				}
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loadingVaga: false,
				}))
			})
	}

	handleShowDescription = () => {
		this.setState(prevState => ({
			showDescription: !prevState.showDescription
		}))
	}

	componentDidMount() {
		this._isMounted = true
		this.getVaga()
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		return (
			this.state.loadingVaga
				? (<Loading/>)
				: (
					this.state.errorVaga
						? (
							<Erro mensagem={this.state.errorVaga} tryAgainHandler={this.getVaga}/>
						)
						: (
							<section className="detalhe-vaga-wrap">
								<div className="detalhes">
									{this.state.vaga.status === 'live' && (
										<Tooltip title="Editar vaga" arrow placement="bottom">
											<NavLink 
												className="btn-editar-vaga" 
												to={`/recrutador/vagas/editar/${this.props.id}`}>
												<FontAwesomeIcon icon={faPencilAlt}/>
											</NavLink>
										</Tooltip>
									)}
									<header>
										<div className="label-data">{moment(this.state.vaga.created_on, 'YYYY-MM-DD').format('DD/MM/YYYY')}</div>
										<h1 className="titulo-vaga">{this.state.vaga.titulo}</h1>
									</header>
									<article>
										<div className="item">
											<span>Período do trabalho</span>
											<p>{labelDicAuxiliarVaga('periodo_trabalho', this.state.vaga.periodo_trabalho)}</p>
										</div>
										<div className="item">
											<span>Contração</span>
											<p>{labelDicAuxiliarVaga('tipo_contratacao', this.state.vaga.tipo_contratacao)}</p>
										</div>
										<div className="item">
											<span>Nível de experiência</span>
											<p>{labelDicAuxiliarVaga('nivel_experiencia', this.state.vaga.nivel_experiencia)}</p>
										</div>
										<div className="item">
											<span>Local de trabalho</span>
											<p>{labelDicAuxiliarVaga('estado', this.state.vaga.estado)} <br/> {this.state.vaga.cidade}</p>
										</div>
										<div className="item">
											<span>Salário</span>
											<p>{this.state.vaga.salario}</p>
										</div>
										<div className="item">
											<button
												type="button"
												onClick={this.handleShowDescription}
												className="btnDefault btnDefault--btn1">
													{!this.state.showDescription ? 'Ver descrição' : 'Fechar descrição'}
											</button>
										</div>
										{this.state.showDescription &&
											<div className="item item--descricao">
												{parse(this.state.vaga.descricao)}
											</div>
										}
									</article>
								</div>
								<div className="candidatos">
									<div className="item item--total">
										<p className="valor">{this.state.vaga.resumo.total}</p>
										<p className="txt">
											candidato{this.state.vaga.resumo.total !== 1? 's': ''}
											<br/>
											<small>no total</small>
										</p>
									</div>
									<div className="item item--pendente">
										<p className="valor">{this.state.vaga.resumo.pendente}</p>
										<p className="txt">
											pendente{this.state.vaga.resumo.pendente !== 1? 's': ''}
											<br/>
											<small>de análise</small>
										</p>
									</div>
									<div className="item item--aprovados">
										<p className="valor">{this.state.vaga.resumo.aprovado}</p>
										<p className="txt">
											aprovado{this.state.vaga.resumo.aprovado !== 1? 's': ''}
											<br/>
											<small>na entrevista</small>
										</p>
									</div>
									<div className="item item--reprovados">
										<p className="valor">{this.state.vaga.resumo.reprovado}</p>
										<p className="txt">
											reprovado{this.state.vaga.resumo.reprovado !== 1? 's': ''}
											<br/>
											<small>na entrevista</small>
										</p>
									</div>
								</div>
							</section>
						)
				)

		)
	}
}

DetalheVagaContent.prototypes = ({
	id: PropTypes.string.isRequired
})

const mapDispatchToProps = (dispatch) => ({
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
	logout: () => dispatch(logout()),
})

export default connectWithStore(null, mapDispatchToProps)(DetalheVagaContent)
