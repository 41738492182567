import React, {Component} from "react"
import {fechaModal, setToast} from "../../Actions/util"
import connectWithStore from "../../Stores/connectWithStore"
import Modal from "react-modal"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTimes} from "@fortawesome/free-solid-svg-icons"
import Curriculo from '../Candidato/Curriculo/index'

class CurriculoModal extends Component {
	_isMounted = false

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		return (
			<Modal
				{...this.props}
				isOpen
				className="Modal Modal__curriculo"
				overlayClassName="Overlay"
			>
				<div className="modalContent">
					<button className="close-modal" onClick={this.props.onRequestClose}>
						<FontAwesomeIcon icon={faTimes}/>
					</button>

					<Curriculo titulo="Meu currículo" onUpdate={this.props.onRequestClose} />
				</div>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
	onRequestClose: () => dispatch(fechaModal()),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(CurriculoModal)
