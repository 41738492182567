import React from 'react'
import BtnAvaliacao from '../../Buttons/avaliacao'
import BtnShare from '../../Buttons/share'
import BtnWhatsapp from '../../Buttons/whatsapp'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
	faEnvelope,
	faPhoneAlt,
	faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons"

import {urlFotoPerfil} from "../../../Helpers/util"
import connectWithStore from "../../../Stores/connectWithStore"
import BtnCvRecrutador from "../../Buttons/cvRecrutador"

const InfoBioCandidato = ({vagaCandidato}) => {
	const {perfil, vaga} = vagaCandidato
	return (
		<>
			<section className="wrap-info-candidado">
				<div className="wrap-info-candidado__item">
					<div
						className="avatar"
						style={{backgroundImage: `url(${urlFotoPerfil(perfil.foto, 140, 140, 'fit')})`}}/>
				</div>
				<div className="wrap-info-candidado__item wrap-info-candidado__item--dados-pessoais">
					<span className="wrap-info-candidado__vaga">{vaga.titulo}</span>
					<h1 className="wrap-info-candidado__nome">{perfil.first_name}</h1>
					<div className="wrap-info-candidado__contatos">
						<ul>
							<li>
								<span>
									<FontAwesomeIcon icon={faEnvelope}/>
									E-mail:
								</span>
								{perfil.email}
							</li>
							<li>
								<span>
									<FontAwesomeIcon icon={faPhoneAlt}/>
									Telefone:
								</span>
								{perfil.telefone}
							</li>
							<li>
								<span>
									<FontAwesomeIcon icon={faMapMarkerAlt}/>
									Localidade:
								</span>
								{perfil.cidade}/{perfil.estado}
							</li>
						</ul>
					</div>
				</div>
				<div className="wrap-info-candidado__item wrap-info-candidado__item--avaliacao">
					<BtnAvaliacao titulo="Avaliação do Candidato" />
				</div>
			</section>
			<section className="wrap-info-botoes">
				<BtnCvRecrutador curriculo={perfil.curriculo} curriculoTexto={perfil.curriculo_texto} />
				<BtnWhatsapp page="dashboard" numero={perfil.whatsapp} />
				<BtnShare/>
			</section>
		</>
	)
}

const mapStateToProps = (state) => ({
	vagaCandidato: state.vagaCandidato
})

export default connectWithStore(mapStateToProps, null)(InfoBioCandidato)
