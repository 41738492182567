import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from './button'
import RecordButton from './record-button'
import StopButton from './stop-button'
import Timer from './timer'
import Countdown from './countdown'
import CircularProgress from "@material-ui/core/CircularProgress"

const ActionsWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 0px;

  @media(max-width: 550px) {
	padding-bottom: 0px;
  }
`

const Actions = ({
	                 isVideoInputSupported,
	                 isInlineRecordingSupported,
	                 thereWasAnError,
	                 isRecording,
	                 isCameraOn,
	                 streamIsReady,
	                 isConnecting,
	                 isRunningCountdown,
	                 isReplayingVideo,
	                 countdownTime,
	                 timeLimit,
	                 showReplayControls,
	                 replayVideoAutoplayAndLoopOff,
	                 useVideoInput,

	                 onTurnOnCamera,
	                 onTurnOffCamera,
	                 onOpenVideoInput,
	                 onStartRecording,
	                 onStopRecording,
	                 onPauseRecording,
	                 onResumeRecording,
	                 onStopReplaying,
	                 onConfirm,
	                 onCancelar,
	                 onEnviarVideo,
	                 enviarLoading,

                 }) => {
	const renderContent = () => {
		const shouldUseVideoInput =
			!isInlineRecordingSupported && isVideoInputSupported

		if (
			(!isInlineRecordingSupported && !isVideoInputSupported) ||
			thereWasAnError ||
			isConnecting ||
			isRunningCountdown
		) {
			return null
		}

		if (isReplayingVideo) {
			return (
				<>
					{/* <Button onClick={onStopReplaying} data-qa='start-replaying'>
						Regravar
					</Button> */}
					<button
						onClick={onEnviarVideo}
						className="btnDefault btnDefault--btn1 btnDefault--loading"
						disabled={enviarLoading}
					>
						{enviarLoading
							? (
								<>
									<CircularProgress className="circle-loading" size={20}/>
									Enviando
								</>
							)
							: (
								'Enviar'
							)}
					</button>
					{/* <Button onClick={onCancelar} backgroundColor="#999999">
						Cancelar
					</Button> */}
				</>
			)
		}

		if (isRecording) {
			return <StopButton onClick={onStopRecording} data-qa='stop-recording'/>
		}

		if (isCameraOn && streamIsReady) {
			return (
				<RecordButton onClick={onStartRecording} data-qa='start-recording'/>
			)
		}

		if (useVideoInput) {
			return (
				<Button onClick={onOpenVideoInput} data-qa='open-input'>
					Upload a video
				</Button>
			)
		}

		return shouldUseVideoInput ? (
			<Button onClick={onOpenVideoInput} data-qa='open-input'>
				Record a video
			</Button>
		) : (
			<Button onClick={onTurnOnCamera} data-qa='turn-on-camera'>
				Turn my camera ON
			</Button>
		)
	}

	return (
		<div>
			{isRecording && <Timer timeLimit={timeLimit}/>}
			{isRunningCountdown && <Countdown countdownTime={countdownTime}/>}
			<ActionsWrapper>{renderContent()}</ActionsWrapper>
		</div>
	)
}

Actions.propTypes = {
	isVideoInputSupported: PropTypes.bool,
	isInlineRecordingSupported: PropTypes.bool,
	thereWasAnError: PropTypes.bool,
	isRecording: PropTypes.bool,
	isCameraOn: PropTypes.bool,
	streamIsReady: PropTypes.bool,
	isConnecting: PropTypes.bool,
	isRunningCountdown: PropTypes.bool,
	countdownTime: PropTypes.number,
	timeLimit: PropTypes.number,
	showReplayControls: PropTypes.bool,
	replayVideoAutoplayAndLoopOff: PropTypes.bool,
	isReplayingVideo: PropTypes.bool,
	useVideoInput: PropTypes.bool,

	onTurnOnCamera: PropTypes.func,
	onTurnOffCamera: PropTypes.func,
	onOpenVideoInput: PropTypes.func,
	onStartRecording: PropTypes.func,
	onStopRecording: PropTypes.func,
	onPauseRecording: PropTypes.func,
	onResumeRecording: PropTypes.func,
	onStopReplaying: PropTypes.func,
	onConfirm: PropTypes.func
}

export default Actions