import React, {Fragment} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faExclamation} from "@fortawesome/free-solid-svg-icons"
import PropTypes from 'prop-types'
/* import { NavLink } from 'react-router-dom' */

const ModalErro = (props) => {
	//TODO pensar como receber o botão

	//clonando props para não alterar o state de quem chama
	const propsTitulo = props.titulo instanceof Array? [...props.titulo]: props.titulo
	const propsTexto = props.texto instanceof Array? [...props.texto]: props.texto

	let titulo = propsTitulo
	let texto = propsTexto ? propsTexto : []
	texto = texto instanceof Array ? texto : [texto]

	//se tiver mais de um item em título, joga do segundo em diante no texto para não ter mais de uma coisa no h1
	if (propsTitulo instanceof Array && propsTitulo.length > 1) {
		titulo = propsTitulo.shift()
		texto = [...propsTitulo, ...texto]
	}
	return (
		<div className="modalContent__alerta modalContent__alerta--erro">
			<span className="icon"><FontAwesomeIcon icon={faExclamation}/></span>
			<h1>{titulo}</h1>
			<p>
				{texto.map((v, k) => (
					<Fragment key={k}>
						{v}
						<br/>
					</Fragment>
				))}
			</p>
			{props.btAcao && (
				props.btAcao
			)}
		</div>
	)
}
ModalErro.propTypes = {
	titulo: PropTypes.any.isRequired,
	texto: PropTypes.any,
}
ModalErro.defaultProps = {
	texto: ''
}

export default ModalErro