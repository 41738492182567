import React from 'react'
import { withRouter } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons"

const TituloCandidato = ({ titulo, history }) => {
    return(
        <section className="box-titulo-perfil-candidato">
            <div className="container">
                <h1>
                    <button onClick={() => history.goBack()}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    {titulo}
                </h1>
            </div>
        </section>
    )
}

export default withRouter(TituloCandidato)