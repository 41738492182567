const vagaCandidatoReducer = (state = null, action) => {
	switch(action.type) {
		case 'SET_VAGA_CANDIDATO':
			return action.vagaCandidato
		case 'UNSET_VAGA_CANDIDATO':
			return null
		default:
			return state
	}
}

export default vagaCandidatoReducer
