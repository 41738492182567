import React from 'react'
import {NavLink} from 'react-router-dom'
import PropTypes from 'prop-types'
import parser from 'html-react-parser'
import {isLoggedIn} from "../../../Helpers/user"
import {abreModal, fechaModal} from "../../../Actions/util"
import connectWithStore from "../../../Stores/connectWithStore"

const DescricaoVaga = ({pagina, vaga, candidatura, abreModal, history, fechaModal}) => {
	const onLoggedIn = () => {
		fechaModal()
		history.push(`/vagas/${vaga.id}/candidatar`)
	}

	const onLoginClick = () => {
		abreModal('login', {onLoggedIn: onLoggedIn})
	}

	let regravacaoSolicitada = []
	if (candidatura) {
		regravacaoSolicitada = candidatura.respostas.filter((v) => v.permite_regravar === 'sim')
	}

	return (
		<div className="infoVagaSite__descricao-content">
			<h1 className="tituloSite2">Descrição</h1>
			{parser(vaga.descricao)}

			{pagina === "vagas" && (
				isLoggedIn()
					? (
						(!candidatura || candidatura.status === 'incompleto' || regravacaoSolicitada.length > 0) && (
							<NavLink to={`/vagas/${vaga.id}/candidatar`}
							         className="btnDefault btnDefault--btn1"
							         disabled={candidatura && !regravacaoSolicitada.length > 0}
							>
								{candidatura && candidatura.status === 'incompleto'? 'Concluír candidatura': (
									regravacaoSolicitada.length > 0? 'Regravar resposta':
										'Quero me candidatar'
								)}
							</NavLink>
						)
					)
					: (
						<button
							className="btnDefault btnDefault--btn1"
							onClick={onLoginClick}
						>
							Quero me candidatar
						</button>
					)
			)}
		</div>
	)
}


DescricaoVaga.propTypes = ({
	vaga: PropTypes.object.isRequired,
	candidatura: PropTypes.object,
	pagina: PropTypes.oneOf(["vagas"])
})

const mapStateToProps = (state) => ({
	user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
	abreModal: (tipo, modalExtra) => dispatch(abreModal(tipo, modalExtra)),
	fechaModal: () => dispatch(fechaModal())
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(DescricaoVaga)
