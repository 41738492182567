import React from 'react'
import { NavLink } from 'react-router-dom'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt, faSuitcase, faCoins } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types"
import {labelDicAuxiliarVaga} from "../../../Helpers/util"
import {abreModal, fechaModal} from "../../../Actions/util"
import connectWithStore from "../../../Stores/connectWithStore"
import {isLoggedIn} from "../../../Helpers/user"

const InfosIniciaisVaga = ({ pagina, vaga, candidatura, abreModal, history, fechaModal }) => {
	const onLoggedIn = () => {
		fechaModal()
		history.push(`/vagas/${vaga.id}/candidatar`)
	}

	const onLoginClick = () => {
		abreModal('login', {onLoggedIn: onLoggedIn})
	}

	let regravacaoSolicitada = []
	if (candidatura) {
		regravacaoSolicitada = candidatura.respostas.filter((v) => v.permite_regravar === 'sim')
	}

	return (
        <div className="infoVagaSite__sobre-content">
            {/*<span className="labelCategoriaVaga">
                Comercial
            </span>*/}
            <h1 className="tituloSite">
                {vaga.titulo}
            </h1>
            <h2 className="subTituloSite">
                {vaga.local_trabalho} - {vaga.cidade}, {vaga.estado}
            </h2>
            {pagina === "vagas" &&
                <div className="infoVagaSite__botoesWrap">
	                {isLoggedIn()
		                ? (
			                (!candidatura || candidatura.status === 'incompleto' || regravacaoSolicitada.length > 0) && (
				                <NavLink to={`/vagas/${vaga.id}/candidatar`}
				                         className="btnDefault btnDefault--btn1"
				                         disabled={candidatura && !regravacaoSolicitada.length > 0}
				                >
					                {candidatura && candidatura.status === 'incompleto'? 'Concluír candidatura': (
					                	regravacaoSolicitada.length > 0? 'Regravar resposta':
							                'Quero me candidatar'
					                )}
				                </NavLink>
			                )
		                )
		                : (
			                <button
				                className="btnDefault btnDefault--btn1"
				                onClick={onLoginClick}
			                >
				                Quero me candidatar
			                </button>
		                )}
                </div>
            }
            <div className="infoVagaSite__info-adicionais">
                <div className="item-infos-adicionais item-infos-adicionais--local">
                    <FontAwesomeIcon icon={faMapMarkedAlt} />
                    <p>
                        {vaga.local_trabalho} - {vaga.cidade}, {vaga.estado}
                    </p>
                </div>
                <div className="item-infos-adicionais item-infos-adicionais--periodo">
                    <FontAwesomeIcon icon={faSuitcase} />
                    <p>
                        {labelDicAuxiliarVaga('periodo_trabalho', vaga.periodo_trabalho)}
                    </p>
                </div>
                <div className="item-infos-adicionais item-infos-adicionais--salario">
                    <FontAwesomeIcon icon={faCoins} />
                    <p>
                        {vaga.salario}
                    </p>
                </div>
            </div>
        </div>
    )
}

InfosIniciaisVaga.propTypes = ({
    vaga: PropTypes.object.isRequired,
	candidatura: PropTypes.object,
    pagina: PropTypes.oneOf(["vagas", "candidatar"])
})

const mapStateToProps = (state) => ({
    user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
    abreModal: (tipo, modalExtra) => dispatch(abreModal(tipo, modalExtra)),
    fechaModal: () => dispatch(fechaModal())
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(InfosIniciaisVaga)
