import React, { Component } from 'react'
import Listagem from './listagem'
import Criar from './criar'
import {get} from "../../../Helpers/request"
import Loading from "../../Loading"
import PropTypes from 'prop-types'
import connectWithStore from "../../../Stores/connectWithStore"
import {setVagaCandidatoAvaliacao, unsetVagaCandidatoAvaliacao} from "../../../Actions/vagaCandidatoAvaliacao"

class Avaliacao extends Component {
    _isMounted = false
    state = {
        loading: false,
        error: undefined,
    }

    getAvaliacoes = (e) => {
        e && e.preventDefault()

        this.setState(() => ({
            error: undefined,
            loading: true,
        }))

        const url = this.props.readOnly && this.props.match.params.hash
            ? `vaga/compartilhado/${this.props.match.params.hash}/avaliacao`
            : `recrutador/vaga/${this.props.vagaCandidato.vaga.id}/candidato/${this.props.vagaCandidato.id}/avaliacao`
        get(url)
            .then(({data, status}) => {
                if (data.avaliacoes) {
                    this._isMounted && this.props.setVagaCandidatoAvaliacao(data.avaliacoes)
                }
            })
            .catch((err) => {
                this._isMounted && this.setState(() => ({
                    error: err.response && err.response.data.message ? err.response.data.message : err.message
                }))
            })
            .finally(() => {
                this._isMounted && this.setState(() => ({
                    loading: false,
                }))
            })
    }

    componentDidMount() {
        this._isMounted = true
        this.getAvaliacoes()
    }

    componentWillUnmount() {
        this._isMounted = false
        this.props.unsetVagaCandidatoAvaliacao()
    }

    render() {
        return (
            <div className="main-avaliacoes">
                <h1 className="main-avaliacoes__titulo">
                    Avaliações dos recrutadores
                </h1>
                {this.state.loading
                    ?(
                        <Loading />
                    )
                    :(
                        <>
                            <Listagem />
                            {!this.props.readOnly && (
                                <Criar onComentario={this.getAvaliacoes} />
                            )}
                        </>
                    )}
            </div>
        )
    }
}

Avaliacao.defaultProps = {
    readOnly: false,
}

Avaliacao.propTypes = {
    readOnly: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    vagaCandidato: state.vagaCandidato,
})

const mapDispatchToProps = (dispatch) => ({
    setVagaCandidatoAvaliacao: (avaliacoes) => dispatch(setVagaCandidatoAvaliacao(avaliacoes)),
    unsetVagaCandidatoAvaliacao: () => dispatch(unsetVagaCandidatoAvaliacao()),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(Avaliacao)
