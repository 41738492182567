import React from 'react'
import {Helmet} from 'react-helmet'

const PageMeta = ({ title, description, keywords }) => {
    const defaultTitle = "Programa de Excelência Momentum"
    return(
        <Helmet>
            <title>{title ? `${defaultTitle} :: ${title}` : defaultTitle}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
        </Helmet>
    )
}

export default PageMeta