import React, {useState} from 'react'
import {NavLink} from 'react-router-dom'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'
import Modal from 'react-modal'
import Contato from "../../Modal/Contato"
import Login from "../../Modal/Login"
import Cadastro from "../../Modal/Cadastro"
import BtnPerfil from '../../Buttons/perfil'
import connectWithStore from "../../../Stores/connectWithStore"
import {isCandidato, isLoggedIn, isRecrutador} from "../../../Helpers/user"
import {abreModal, fechaModal} from "../../../Actions/util"
import ModalAlerta from "../../Modal/Alerta"
import EsqueciMinhaSenha from "../../Modal/EsqueciMinhaSenha"
import EsqueciNovaSenha from "../../Modal/EsqueciNovaSenha"
import CurriculoModal from "../../Modal/Curriculo"
import ModalInput from "../../Modal/Candidatar/Input"
import ModalInputLocalidade from "../../Modal/Candidatar/InputLocalidade"

Modal.setAppElement('#root')

const HeaderMobileSite = ({user, alertaTipo, modal, modalExtra, abreModal}) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const handleClickMobile = event => {
		setAnchorEl(event.currentTarget)
		event.currentTarget.classList.add("change")
	}

	const handleCloseMobile = () => {
		setAnchorEl(null)
		document.querySelector('.menu-hamburger').classList.remove("change")
	}

	return (
		<div className="header__item header__item--menu">
			{isCandidato() && (
				<BtnPerfil/>
			)}

			<button
				type="button"
				className="menu-hamburger"
				aria-controls="menu-mobile"
				aria-haspopup="true"
				onClick={handleClickMobile}
			>
				<div className="bar bar--site bar1"/>
				<div className="bar bar--site bar2"/>
				<div className="bar bar--site bar3"/>
			</button>
			<Menu
				id="menu-mobile"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleCloseMobile}
				TransitionComponent={Fade}
			>
				<MenuItem onClick={handleCloseMobile}>
					<NavLink
						to="/"
						exact
						className="header__link"
						activeClassName="active"
					>
						Home
					</NavLink>
				</MenuItem>
				{!isRecrutador() && (
					<MenuItem onClick={handleCloseMobile}>
						<button
						   onClick={() => abreModal('contato')}
						   className="header__link">
							Contato
						</button>
					</MenuItem>
				)}
				{!isLoggedIn() && (
					<MenuItem onClick={handleCloseMobile}>
						<button
						   onClick={() => abreModal('cadastro')}
						   className="header__link">
							Cadastrar
						</button>
					</MenuItem>
				)}
				{isRecrutador() && (
					<MenuItem onClick={handleCloseMobile}>
						<NavLink
							to="/recrutador/"
							className="header__link header__link"
						>
							Dashboard
						</NavLink>
					</MenuItem>
				)}
				{!isLoggedIn() && (
					<MenuItem onClick={handleCloseMobile}>
						<button
							onClick={() => abreModal('login')}
							className="header__link header__link--login">
							Entrar
						</button>
					</MenuItem>
				)}
			</Menu>
			{alertaTipo && (<ModalAlerta/>)}
			{modal === 'contato' && <Contato {...modalExtra} />}
			{modal === 'cadastro' && <Cadastro {...modalExtra} />}
			{modal === 'login' && <Login {...modalExtra} />}
			{modal === 'esqueci-minha-senha' && <EsqueciMinhaSenha {...modalExtra} />}
			{modal === 'esqueci-nova-senha' && <EsqueciNovaSenha {...modalExtra} />}
			{modal === 'curriculo' && <CurriculoModal {...modalExtra} />}
			{modal === 'input' && <ModalInput {...modalExtra} />}
			{modal === 'input-localidade' && <ModalInputLocalidade {...modalExtra} />}
		</div>
	)
}

const mapStateToProps = (state) => ({
	user: state.user,
	alertaTipo: state.util.alertaTipo,
	modal: state.util.modal,
	modalExtra: state.util.modalExtra
})

const mapDispatchToProps = (dispatch) => ({
	abreModal: (tipo) => dispatch(abreModal(tipo)),
	fechaModal: () => dispatch(fechaModal()),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(HeaderMobileSite)
