import React from "react"
import PropTypes from 'prop-types'

const Erro = (props) => {
	const mensagem = props.mensagem instanceof Array? props.mensagem: [props.mensagem]
	return <>
		{mensagem.map((v, k) => (
			<p className="erro-message" key={k}>{v}</p>
		))}
		{props.tryAgainHandler && (
			<p onClick={props.tryAgainHandler}>Tentar novamente</p>
		)}
	</>
}
Erro.propTypes = {
	mensagem: PropTypes.any.isRequired,
	tryAgainHandler: PropTypes.func
}

export default Erro