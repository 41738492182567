import React from "react"
import {connect} from "react-redux"
import store from "./configureStore"
import {withRouter} from "react-router"

const connectWithStore = (mapStateToProps = null, mapDispatchToProps = null, mergeProps = null) => WrappedComponent =>{
	const ConnectedWrappedComponent = withRouter(connect(mapStateToProps,mapDispatchToProps,mergeProps)(WrappedComponent))
	return props => <ConnectedWrappedComponent {...props} store={store} />
}

export default connectWithStore
