import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import {get} from "../../../Helpers/request"
import Loading from "../../Loading"
import Erro from "../../ErrorMessage"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronDown} from "@fortawesome/free-solid-svg-icons"
import {urlFotoPerfil} from "../../../Helpers/util"
import moment from "moment"
import PropTypes from "prop-types"
import Vazio from "../../ErrorMessage/vazio"

class CandidatosLista extends Component {
	_isMounted = false
	state = {
		lista: [],
		error: undefined,
		loading: false,
		pagina: 1,
		totalPaginas: undefined,
	}

	getCandidatos = (e) => {
		e && e.preventDefault()

		this.setState(() => ({
			error: undefined,
			loading: true,
		}))

		const urlLista = this.props.idVaga && this.props.tipo
			? `recrutador/vaga/${this.props.idVaga}/candidatos/${this.props.tipo}/${this.state.pagina}`
			: `recrutador/candidato${this.props.tipo? `/${this.props.tipo}`: ''}/${this.state.pagina}`

		const qs = {
			limit: this.props.limit
		}

		if (this.props.q) {
			qs.q = this.props.q
		}

		get(urlLista, qs)
			.then(({data, status}) => {
				if (data.candidatos) {
					this._isMounted && this.setState((prevState) => ({
						lista: prevState.lista.concat(data.candidatos),
						totalPaginas: data.total_paginas,
					}))
				}
			})
			.catch((err) => {
				this._isMounted && this.setState(() => ({
					error: err.response && err.response.data.message ? err.response.data.message : err.message
				}))
			})
			.finally(() => {
				//this.props.setLoading(false)
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})
	}

	onNextPage = (e) => {
		e.preventDefault()
		this.setState((state) => ({
			pagina: state.pagina + 1
		}))
	}

	componentDidMount() {
		this._isMounted = true
		this.getCandidatos()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.pagina !== this.state.pagina || prevProps.idVaga !== this.props.idVaga || prevProps.tipo !== this.props.tipo) {
			this.getCandidatos()
		}

		if (prevProps.q !== this.props.q) {
			this.setState(() => ({
				lista: [],
				error: undefined,
				loading: false,
				pagina: 1,
				totalPaginas: undefined,
			}), this.getCandidatos)
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		return (
			<>
				{!this.state.loading && !this.state.error && this.state.lista.length === 0 && (
					<Vazio message={"Nenhum candidato encontrado"} />
				)}
				{this.state.lista.map((v, k) => (
					<div key={k} className={
						v.status === "pendente" || v.status === "incompleto" ? "ultimos-candidatos__item pendente"
							: v.status === "aprovado" ? "ultimos-candidatos__item aprovado"
							: "ultimos-candidatos__item reprovado"}>
						<div>
							<div
								style={{backgroundImage: `url(${urlFotoPerfil(v.perfil.foto, 70, 70, 'fit')})`}}
								className='avatar'>
							</div>
						</div>
						<div className="wrap-dados-candidato">
							<p className="data-candidatura">{moment(v.created_on, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
							<h1 className='nome-candidatos'>{v.perfil.first_name}</h1>
							<h2 className='cargo-candidatos'>{v.vaga.titulo}</h2>
							<NavLink
								to={`/recrutador/candidato/${v.vaga.id}/${v.id}`}
								title='Ver currículo'
								className='btnDefault btnDefault--btn4'>
								Visualizar
							</NavLink>
						</div>
					</div>
				))}
				{this.state.loading
					? (<div className="wrap-spinner"><Loading/></div>)
					: (
						this.state.error
							? (<Erro mensagem={this.state.error} tryAgainHandler={this.getCandidatos}/>)
							: (
								this.props.mostraBtVerTodas && this.state.pagina < this.state.totalPaginas && (
									<NavLink to="/recrutador/candidatos/lista" className="btnDefault btnDefault--btn4">
										Ver todas
									</NavLink>
								)
							)
					)}
				{!this.state.loading && this.props.paginacao && this.state.pagina < this.state.totalPaginas && (
					<section className="ver-mais-wrap">
						<button
							onClick={this.onNextPage}>
							Carregar mais
							<FontAwesomeIcon icon={faChevronDown}/>
						</button>
					</section>
				)}
			</>
		)
	}
}

CandidatosLista.defaultProps = {
	limit: 3,
	paginacao: false,
	mostraBtVerTodas: false,
}

CandidatosLista.propTypes = ({
	limit: PropTypes.number,
	paginacao: PropTypes.bool,
	mostraBtVerTodas: PropTypes.bool,
	idVaga: PropTypes.string,
	tipo: PropTypes.oneOf(['incompleto', 'pendente', 'aprovado', 'reprovado'])
})

export default CandidatosLista