import React from 'react'
import Layout from '../../Components/Recrutador/Layout'
import Title from '../../Components/Recrutador/Title'
import VagasLista from '../../Components/Recrutador/ListaVagas'
import CandidatosLista from '../../Components/Recrutador/ListaCandidatos'
import PageMeta from '../../Components/PageMeta'
import queryString from "query-string"

const ResultadoBusca = (props) => {
	const qs = queryString.parse(props.location.search)
	const q = qs.q || ''
	return(
		<Layout>
			<PageMeta
				title="Dashboard"
				description="description home dashboard"
				keywords="Vaga, Momentum, Recrutamento"
			/>

			<Title title='Vagas'/>

			<VagasLista
				limit={3}
				paginacao={true}
				q={q}
			/>

			<Title title="Candidaturas"/>

			<section className="ultimos-candidatos ultimos-candidatos--home">
				<CandidatosLista
					limit={3}
					paginacao={true}
					q={q}
				/>
			</section>
		</Layout>
	)
}

export default ResultadoBusca
