import React, {Component, Fragment} from 'react'

import HeaderSite from '../Header/'
import FooterSite from '../Footer'

import PropTypes from 'prop-types'
import {login, logout} from "../../../Actions/user"
import connectWithStore from "../../../Stores/connectWithStore"
import {get, put} from "../../../Helpers/request"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from '@material-ui/lab/Alert'
import {abreModal, alerta, alertaErro, alertaSucesso, fechaModal, setToast} from "../../../Actions/util"
import {isLoggedIn} from "../../../Helpers/user"
import queryString from "query-string"
import {NavLink} from "react-router-dom"

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />
}

class Layout extends Component {
	onToastClose = (event, reason) => {
		if (this.props.onToastClose) {
			return this.props.onToastClose(event, reason)
		}

		if (reason === 'clickaway') {
			return
		}
		this.props.setToast(undefined)
	}

	componentDidMount() {
		if (!this.props.user) {
			if (this.props.match.params.activationCode && this.props.match.params.idUser) {
				this.activateUser()
			}
			else if (this.props.match.params.forgottenPasswordCode) {
				this.resetPassword()
			}
			if (this.props.match.path === '/login') {
				this.getUser(this.abreLogin)
			}
			else {
				this.getUser()
			}
		}
	}

	loginClose = () => {
		this.props.history.replace("/")
		this.props.fechaModal()
	}

	abreLogin = () => {
		if (isLoggedIn()) {
			this.loginRedirect()
		}
		else {
			this.props.abreModal('login', {onLoggedIn: this.loginRedirect, onClose: this.loginClose})
		}
	}

	loginRedirect = () => {
		const qs = queryString.parse(this.props.location.search)
		this.props.history.replace('/')
		//se não começar com /, ignora
		if (qs.r && qs.r.indexOf('/') === 0) {
			this.props.history.push(qs.r || '/')
		}
	}

	getUser = (cb) => {
		get('users')
			.then(({data}) => {
				if (data.status) {
					return this.props.login(data.user)
				}
			})
			.catch((err) => {
			})
			.finally(() => {
				cb && cb()
			})
	}

	activateUser = () => {
		const dados = {
			id: this.props.match.params.idUser,
			code: this.props.match.params.activationCode
		}
		const redirectUrl = this.props.match.params.id? `/vagas/${this.props.match.params.id}/candidatar`: '/candidato/perfil'
		let replaceUrl = this.props.match.params.id? `/vagas/${this.props.match.params.id}`: '/'

		put('users/activate', dados)
			.then(({data}) => {
				const btAcao =
					<NavLink
						to={redirectUrl}
						onClick={this.props.alerta}
						className="btnDefault btnDefault--btn4">
						Completar meu perfil
					</NavLink>
				const onRequestClose = () => {
					this.props.history.push(redirectUrl)
					this.props.alerta()
				}
				this.props.alertaSucesso("Seu cadastro foi realizado com sucesso!", "Para continuar se candidatando na vaga, você deve completar seu perfil primeiro.", {btAcao, onRequestClose})

				this.getUser()
			})
			.catch((err) => {
				//Se o usuário já está ativo (provavelmente por o cliente de e-mail já ter ativado a conta, convida o usuário a fazer o login
				if (err.response.data && err.response.data.ativo) {
					//adiciona um redirect na query string para redirecionar após o login
					replaceUrl+= `?${queryString.stringify({r: redirectUrl})}`
					const onRequestClose = () => {
						this.props.alerta()
						this.props.abreModal('login', {onLoggedIn: this.loginRedirect})
					}
					const btAcao =
						<button
							onClick={onRequestClose}
							className="btnDefault btnDefault--btn4">
							Fazer login
						</button>
					this.props.alertaSucesso("Seu cadastro foi realizado com sucesso!", "Para continuar se candidatando na vaga, você deve realizar o login e completar seu perfil.", {btAcao, onRequestClose})
				}
				else {
					this.props.alertaErro(err.response && err.response.data.message ? err.response.data.message : err.message)
				}
			})
			.finally(() => {
				this.props.history.replace(replaceUrl)
			})
	}

	resetPassword = () => {
		const dados = {
			code: this.props.match.params.forgottenPasswordCode
		}

		put('users/reset_pass', dados)
			.then(({data}) => {
				this.props.abreModal('esqueci-nova-senha')
				this.getUser()
			})
			.catch((err) => {
				if (err.response.status === 412) {
					this.getUser()
				}
				else {
					this.props.alertaErro(err.response && err.response.data.message ? err.response.data.message : err.message)
				}
			})
			.finally(() => {
				this.props.history.replace('/')
			})
	}

	render() {
		const {children} = this.props

		return (
			<>
				<HeaderSite/>
				{children}
				<FooterSite/>
				{!!this.props.toastMessage && (
					<Snackbar
						open={true}
						autoHideDuration={6000}
						onClose={this.onToastClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
					>
						<Alert onClose={this.onToastClose} severity={this.props.toastType}>
							{this.props.toastMessage.map((v, k) => (
								<Fragment key={k}>
									{v}
									<br/>
								</Fragment>
							))}
						</Alert>
					</Snackbar>
				)}
			</>
		)
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

const mapStateToProps = (state) => ({
	user: state.user,
	toastMessage: state.util.toastMessage,
	toastType: state.util.toastType,
	onToastClose: state.util.onToastClose,
})

const mapDispathToProp = (dispatch) => ({
	login: (user) => dispatch(login(user)),
	logout: () => dispatch(logout()),
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
	alerta: () => dispatch(alerta()),
	alertaSucesso: (alertaTitulo, alertaMensagem, alertaExtra) => dispatch(alertaSucesso(alertaTitulo, alertaMensagem, alertaExtra)),
	alertaErro: (alertaTitulo, alertaMensagem) => dispatch(alertaErro(alertaTitulo, alertaMensagem)),
	abreModal: (tipo, modalExtra) => dispatch(abreModal(tipo, modalExtra)),
	fechaModal: () => dispatch(fechaModal()),
})

export default connectWithStore(mapStateToProps, mapDispathToProp)(Layout)
