import React from 'react'
import Layout from '../../Components/Recrutador/Layout'
import Title from '../../Components/Recrutador/Title'
import CandidatosLista from '../../Components/Recrutador/ListaCandidatos'
import PageMeta from '../../Components/PageMeta'

const ListaCandidato = () => {
	return (
		<Layout>
			<PageMeta
				title="Candidatos"
				description="description da tela de candidatos"
				keywords="Vaga, Momentum, Recrutamento"
			/>

			<Title title='Todas os candidatos'/>

			<section className="ultimos-candidatos">
				<div className="label label--pendente">Pendentes</div>
				<CandidatosLista
					tipo="pendente"
					paginacao={true}
				/>
			</section>

			<section className="ultimos-candidatos">
				<div className="label label--aprovados">Aprovados</div>
				<CandidatosLista
					tipo="aprovado"
					paginacao={true}
				/>
			</section>

			<section className="ultimos-candidatos">
				<div className="label label--reprovados">Reprovados</div>
				<CandidatosLista
					tipo="reprovado"
					paginacao={true}
				/>
			</section>

			{/*<section className="ultimos-candidatos">
				<div className="label label--pendente">Aguardando</div>
				<CandidatosLista
					tipo="incompleto"
					paginacao={true}
				/>
			</section>*/}
		</Layout>
	)
}

export default ListaCandidato