const defaultState = {
	toastMessage: undefined,
	toastType: undefined,
	onToastClose: undefined,

	listaTimestamp: new Date().getTime(),

	alertaTipo: undefined,
	alertaTitulo: undefined,
	alertaMensagem: undefined,
	alertaExtra: undefined,

	modal:undefined,
	modalExtra: undefined,

	erros: undefined,
}
const utilReducer = (state = defaultState, action) => {
	switch(action.type) {
		case 'TOAST':
			return {
				...state,
				toastMessage: action.toastMessage && !(action.toastMessage instanceof Array)? [action.toastMessage]: action.toastMessage,
				toastType: action.toastType,
				onToastClose: action.onToastClose
			}
		case 'UPDATE_LISTA_TIMESTAMP': {
			return {
				...state,
				listaTimestamp: new Date().getTime()
			}
		}
		case 'ALERTA':
			return {
				...state,
				alertaTipo: action.alertaTipo,
				alertaTitulo: action.alertaTitulo,
				alertaMensagem: action.alertaMensagem,
				alertaExtra: action.alertaExtra
			}
		case 'MODAL':
			return {
				...state,
				modal: action.modal,
				modalExtra: action.modalExtra
			}
		case 'ERROS':
			return {
				...state,
				erros: action.erros,
			}
		default:
			return state
	}
}

export default utilReducer
