import 'webrtc-adapter'
import React, {Component} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCamera, faTimes} from "@fortawesome/free-solid-svg-icons"
import Modal from 'react-modal'
import VideoRecorder from 'react-video-recorder'
import Loading from "../../Loading"
import {post} from "../../../Helpers/request"
import {alerta, alertaErro} from "../../../Actions/util"
import connectWithStore from "../../../Stores/connectWithStore"
import Actions from "./VideoRecord/render-actions"
import ErrorView from './VideoRecord/error-view'
import UnsupportedView from './VideoRecord/unsupported-view'
import PropTypes from "prop-types"

Modal.setAppElement('#root')

class VideoRecord extends Component {
	_isMounted = false
	state = {
		loading: false,
		isModalOpen: false,
		videoRecorded: false,
		file: undefined,
		video: {},
		onStartRecording: false
	}

	handleModalGravar = () => {
		this._isMounted && this.setState(() => ({
			isModalOpen: true
		}))
	}

	handleCloseModal = () => {
		this._isMounted && this.setState(() => ({
			videoRecorded: false,
			isModalOpen: false,
			onStartRecording: false
		}))
	}

	showVideoRecorded = video => {
		const url = new FileReader()
		console.log(video)
		url.readAsDataURL(video)
		url.onload = (e) => this._isMounted && this.setState(() => ({
			video: e.target.result,
			videoRecorded: true,
		}))
		this._isMounted && this.setState(() => ({
			file: new File([video], 'video.webm', {type: video.type})
		}))
	}

	onRecordingComplete = video => {
		this._isMounted && this.setState(() => ({
			file: new File([video], 'video.webm', {type: video.type})
		}), () => this.onEnviarVideo())
	}

	handleRegravarVideo = () => {
		this._isMounted && this.setState(() => ({
			videoRecorded: false
		}))
	}

	handleStartRecording = () => {
		this._isMounted && this.setState(() => ({
			onStartRecording: true
		}))
	}

	onEnviarVideo = () => {
		this.setState(() => ({
			loading: true,
		}))
		const dados = new FormData()
		dados.append('resposta', this.state.file)
		post('upload/vaga_resposta', dados)
			.then(({data}) => {
				this.props.onArquivoOk(data.id)
			})
			.catch((err) => {
				this.props.alertaErro(err.response && err.response.data.message ? err.response.data.message : err.message, null, {onRequestClose: () => {
					this.onEnviarVideo()
					this.props.fechaModal()
				}})
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})
	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		return (
			<>
				<div>
					<button
						className="btnVideo btnVideo--gravar"
						onClick={this.handleModalGravar}>
						<FontAwesomeIcon icon={faCamera}/>
					</button>
					<p className="legenda-botao">
						Verifique se sua câmera e microfone
						<br/>
						estão conectados para gravar
					</p>
				</div>

				<Modal
					isOpen={this.state.isModalOpen}
					onRequestClose={this.handleCloseModal}
					shouldCloseOnOverlayClick={false}
					shouldCloseOnEsc={false}
					className="Modal Modal__video"
					overlayClassName="Overlay">

					{this.state.videoRecorded
						? (
							this.state.loading ? (
								<Loading/>
							) : (
								<div className="wrap-video-gravado">
									<video controls>
										<source src={this.state.video} type="video/webm"/>
									</video>
									<div className="wrap-video-gravado__buttons">
										<button
											type="submit"
											className="btnDefault btnDefault--btn1"
											onClick={this.onEnviarVideo}
										>
											Enviar vídeo
										</button>
										{/* <button
											onClick={this.handleRegravarVideo}
											type="button"
											className="btnDefault btnDefault--btn1">
											Regravar vídeo
										</button> */}
										{/* <button
											onClick={this.handleCloseModal}
											type="button"
											className="btnDefault btnDefault--btn5">
											Cancelar
										</button> */}
									</div>
								</div>
							)
						)
						: (
							<>
								{!this.state.onStartRecording &&
									<button
										className="close-modal"
										onClick={this.handleCloseModal}
									>
										<FontAwesomeIcon icon={faTimes}/>
									</button>
								}

								<h1 className="pergunta-modal">{this.props.pergunta}</h1>
								
								<VideoRecorder
									isOnInitially
									timeLimit={120000}
									countdownTime={0}
									isFlipped={false}
									showReplayControls
									replayVideoAutoplayAndLoopOff={true}
									mimeType='video/webm;codecs="vp8,opus"'
									onStartRecording={this.handleStartRecording}
									onRecordingComplete={(videoBlob) => this.onRecordingComplete(videoBlob)}
									renderLoadingView={() => {return (<p>Carregando...</p>)}}
									renderActions={(props) => <Actions {...props} onCancelar={this.handleCloseModal} onEnviarVideo={this.onEnviarVideo} enviarLoading={this.state.loading}/>}
									renderUnsupportedView={() => <UnsupportedView />}
									renderErrorView={() => <ErrorView />}
									constraints={{ audio: true, video: { facingMode: { exact: "user" } } }}
								/>
							</>
						)
					}
				</Modal>
			</>
		)
	}
}

VideoRecord.propTypes = {
	pergunta: PropTypes.string.isRequired
}

const mapDispatchToProps = (dispatch) => ({
	alertaErro: (alertaTitulo, alertaMensagem, alertaExtra) => dispatch(alertaErro(alertaTitulo, alertaMensagem, alertaExtra)),
	fechaModal: () => dispatch(alerta(undefined)),
})

export default connectWithStore(null, mapDispatchToProps)(VideoRecord)