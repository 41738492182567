import React from 'react'
import PageMeta from '../../Components/PageMeta'
import TituloCandidato from '../../Components/Candidato/Titulo'
import DadosPessoais from '../../Components/Candidato/DadosPessoais'
import LayoutLogado from "../../Components/Site/Layout/logado"
import Curriculo from "../../Components/Candidato/Curriculo"

const PerfilCandidato = () => {
    return (
        <LayoutLogado>
            <PageMeta
                title="Dados Pessoais"
                description="Description tela Dados Pessoais"
                keywords="Vaga, Momentum, Recrutamento"
            />

            <TituloCandidato titulo="Informações sobre você" />
            
            <div className="container">
                <DadosPessoais titulo="Dados pessoais" exibeBtCv={false} />
                <Curriculo titulo="Meu currículo" />
            </div>
        </LayoutLogado>
    )
}

export default PerfilCandidato