import Modal from "react-modal"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTimes} from "@fortawesome/free-solid-svg-icons"
import React, {Component} from "react"
import {post} from "../../Helpers/request"
import {login} from "../../Actions/user"
import CircularProgress from "@material-ui/core/CircularProgress"
import Erro from "./Erro"
import connectWithStore from "../../Stores/connectWithStore"
import {isLoggedIn, isRecrutador} from "../../Helpers/user"
import {Redirect} from "react-router-dom"
import {abreModal, fechaModal} from "../../Actions/util"

class Login extends Component {
	_isMounted = false
	state = {
		email: '',
		password: '',
		loading: false,
		erroMensagem: undefined,
		erroCampos: [],
	}

	onChange = (e) => {
		const obj = {}
		obj[e.target.name] = e.target.value
		this._isMounted && this.setState(() => (obj))
	}

	onSubmit = (e) => {
		e.preventDefault()
		this._isMounted && this.setState(() => ({
			loading: true,
			erroMensagem: undefined,
			erroCampos: []
		}))
		const dados = {
			email: this.state.email,
			password: this.state.password,
		}
		post('users/session', dados)
			.then(({data}) => {
				if (data.status) {
					return this.props.login(data.user)
				}
				else {
					this._isMounted && this.setState(() => ({
						erroMensagem: data.erros ? undefined : data.message,
						erroCampos: data.erros || {}
					}))
				}
			})
			.then(() => {
				if (this.props.onLoggedIn) {
					this.props.onLoggedIn()
				}
			})
			.catch((err) => {
				//erro controlado
				if (err.response) {
					this._isMounted && this.setState(() => ({
						erroMensagem: err.response.data.erros ? undefined : err.response.data.message,
						erroCampos: err.response.data.erros || {}
					}))
				}
				//erro aleatório
				else {
					this._isMounted && this.setState(() => ({
						erroMensagem: err.response && err.response.data.message ? err.response.data.message : err.message,
					}))
				}
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})
	}

	onClose = () => {
		if (this.props.onClose) {
			this.props.onClose()
		}
		else {
			this.props.fechaModal()
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (isLoggedIn()) {
			if (!isRecrutador()) {
				this.onClose()
			}
		}
	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		if (isLoggedIn() && isRecrutador()) {
			this.props.fechaModal()
			return (
				<Redirect to="/recrutador" />
			)
		}
		return (
			<Modal
				{...this.props}
				isOpen
				className="Modal Modal__login"
				overlayClassName="Overlay"
				onRequestClose={this.onClose}
			>
				<div className="modalContent">
					<button className="close-modal" onClick={this.onClose}>
						<FontAwesomeIcon icon={faTimes}/>
					</button>
					<h1 className="modalContent__titulo">Bem-vindo!</h1>
					<form className="modal-form" onSubmit={this.onSubmit}>
						<div className="modal-form__item">
							<label
								htmlFor="email"
								className="labelDefault">
								E-mail*
							</label>
							<input
								type="email"
								id="email"
								name="email"
								className="inputDefault"
								placeholder="Digite seu e-mail"
								maxLength={255}
								value={this.state.email}
								onChange={this.onChange}
							/>
						</div>
						<div className="modal-form__item">
							<label
								htmlFor="senha"
								className="labelDefault">
								Senha*
							</label>
							<input
								type="password"
								id="senha"
								name="password"
								className="inputDefault"
								placeholder="Digite sua senha"
								maxLength="20"
								value={this.state.password}
								onChange={this.onChange}
							/>
						</div>
						{this.state.erroMensagem &&
						<div className="modal-form__item">
							<Erro mensagem={this.state.erroMensagem}/>
						</div>
						}
						<div className="modal-form__item submitWrap">
							<button
								type="submit"
								className="btnDefault btnDefault--btn4 btnDefault--loading"
								disabled={this.state.loading}
							>
								{this.state.loading ?
									<>
										<CircularProgress className="circle-loading" size={20} />
										Entrando
									</>
									: 'Entrar'}
							</button>
							<button className="btnEsqueciSenha" onClick={() => this.props.abreModal('esqueci-minha-senha')}>Esqueci minha senha</button>
						</div>
						<div className="modal-form__item">
							<p className="sem-cadastro" onClick={() => this.props.abreModal('cadastro')}>
								Ainda não tenho cadastro. <strong>Quero me cadastrar agora.</strong>
							</p>
						</div>
					</form>
				</div>
			</Modal>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	login: (user) => dispatch(login(user)),
	fechaModal: () => dispatch(fechaModal()),
	abreModal: (tipo) => dispatch(abreModal(tipo)),
})

export default connectWithStore(null, mapDispatchToProps)(Login)
