import React, {Component} from 'react'
import Layout from '../../Components/Site/Layout'
import InfosIniciaisVaga from '../../Components/Site/InfosIniciasVaga'
import DescricaoVaga from '../../Components/Site/DescricaoVaga'
import ListaOportunidades from '../../Components/Site/ListaOportunidades'
import {get} from "../../Helpers/request"
import Loading from "../../Components/Loading"
import Erro from "../../Components/ErrorMessage"
import {alertaErro} from "../../Actions/util"
import connectWithStore from "../../Stores/connectWithStore"
import {isLoggedIn} from "../../Helpers/user"
import PageMeta from '../../Components/PageMeta'

class VagasSite extends Component {
	_isMounted = false
	state = {
		vaga: undefined,
		candidatura: undefined,
		loadingVaga: true,
		errorVaga: undefined,
		redirect: undefined,
	}

	getVaga = (e) => {
		e && e.preventDefault()

		this.setState(() => ({
			errorVaga: false,
			loadingVaga: true,
		}))

		get(`vaga/${this.props.match.params.id}`)
			.then(({data}) => {
				this._isMounted && this.setState(() => ({
					vaga: data.vaga
				}))
			})
			.catch((err) => {
				if (err.response && err.response.status === 404) {
					this.props.alertaErro(err.response.data.message)
					this._isMounted && this.props.history.push('/')
				}
				else {
					this._isMounted && this.setState(() => ({
						errorVaga: err.response && err.response.data.message ? err.response.data.message : err.message
					}))
				}
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loadingVaga: false,
				}))
			})
	}

	getCandidatura = (e) => {
		e && e.preventDefault()

		get(`vaga/${this.props.match.params.id}/candidatura`)
			.then(({data}) => {
				this._isMounted && this.setState(() => ({
					candidatura: data.candidatura
				}))
			})
			.catch((err) => {
				this._isMounted && this.setState(() => ({
					candidatura: undefined
				}))
			})
			.finally(() => {
			})
	}

	componentDidMount() {
		this._isMounted = true
		this.getVaga()
		if (isLoggedIn()) {
			this.getCandidatura()
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.getVaga()
			if (isLoggedIn()) {
				this.getCandidatura()
			}
		}
		if (prevProps.user !== this.props.user) {
			this.getCandidatura()
		}
	}

	render() {
		return (
			<Layout>
                <PageMeta
                    title="Vagas"
                    description="Descrição tela vaga"
                    keywords="Vaga, Momentum, Recrutamento"
                />

				<div className="container">
					<section className="infoVagaSite">
						<div className="infoVagaSite__item infoVagaSite__item--content-vaga">
							{this.state.loadingVaga
								? (<Loading/>)
								: (
									this.state.errorVaga
										? (
											<Erro mensagem={this.state.errorVaga} tryAgainHandler={this.getVaga}/>
										)
										: (
											this.state.vaga && (
												<>
													<InfosIniciaisVaga vaga={this.state.vaga} candidatura={this.state.candidatura} pagina="vagas"/>

													<DescricaoVaga vaga={this.state.vaga} candidatura={this.state.candidatura} pagina="vagas"/>
												</>
											)
										)
								)
							}
						</div>
						<div className="infoVagaSite__item infoVagaSite__item--outras-vagas">
							<h1>Outras vagas</h1>

							<ListaOportunidades pagina="vagas"/>
						</div>
					</section>
				</div>
			</Layout>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
	alertaErro: (alertaTitulo) => dispatch(alertaErro(alertaTitulo)),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(VagasSite)
