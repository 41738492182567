import React, {Component} from 'react'
import AvaliacaoItem from "./item"
import connectWithStore from "../../../Stores/connectWithStore"

class Listagem extends Component {
	render() {
		return (
			<section className="listagem">
				{this.props.vagaCandidatoAvaliacao.length === 0
					? (
						<p>
							Nenhum comentário encontrado.
						</p>
					)
					: (
						this.props.vagaCandidatoAvaliacao.map((v, k) => (
							<AvaliacaoItem avaliacao={v} key={k} />
						))
					)}
			</section>
		)
	}
}

Listagem.defaultProps = {
	avaliacoes: [],
}

Listagem.propTypes = {
}

const mapStateToProps = (state) => ({
	vagaCandidatoAvaliacao: state.vagaCandidatoAvaliacao,
})

export default connectWithStore(mapStateToProps)(Listagem)
