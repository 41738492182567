import React from 'react'
import ListaOportunidades from '../ListaOportunidades'

const OportunidadesSite = () => (
    <section className="oportunidadesHome">
        <div className="container">
            <h1 className="oportunidadesHome__titulo">
                Confira as oportunidades da Momentum
            </h1>
            <h2 className="oportunidadesHome__subTitulo">
                Evolua na carreira em um dos setores que mais crescem no país
            </h2>

            <ListaOportunidades pagina="home" />
        </div>
    </section>
)

export default OportunidadesSite