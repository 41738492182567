import React, {Component} from 'react'
import TituloCandidato from '../../Components/Candidato/Titulo'
import InfosIniciaisVaga from '../../Components/Site/InfosIniciasVaga'
import DescricaoVaga from '../../Components/Site/DescricaoVaga'
import DadosPessoais from '../../Components/Candidato/DadosPessoais'
import PerguntaLista from '../../Components/Candidato/Candidatar/PerguntaLista'
import {get, patch} from "../../Helpers/request"
import Loading from "../../Components/Loading"
import Erro from "../../Components/ErrorMessage"
import LayoutLogado from "../../Components/Site/Layout/logado"
import {alertaErro} from "../../Actions/util"
import connectWithStore from "../../Stores/connectWithStore"
import PageMeta from '../../Components/PageMeta'

class Regravar extends Component {
	_isMounted = false
	state = {
		vaga: undefined,
		candidatura: undefined,
		loadingVaga: true,
		errorVaga: undefined,
		redirect: undefined,
		visible: false,
	}

	getVaga = (e) => {
		e && e.preventDefault()

		this.setState(() => ({
			errorVaga: false,
			loadingVaga: true,
		}))
		Promise.all([
			get(`vaga/${this.props.match.params.id}`),
			get(`vaga/${this.props.match.params.id}/candidatura`),
		])
			.then((values) => {
				if (this._isMounted) {
					//vaga
					const data1 = values[0].data
					//candidatura
					const data2 = values[1].data

					data2.candidatura.respostas = data2.candidatura.respostas.filter((v, k) => {
						if (v.permite_regravar === 'nao') {
							data1.vaga.pergunta = data1.vaga.pergunta.filter((pergunta) => pergunta.id !== v.id_vaga_questionario)
							return false
						}
						return true
					})

					if (data2.candidatura.respostas.length === 0) {
						this.props.alertaErro('Não há nenhuma resposta pendente de regravação.')
						this.props.history.replace(`/vagas/${this.props.match.params.id}`)
					}

					this.setState(() => ({
						vaga: data1.vaga,
						candidatura: data2.candidatura
					}))
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 404) {
					this.props.alertaErro(err.response.data.message)
					this._isMounted && this.props.history.replace(`/vagas/${this.props.match.params.id}`)
				}
				else {
					this._isMounted && this.setState(() => ({
						errorVaga: err.response && err.response.data.message ? err.response.data.message : err.message
					}))
				}
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loadingVaga: false,
				}))
			})
	}

	componentDidMount() {
		this._isMounted = true
		this.getVaga()
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	handleShowDescription = e => {
		this.setState((prevState) => ({
			visible: !prevState.visible
		}))
	}

	render() {
		return (
			this.state.loadingVaga
				? (<Loading/>)
				: (
					this.state.errorVaga
						? (
							<Erro mensagem={this.state.errorVaga} tryAgainHandler={this.getVaga}/>
						)
						: (
							this.state.vaga && (
								<LayoutLogado redirectTo={`/vagas/${this.state.vaga.id}`}>
									<PageMeta
										title="Regravar"
										description="Description tela Regravar"
										keywords="Vaga, Momentum, Recrutamento"
									/>

									<TituloCandidato titulo="Regravando resposta"/>

									<div className="container">
										<InfosIniciaisVaga vaga={this.state.vaga} pagina="candidatar"/>

										{this.state.visible && <DescricaoVaga vaga={this.state.vaga}/>}
										<button
											onClick={this.handleShowDescription}
											className="btnDescricaoCompleta btnDefault btnDefault--btn1">
											{!this.state.visible ? 'Ver descrição completa' : 'Recolher descrição'}
										</button>

										<div className="line"/>

										<DadosPessoais titulo="Meus dados"/>

										<PerguntaLista vaga={this.state.vaga} metodo={patch}/>
									</div>
								</LayoutLogado>
							)
						)
				)
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	alertaErro: (alertaTitulo) => dispatch(alertaErro(alertaTitulo)),
})

export default connectWithStore(null, mapDispatchToProps)(Regravar)
