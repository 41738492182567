import React from 'react'
import MuiAlert from '@material-ui/lab/Alert'
import PropTypes from "prop-types"

const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Vazio = props => (
    <div className="lista-vazio">
        <Alert severity="warning">{props.message}</Alert>
    </div>
)

Vazio.propTypes = ({
    message: PropTypes.string.isRequired
})

export default Vazio