//TODO colocar o tipo como primeiro parâmetro
export const setToast = (toastMessage, toastType, onToastClose = undefined) => ({
	type: 'TOAST',
	toastMessage,
	toastType,
	onToastClose
})

export const updateListaTimestamp = () => ({
	type: 'UPDATE_LISTA_TIMESTAMP'
})

export const alerta = (alertaTipo, alertaTitulo, alertaMensagem, alertaExtra) => ({
	type: 'ALERTA',
	alertaTipo,
	alertaTitulo,
	alertaMensagem,
	alertaExtra
})

export const alertaSucesso = (alertaTitulo, alertaMensagem, alertaExtra) => ({
	type: 'ALERTA',
	alertaTipo: 'sucesso',
	alertaTitulo,
	alertaMensagem,
	alertaExtra
})

export const alertaErro = (alertaTitulo, alertaMensagem, alertaExtra) => ({
	type: 'ALERTA',
	alertaTipo: 'erro',
	alertaTitulo,
	alertaMensagem,
	alertaExtra
})

export const abreModal = (tipo, modalExtra) => ({
	type: 'MODAL',
	modal: tipo,
	modalExtra
})

export const fechaModal = () => ({
	type: 'MODAL',
	modal: undefined
})

export const setErros = (erros) => ({
	type: 'ERROS',
	erros
})

export const unsetErros = () => ({
	type: 'ERROS',
	erros: undefined,
})
