import React from 'react'
import Layout from '../../Components/Recrutador/Layout'
import Title from '../../Components/Recrutador/Title'
import VagasLista from '../../Components/Recrutador/ListaVagas'
import CandidatosLista from '../../Components/Recrutador/ListaCandidatos'
import PageMeta from '../../Components/PageMeta'

const HomeRecrutador = () => {
	return(
		<Layout>
			<PageMeta
				title="Dashboard"
				description="description home dashboard"
				keywords="Vaga, Momentum, Recrutamento"
			/>

			<Title title='Vagas Ativas'/>

			<VagasLista
				tipo="live"
				limit={3}
				paginacao={false}
				mostraBtVerTodas={true}
			/>

			<Title title="Últimas Candidaturas"/>

			<section className="ultimos-candidatos ultimos-candidatos--home">
				<CandidatosLista
					limit={3}
					mostraBtVerTodas={true}
				/>
			</section>
		</Layout>
	)
}

export default HomeRecrutador
