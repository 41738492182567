import React, {Component} from 'react'
import PropTypes from "prop-types"
import InfosIniciaisVaga from "../../Site/InfosIniciasVaga"
import DescricaoVaga from "../../Site/DescricaoVaga"

class InfoVagaCompartilhado extends Component {
	state = {
		visible: false,
	}

	handleShowDescription = e => {
		this.setState((prevState) => ({
			visible: !prevState.visible
		}))
	}

	render() {

		return (
			<>
				<InfosIniciaisVaga vaga={this.props.vaga} pagina="candidatar"/>

				{this.state.visible && <DescricaoVaga vaga={this.props.vaga}/>}
				<button
					onClick={this.handleShowDescription}
					className="btnDescricaoCompleta btnDefault btnDefault--btn1">
					{!this.state.visible ? 'Ver descrição completa' : 'Recolher descrição'}
				</button>

				<div className="line"/>
			</>
		)
	}
}

InfoVagaCompartilhado.propTypes = {
	vaga: PropTypes.object.isRequired
}

export default InfoVagaCompartilhado
