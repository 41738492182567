import React, {Component} from 'react'
import DropZone from './Dropzone'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck} from "@fortawesome/free-solid-svg-icons"
import PropTypes from "prop-types"
import {setToast} from "../../../Actions/util"
import {logout} from "../../../Actions/user"
import connectWithStore from "../../../Stores/connectWithStore"
import {patch, post} from "../../../Helpers/request"
import Loading from "../../Loading"
import Erro from "../../ErrorMessage"

class Pergunta extends Component {
	_isMounted = false
	state = {
		loading: false,
		erroMensagem: undefined,
		resposta: undefined,
	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	onResposta = (arquivo) => {
		this.setState(() => ({
			resposta: arquivo,
		}), this.onSubmit)
	}
	onSubmit = () => {
		this.setState(() => ({
			loading: true,
			erroMensagem: undefined,
		}))

		const dados = {
			resposta: this.state.resposta
		}

		this.props.metodo(`vaga/${this.props.match.params.id}/resposta/${this.props.pergunta.id}`, dados)
			.then(({data}) => {
				this.props.onResposta(this.props.numero, this.state.resposta)
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					this.props.setToast('Sua sessão expirou', 'error')
					this.props.logout()
					return
				}
				//erro controlado
				if (err.response) {
					this._isMounted && this.setState(() => ({
						erroMensagem: err.response.data.message,
					}))
				}
				//erro aleatório
				else {
					this._isMounted && this.setState(() => ({
						erroMensagem: err.response && err.response.data.message ? err.response.data.message : err.message,
					}))
				}

			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})
	}

	render() {
		const {pergunta, numero, status} = this.props
		return (
			<>
				<div className="questionario-wrap__box-video-pergunta">
					<div
						className={`label-pergunta label-pergunta--${status}`}>Pergunta {numero + 1}</div>
					<p className="txt-pergunta">
						{pergunta.pergunta}
					</p>
					{this.state.loading
						? <Loading/>
					: (
						this.state.erroMensagem
							? <Erro mensagem={this.state.erroMensagem} tryAgainHandler={this.onSubmit} />
							: (
								<>
									{status === 'respondida' && (<FontAwesomeIcon icon={faCheck} className="ico-sucesso"/>)}
									{status === 'ativa' && (<DropZone onResposta={this.onResposta} pergunta={pergunta.pergunta}/>)}
								</>
							)
					)}
				</div>

			</>
		)
	}
}


Pergunta.propTypes = {
	pergunta: PropTypes.object.isRequired,
	numero: PropTypes.number.isRequired,
	status: PropTypes.oneOf(['respondida', 'ativa', 'pendente']),
	onResposta: PropTypes.func.isRequired,
	metodo: PropTypes.oneOf([post, patch]),
}

const mapStateToProps = (state) => ({
	user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
	logout: () => dispatch(logout()),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(Pergunta)
