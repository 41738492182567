import React from 'react'
import {NavLink} from 'react-router-dom'
import Modal from 'react-modal'
import Contato from "../../Modal/Contato"
import Login from "../../Modal/Login"
import Cadastro from "../../Modal/Cadastro"
import BtnPerfil from '../../Buttons/perfil'
import Hidden from '@material-ui/core/Hidden'
import logoSite from '../../../img/logo.png'
import HeaderMobileSite from '../HeaderMobile'
import connectWithStore from "../../../Stores/connectWithStore"
import {isCandidato, isLoggedIn, isRecrutador} from "../../../Helpers/user"
import ModalAlerta from "../../Modal/Alerta"
import {abreModal, fechaModal} from "../../../Actions/util"
import EsqueciMinhaSenha from "../../Modal/EsqueciMinhaSenha"
import EsqueciNovaSenha from "../../Modal/EsqueciNovaSenha"
import CurriculoModal from "../../Modal/Curriculo"
import ModalInput from "../../Modal/Candidatar/Input"
import ModalInputLocalidade from "../../Modal/Candidatar/InputLocalidade"

Modal.setAppElement('#root')

const HeaderSite = ({user, alertaTipo, modal, modalExtra, abreModal}) => {
	return (
		<header className="header">
			<div className="container">
				<div className="header__wrap">
					<div className="header__item header__item--logo">
						<NavLink to="/" exact>
							<img src={logoSite}
							     alt="Programa de Excelencia Momentum"/>
						</NavLink>
					</div>
					{/* Mobile */}
					<Hidden mdUp>
						<HeaderMobileSite/>
					</Hidden>
					{/* Desktop */}
					<Hidden smDown>
						<div className="header__item header__item--menu">
							<NavLink
								to="/"
								exact
								className="header__link"
								activeClassName="active"
							>
								Home
							</NavLink>
							{!isRecrutador() && (
								<button
								   onClick={() => abreModal('contato')}
								   className="header__link">
									Contato
								</button>
							)}
							{!isLoggedIn() && (
								<button
								   onClick={() => abreModal('cadastro')}
								   className="header__link">
									Cadastrar
								</button>
							)}
							{isRecrutador() && (
								<NavLink
									to="/recrutador/"
									className="header__link header__link--login"
								>
									Dashboard
								</NavLink>
							)}
							{!isLoggedIn() && (
								<button
									onClick={() => abreModal('login')}
									className="header__link header__link--login">
									Entrar
								</button>
							)}
							{isCandidato() && (
								<BtnPerfil/>
							)}
						</div>
					</Hidden>
				</div>
			</div>
			{alertaTipo && (<ModalAlerta/>)}
			{modal === 'contato' && <Contato {...modalExtra} />}
			{modal === 'cadastro' && <Cadastro {...modalExtra} />}
			{modal === 'login' && <Login {...modalExtra} />}
			{modal === 'esqueci-minha-senha' && <EsqueciMinhaSenha {...modalExtra} />}
			{modal === 'esqueci-nova-senha' && <EsqueciNovaSenha {...modalExtra} />}
			{modal === 'curriculo' && <CurriculoModal {...modalExtra} />}
			{modal === 'input' && <ModalInput {...modalExtra} />}
			{modal === 'input-localidade' && <ModalInputLocalidade {...modalExtra} />}
		</header>
	)
}

const mapStateToProps = (state) => ({
	user: state.user,
	alertaTipo: state.util.alertaTipo,
	modal: state.util.modal,
	modalExtra: state.util.modalExtra
})

const mapDispatchToProps = (dispatch) => ({
	abreModal: (tipo) => dispatch(abreModal(tipo)),
	fechaModal: () => dispatch(fechaModal()),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(HeaderSite)
