import React, {Component, Fragment} from 'react'

import PropTypes from 'prop-types'
import {login, logout} from "../../../Actions/user"
import connectWithStore from "../../../Stores/connectWithStore"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from '@material-ui/lab/Alert'
import {abreModal, setToast} from "../../../Actions/util"
import ModalCurriculoRecrutador from "../../Modal/CurriculoRecrutador"

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />
}

class LayoutCompartilhado extends Component {
	onToastClose = (event, reason) => {
		if (this.props.onToastClose) {
			return this.props.onToastClose(event, reason)
		}

		if (reason === 'clickaway') {
			return
		}
		this.props.setToast(undefined)
	}

	render() {
		const {children} = this.props

		return (
			<main className="mainDashboard">
				<section
					className={"contentDashboard share"}>
					<div className="contentDashboard__wrap share">
						<section className="recrutadorWrapper">
							<div className="container">
								{children}
							</div>
						</section>
					</div>
				</section>

				{!!this.props.toastMessage && (
					<Snackbar
						open={true}
						autoHideDuration={6000}
						onClose={this.onToastClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
					>
						<Alert onClose={this.onToastClose} severity={this.props.toastType}>
							{this.props.toastMessage.map((v, k) => (
								<Fragment key={k}>
									{v}
									<br/>
								</Fragment>
							))}
						</Alert>
					</Snackbar>
				)}
				{this.props.modal === 'curriculo-recrutador' && <ModalCurriculoRecrutador {...this.props.modalExtra} />}
			</main>
		)
	}
}

LayoutCompartilhado.propTypes = {
	children: PropTypes.node.isRequired,
}

const mapStateToProps = (state) => ({
	user: state.user,
	toastMessage: state.util.toastMessage,
	toastType: state.util.toastType,
	onToastClose: state.util.onToastClose,
	modal: state.util.modal,
	modalExtra: state.util.modalExtra
})

const mapDispatchToProps = (dispatch) => ({
	login: (user) => dispatch(login(user)),
	logout: () => dispatch(logout()),
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
	abreModal: (tipo, modalExtra) => abreModal(tipo, modalExtra)
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(LayoutCompartilhado)
