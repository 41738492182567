import React, {Component} from "react"
import Loading from "../../Loading"
import Layout from "./index"
import PropTypes from "prop-types"
import {login, logout} from "../../../Actions/user"
import {setToast} from "../../../Actions/util"
import connectWithStore from "../../../Stores/connectWithStore"
import {get} from "../../../Helpers/request"
import {isCandidato, isRecrutador} from "../../../Helpers/user"
import {Redirect} from "react-router-dom"

class LayoutLogado extends Component {
	state = {
		loginVerificado: false,
	}

	componentDidMount() {
		if (!this.props.user) {
			get('users')
				.then(({data}) => {
					if (data.status) {
						 this.props.login(data.user)
					}
				})
				.catch((err) => {
					this.props.setToast('É necessário fazer login', 'error')
					this.props.logout()
				})
				.finally(() => {
					this.setState(() => ({
						loginVerificado: true
					}))
				})
		}
		else {
			this.setState(() => ({
				loginVerificado: true
			}))
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

	}

	render() {
		if (!this.state.loginVerificado) {
			return (
				<Loading/>
			)
		}

		if (!isCandidato()) {
			if (isRecrutador()) {
				this.props.setToast('Recrutadores não podem se candidatar a vagas', 'error')
			}

			return (
				<Redirect to={this.props.redirectTo? this.props.redirectTo: "/"}/>
			)
		}

		return (
			<Layout>{this.props.children}</Layout>
		)
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	redirectTo: PropTypes.string,
}

const mapStateToProps = (state) => ({
	user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
	login: (user) => dispatch(login(user)),
	logout: () => dispatch(logout()),
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(LayoutLogado)
