import React, { Component } from 'react'
import Layout from '../../Components/Site/Layout'
import BannerSite from '../../Components/Site/Banner'
import OportunidadesSite from '../../Components/Site/Oportunidades'
import SobreMomentum from '../../Components/Site/SobreMomentum'
import PageMeta from '../../Components/PageMeta'

class HomeSite extends Component {
    render () {
        const textoBanner = {
            titulo: "O lugar certo \n para o seu talento",
            texto: "A maior empresa de loteamentos de lazer do Brasil \n está sempre atenta ao mercado e à procura de profissionais \n talentosos, que venham somar à sua equipe de sucesso."
        }

        const textoSobre = {
            titulo: "Sobre a Momentum",
            texto1: "Com mais de 67 mil lotes em diversos empreendimentos no interior de São Paulo,consolidou-se como a maior empresa de loteamentos de lazer do Brasil.",
            texto2: "Os empreendimentos aliam projetos urbanísticos cuidadosamente planejados, que harmonizam infraestrutura, grandes áreas verdes, complexos de lazer, segurança e serviços de conservação certificados nas normas ISO 9.001 e 14.001, adotando a política do Sistema de Gestão Integrado denominada LOTES."
        }

        return (
            <Layout>
                <PageMeta
                    title="Home"
                    description="A maior empresa de loteamentos de lazer do Brasil está sempre atenta ao mercado e à procura de profissionais talentosos, que venham para agregar a sua equipe de sucesso."
                    keywords="Vaga, Momentum, Recrutamento"
                />

                <BannerSite
                    titulo={textoBanner.titulo}
                    texto={textoBanner.texto}
                />

                <OportunidadesSite />

                <SobreMomentum
                    titulo={textoSobre.titulo}
                    texto1={textoSobre.texto1}
                    texto2={textoSobre.texto2}
                />
            </Layout>
        )
    }
}

export default HomeSite
