export const setVagaCandidatoAvaliacao = (vagaCandidatoAvaliacao) => ({
	type: 'SET_VAGA_CANDIDATO_AVALIACAO',
	vagaCandidatoAvaliacao
})

export const unsetVagaCandidatoAvaliacao = () => ({
	type: 'UNSET_VAGA_CANDIDATO_AVALIACAO',
})

export const addVagaCandidatoAvaliacao = (avaliacao) => ({
	type: 'ADD_VAGA_CANDIDATO_AVALIACAO',
	avaliacao,
})

export const editVagaCandidatoAvaliacao = (avaliacao) => ({
	type: 'EDIT_VAGA_CANDIDATO_AVALIACAO',
	avaliacao,
})

export const removeVagaCandidatoAvaliacao = (id) => ({
	type: 'REMOVE_VAGA_CANDIDATO_AVALIACAO',
	id,
})
