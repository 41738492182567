import React from 'react'
import {NavLink} from 'react-router-dom'
import logoFooter from '../../../img/logoFooter.png'
import logoMomentumSite from '../../../img/logoMomentumSite.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons"

const FooterSite = () => (
    <footer className="footer">
        <div className="container">
            <div className="footer__wrap">
                <div className="footer__item footer__item--logo">
                    <a href="http://www.momentum.com.br" rel="nofollow noopener noreferrer" target="_blank">
                        <img src={logoMomentumSite} alt="Momentum" />
                    </a>
                </div>
                <div className="footer__item footer__item--logo">
                    <NavLink to="/" exact>
                        <img src={logoFooter} alt="PEM" />
                    </NavLink>
                </div>
                <div className="footer__item footer__item--socialWrapp">
                    <a className="footer__socialLinks" 
                        title="Facebook"
                        href="https://facebook.com/momentum.permitaseviver/"
                        rel="nofollow noopener noreferrer" 
                        target="_blank">
                        <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a className="footer__socialLinks"
                        title="Instagram" 
                        href="https://www.instagram.com/momentumempreendimentos/"
                        rel="nofollow noopener noreferrer" 
                        target="_blank">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a className="footer__socialLinks" 
                        title="LinkedIn"
                        href="https://www.linkedin.com/company/momentum-empreendimentos-imobili%C3%A1rios/"
                        rel="nofollow noopener noreferrer" 
                        target="_blank">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                </div>
            </div>
        </div>
    </footer>
)

export default FooterSite