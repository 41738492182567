import React, { useState } from 'react'

import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faHome, 
    faSuitcase, 
    faUser,
    faSignOutAlt 
    } from "@fortawesome/free-solid-svg-icons"
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'
import Logo from '../MenuSidebar/logo'
import {logout} from "../../../Actions/user"
import connectWithStore from "../../../Stores/connectWithStore"
import {del} from "../../../Helpers/request"
import {setToast} from "../../../Actions/util"

const MenuTopo = ({logout, setToast}) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
        event.currentTarget.classList.add("change")
    }

    const handleClose = () => {
        setAnchorEl(null)
        document.querySelector('.menu-hamburger').classList.remove("change")
    }

    const onLogout = (e) => {
        e.preventDefault()
        setToast(undefined)
        del('users/session')
            .then(({data}) => {
                logout()
            })
            .catch((err) => {
                const erroMensagem = err.response && err.response.data.message ? err.response.data.message : err.message
                setToast(erroMensagem, 'error')
            })
    }

    return (
        <section className="menu-topo-mobile">
            <div>
                <Logo />
            </div>
            <div>
                <button
                    type="button"
                    className="menu-hamburger"
                    aria-controls="menu-mobile" 
                    aria-haspopup="true" 
                    onClick={handleClick}
                >
                    <div className="bar bar--recrutador bar1"></div>
                    <div className="bar bar--recrutador bar2"></div>
                    <div className="bar bar--recrutador bar3"></div>
                </button>
                <Menu
                    id="menu-mobile"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    <MenuItem onClick={handleClose}>
                        <NavLink to="/recrutador/" className="links-mobile">
                            <FontAwesomeIcon icon={faHome} />
                            <span>Dashboard</span>
                        </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <NavLink to="/recrutador/vagas" className="links-mobile">
                            <FontAwesomeIcon icon={faSuitcase} />
                            <span>Vagas</span>
                        </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <NavLink to="/recrutador/candidatos/lista" className="links-mobile">
                            <FontAwesomeIcon icon={faUser} />
                            <span>Candidatos</span>
                        </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <button 
                            type="button" 
                            onClick={onLogout}
                            className="links-mobile">
                            <FontAwesomeIcon icon={faSignOutAlt} />
                            <span>Fazer logout</span>
                        </button>
                    </MenuItem>
                </Menu>
            </div>
        </section>
    )
}

const mapDispatchToProp = (dispatch) => ({
    logout: () => dispatch(logout()),
    setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
})

export default connectWithStore(null, mapDispatchToProp)(MenuTopo)