import React from 'react'

import avatarBg from '../../../img/avatar.png'

const InfoRecrutador = ({ name, cargo, avatarShrink, escondeTxt }) => (
    <div className="sidebarNav__item sidebarNav__item--infoWrapper">
        <div
            style={{backgroundImage: `url(${avatarBg})`}}
            className={avatarShrink}>
        </div>
        <h1 className={escondeTxt}>Olá, {name}</h1>
        <h2 className={escondeTxt}>{cargo}</h2>
    </div>
)

export default InfoRecrutador