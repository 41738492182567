import React from 'react'

const ErrorView = () => (
  <div style={{textAlign: 'center'}}>
    Encontramos um erro ao gravar seu vídeo.
    <br />
    <br />
    Por favor atualize a página e tente novamente.
  </div>
)

export default ErrorView