import {applyMiddleware, combineReducers, compose, createStore} from "redux"
import * as Sentry from "@sentry/react"
import userReducer from "../Reducers/user"
import thunk from "redux-thunk"
import utilReducer from "../Reducers/util"
import vagaCandidatoReducer from "../Reducers/vagaCandidato"
import vagaCandidatoAvaliacaoReducer from "../Reducers/vagaCandidatoAvaliacao"
import { isDev } from '../Helpers/util'

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const sentryReduxEnhancer = Sentry.createReduxEnhancer({

})

const configureStore = () => createStore(
	combineReducers({
		user: userReducer,
		util: utilReducer,
		vagaCandidato: vagaCandidatoReducer,
		vagaCandidatoAvaliacao: vagaCandidatoAvaliacaoReducer,
	}),
	isDev()?
		composeEnhancers(applyMiddleware(thunk)):
		composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer)
)
const store = configureStore()

export default store
