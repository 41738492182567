import React, {Component} from "react"
import {fechaModal} from "../../Actions/util"
import connectWithStore from "../../Stores/connectWithStore"
import Modal from "react-modal"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTimes} from "@fortawesome/free-solid-svg-icons"
import parse from "html-react-parser"
import PropTypes from "prop-types"

class ModalCurriculoRecrutador extends Component {
	_isMounted = false

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		return (
			<Modal
				{...this.props}
				isOpen
				className="Modal Modal__curriculo"
				overlayClassName="Overlay"
			>
				<div className="modalContent">
					<button className="close-modal" onClick={this.props.onRequestClose}>
						<FontAwesomeIcon icon={faTimes}/>
					</button>

					<div className="content-cv-dashboad">
						{parse(this.props.curriculoTexto)}
					</div>
				</div>
			</Modal>
		)
	}
}

ModalCurriculoRecrutador.propTypes = {
	curriculoTexto: PropTypes.string,
}

const mapDispatchToProps = (dispatch) => ({
	onRequestClose: () => dispatch(fechaModal()),
})

export default connectWithStore(null, mapDispatchToProps)(ModalCurriculoRecrutador)
