import React, {Component, Fragment} from 'react'

import Hidden from '@material-ui/core/Hidden'
import PropTypes from 'prop-types'
import SidebarMenu from '../MenuSidebar'
import MenuTopo from '../MenuTopo/'
import MenuMobile from '../MenuMobile/'
import {isRecrutador} from "../../../Helpers/user"
import {Redirect} from "react-router-dom"
import {get} from "../../../Helpers/request"
import Loading from "../../Loading"
import {login, logout} from "../../../Actions/user"
import connectWithStore from "../../../Stores/connectWithStore"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from '@material-ui/lab/Alert'
import {abreModal, setToast} from "../../../Actions/util"
import queryString from "query-string"
import ModalCurriculoRecrutador from "../../Modal/CurriculoRecrutador"
import ModalAlerta from "../../Modal/Alerta"

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />
}

class Layout extends Component {
	state = {
		menuOpen: true,
		loginVerificado: false
	}

	onToastClose = (event, reason) => {
		if (this.props.onToastClose) {
			return this.props.onToastClose(event, reason)
		}

		if (reason === 'clickaway') {
			return
		}
		this.props.setToast(undefined)
	}

	switchMenu = e => {
		e.preventDefault()

		this.setState({
			menuOpen: !this.state.menuOpen
		}, () => {
			localStorage.setItem("menu", this.state.menuOpen)
		})
	}

	getUser = () => {
		get('users')
			.then(({data}) => {
				if (data.status) {
					this.props.login(data.user)
				}
			})
			.catch((err) => {
				this.props.logout()
			})
			.finally(() => {
				this.setState(() => ({
					loginVerificado: true
				}))
			})
	}

	componentDidMount() {
		if (!this.props.user) {
			this.getUser()
		}
		else {
			this.setState(() => ({
				loginVerificado: true
			}))
		}

		localStorage.getItem("menu") && this.setState(() => ({
			menuOpen: JSON.parse(localStorage.getItem("menu"))
		}))
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.state.loginVerificado && prevProps.user !== this.props.user) {
		//	this.getUser()
		}
	}

	render() {
		if (!this.state.loginVerificado) {
			return (
				<Loading/>
			)
		}
		if (!isRecrutador()) {
			this.props.setToast('É necessário fazer o login', 'error')
			return (
				<Redirect to={`/login?${queryString.stringify( {r: `${this.props.location.pathname}${this.props.location.search}`})}`}/>
			)
		}

		const {children} = this.props

		return (
			<main className="mainDashboard">
				<SidebarMenu
					{...this.state}
					handleClick={(e) => this.switchMenu(e)}
				/>

				<Hidden smUp>
					<MenuMobile/>
				</Hidden>

				<section
					className={!this.state.menuOpen
						? "contentDashboard contentDashboard--shrinkPadding"
						: "contentDashboard"}
				>
					<div className="contentDashboard__wrap">
						<MenuTopo/>

						<section className="recrutadorWrapper">
							{children}
						</section>
					</div>
				</section>

				{!!this.props.toastMessage && (
					<Snackbar
						open={true}
						autoHideDuration={6000}
						onClose={this.onToastClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
					>
						<Alert onClose={this.onToastClose} severity={this.props.toastType}>
							{this.props.toastMessage.map((v, k) => (
								<Fragment key={k}>
									{v}
									<br/>
								</Fragment>
							))}
						</Alert>
					</Snackbar>
				)}
				{this.props.modal === 'curriculo-recrutador' && <ModalCurriculoRecrutador {...this.props.modalExtra} />}
				{this.props.alertaTipo && (<ModalAlerta/>)}
			</main>
		)
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

const mapStateToProps = (state) => ({
	user: state.user,
	toastMessage: state.util.toastMessage,
	toastType: state.util.toastType,
	onToastClose: state.util.onToastClose,
	modal: state.util.modal,
	modalExtra: state.util.modalExtra,
	alertaTipo: state.util.alertaTipo,
})

const mapDispatchToProps = (dispatch) => ({
	login: (user) => dispatch(login(user)),
	logout: () => dispatch(logout()),
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
	abreModal: (tipo, modalExtra) => abreModal(tipo, modalExtra)
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(Layout)
