import React, {Component} from "react"
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {patch} from "../../../Helpers/request"
import {login} from "../../../Actions/user"
import {setErros, setToast} from "../../../Actions/util"
import connectWithStore from "../../../Stores/connectWithStore"
import CircularProgress from "@material-ui/core/CircularProgress"
import Erro from "../../Modal/Erro"
import PropTypes from 'prop-types'
import TextFormatIcon from '@material-ui/icons/TextFormat'

class CurriculoEditor extends Component {
	_isMounted = false
	state = {
		loading: false,
		curriculo_texto: this.props.user.curriculo_texto || '',
		showEditor: false
	}

	onSubmit = (e) => {
		e && e.preventDefault()

		this.setState(() => ({
			loading: true,
			erroMensagem: undefined,
		}))
		const dados = {
			curriculo_texto: this.state.curriculo_texto,
		}
		patch('users', dados)
			.then(({data}) => {
				if (data.status) {
					const user = {...this.props.user}
					user.curriculo_texto = this.state.curriculo_texto
					user.curriculo = null
					this.props.login(user)

					if (this.props.erros) {
						const novosErros = Object.assign({}, this.props.erros)
						delete novosErros.curriculo
						this.props.setErros(novosErros)
					}

					this.props.onUpdate && this.props.onUpdate()

					this.props.setToast('Currículo salvo com sucesso', 'success')
				}
				else {
					this._isMounted && this.setState(() => ({
						erroMensagem: data.message,
					}))
				}
			})
			.catch((err) => {
				//erro controlado
				if (err.response) {
					this._isMounted && this.setState(() => ({
						erroMensagem: err.response.data.message,
					}))
				}
				//erro aleatório
				else {
					this._isMounted && this.setState(() => ({
						erroMensagem: err.response && err.response.data.message ? err.response.data.message : err.message,
					}))
				}
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})
	}

	onChange = (e) => {
		const obj = {}
		obj[e.target.name] = e.target.value
		this.setState(() => (obj))
	}

	handleShowEditor = () => {
		this.setState(prevState => ({
			showEditor: !prevState.showEditor
		}))
	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.user.curriculo_texto !== this.props.user.curriculo_texto) {
			this.setState(() => ({
				curriculo_texto: this.props.user.curriculo_texto || '',
			}))
		}
	}

	render() {
		return (
			<form onSubmit={this.onSubmit}>
				<div className="editar-curriculo">
					<h3 onClick={this.handleShowEditor}>
						<span className="icon"><TextFormatIcon /></span>
						ou caso não consiga importar seu currículo, <strong> clique aqui</strong>
					</h3>
					{this.state.showEditor || this.state.curriculo_texto 
						? ( 
							<CKEditor
								editor={ClassicEditor}
								onChange={(event, editor) => {
									this.onChange({
										target: {
											value: editor.getData(),
											name: 'curriculo_texto'
										}
									})
								}}
								config={{
									toolbar: ['bold', 'italic', 'numberedList', 'bulletedList'],
								}}
								data={this.state.curriculo_texto}
							/>
						)
						:
						("")
					}
					{this.state.erroMensagem &&
						<div className="modal-form__item">
							<Erro mensagem={this.state.erroMensagem}/>
						</div>
					}
					{this.state.showEditor || this.state.curriculo_texto 
						? ( 
							<button
								className="btnDefault btnDefault--btn1 btnDefault--loading"
								type="submit"
								disabled={this.state.loading}
							>
								{this.state.loading ?
									<>
										<CircularProgress className="circle-loading" size={20}/>
										Salvando
									</>
									: 'Salvar'}
							</button>
						)
						:
						("")
					}
					
				</div>
			</form>
		)
	}
}

CurriculoEditor.propTypes = {
	onUpdate: PropTypes.func,
}

const mapStateToProps = (state) => ({
	user: state.user,
	erros: state.util.erros,
})

const mapDispatchToProps = (dispatch) => ({
	login: (user) => dispatch(login(user)),
	setErros: (erros) => dispatch(setErros(erros)),
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(CurriculoEditor)
