import React, {Component} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faFileDownload, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons"
import {login} from "../../Actions/user"
import {abreModal} from "../../Actions/util"
import connectWithStore from "../../Stores/connectWithStore"

class BtnAddCvCompacto extends Component {
	_isMounted = false
	state = {
		loading: false,
	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		return (
			<>
				{!this.props.user.curriculo && !this.props.user.curriculo_texto
					? (
						<>
							<button
								className="btnAdd btnAdd--cv"
								onClick={() => this.props.abreModal('curriculo')}>
								<span><FontAwesomeIcon icon={faFileDownload}/></span>
								Inserir <br/> currículo
								{this.props.erros && this.props.erros.curriculo && (
									<FontAwesomeIcon icon={faExclamationTriangle}/>)}
							</button>

						</>
					)
					: (
						<button
							className="btnActions btnActions--cv"
							onClick={() => this.props.abreModal('curriculo')}
						>
							<span><FontAwesomeIcon icon={faFileDownload}/></span>
							Currículo
						</button>
					)
				}
			</>
		)
	}
}

BtnAddCvCompacto.defaultProps = {

}

BtnAddCvCompacto.propTypes = {

}

const mapStateToProps = (state) => ({
	user: state.user,
	erros: state.util.erros,
})

const mapDispatchToProps = (dispatch) => ({
	login: (user) => dispatch(login(user)),
	abreModal: (tipo, modalExtra) => dispatch(abreModal(tipo, modalExtra)),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(BtnAddCvCompacto)
