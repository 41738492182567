import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CandidatosSidebarLista from "./lista"

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    }
}));

const CandidatosSidebar = () => {
    
    const classes = useStyles();
    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className="candadidato-sidebar">
            <div className={classes.root}>
                <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <div className="label label--pendente">
                            Pendentes
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <CandidatosSidebarLista
                            tipo={"pendente"}
                            paginacao={true}
                            limit={20}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <div className="label label--aprovados">
                            Aprovados
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <CandidatosSidebarLista
                            tipo={"aprovado"}
                            paginacao={true}
                            limit={20}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <div className="label label--reprovados">
                            Reprovados
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <CandidatosSidebarLista
                            tipo={"reprovado"}
                            paginacao={true}
                            limit={20}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        </div>
    )
}

export default CandidatosSidebar