import React from 'react'

import Logo from './logo'
import InfoRecrutador from './infoRecrutador'
import LinksMenu from './links'
import Logout from './logout'
import connectWithStore from "../../../Stores/connectWithStore"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faArrowRight } from "@fortawesome/free-solid-svg-icons"

const SidebarMenu = ({ handleClick, menuOpen, user }) => {
    return (
        <aside
            className={ menuOpen ? "sidebarNav" 
                        : "sidebarNav sidebarNav--shrink" }>
            <button
                type="button"
                className="btn-shrink-sidebar" 
                onClick={handleClick}
            >
                {menuOpen ? <FontAwesomeIcon icon={faBars} /> : <FontAwesomeIcon icon={faArrowRight} /> }
            </button>

            { menuOpen && <Logo /> }

            <InfoRecrutador
                name={user.first_name}
                cargo="Recrutador"
                avatarShrink={ menuOpen ? "avatar" : "avatar avatar--shrink" }
                escondeTxt={ menuOpen ? "" : "d-none" }
            />

            <LinksMenu
                linkShrink={ menuOpen ? "sidebarNav__links" : "sidebarNav__links sidebarNav__links--shrink" }
                marginSVG={ menuOpen ? "" : "svgMargin" }
            />
            
            <Logout
                linkShrink={ menuOpen ? "sidebarNav__links" : "sidebarNav__links sidebarNav__links--shrink" }
                marginSVG={ menuOpen ? "" : "svgMargin" }
            />
        </aside>
    )
}

const mapStateToProp = (state) => ({
    user: state.user
})

export default  connectWithStore(mapStateToProp, null)(SidebarMenu)