import React, {Component} from 'react'
import TituloCandidato from '../../Components/Candidato/Titulo'
import InfosIniciaisVaga from '../../Components/Site/InfosIniciasVaga'
import DescricaoVaga from '../../Components/Site/DescricaoVaga'
import DadosPessoais from '../../Components/Candidato/DadosPessoais'
import PerguntaLista from '../../Components/Candidato/Candidatar/PerguntaLista'
import {get, post} from "../../Helpers/request"
import Loading from "../../Components/Loading"
import Erro from "../../Components/ErrorMessage"
import LayoutLogado from "../../Components/Site/Layout/logado"
import {alertaErro} from "../../Actions/util"
import connectWithStore from "../../Stores/connectWithStore"
import PageMeta from '../../Components/PageMeta'

class CandidatarVaga extends Component {
	_isMounted = false
	state = {
		vaga: undefined,
		loadingVaga: true,
		errorVaga: undefined,
		redirect: undefined,
		visible: false,
		candidatura: undefined,
	}

	getVaga = (e) => {
		e && e.preventDefault()

		this.setState(() => ({
			errorVaga: false,
			loadingVaga: true,
		}))

		Promise.all([
			get(`vaga/${this.props.match.params.id}`)
				.then(({data}) => {
					this._isMounted && this.setState(() => ({
						vaga: data.vaga
					}))
				})
				.catch((err) => {
					if (err.response && err.response.status === 404) {
						this.props.alertaErro(err.response.data.message)
						this._isMounted && this.setState(() => ({
							redirect: '/'
						}))
					}
					else {
						this._isMounted && this.setState(() => ({
							errorVaga: err.response && err.response.data.message ? err.response.data.message : err.message
						}))
					}
				})
			,
			get(`vaga/${this.props.match.params.id}/candidatura`)
				.then(({data}) => {
					if (data.candidatura.status === 'incompleto') {
						this._isMounted && this.setState(() => ({
							candidatura: data.candidatura,
						}))
					}
					else {
						data.candidatura.respostas = data.candidatura.respostas.filter((v) => v.permite_regravar === 'sim')
						if (data.candidatura.respostas.length > 0) {
							this.props.history.replace(`/vagas/${this.props.match.params.id}/regravar`)

						}
						else {
							this.props.alertaErro('Você já se candidatou a esta vaga')
							this.props.history.replace(`/vagas/${this.props.match.params.id}`)
						}
					}
				})
				.catch((err) => {
					//nada
				}),
		])
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loadingVaga: false,
				}))
			})
	}

	componentDidMount() {
		this._isMounted = true
		this.getVaga()
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	handleShowDescription = e => {
		this.setState((prevState) => ({
			visible: !prevState.visible
		}))
	}

	render() {

		return (
			this.state.loadingVaga
				? (<Loading/>)
				: (
					this.state.errorVaga
						? (
							<Erro mensagem={this.state.errorVaga} tryAgainHandler={this.getVaga}/>
						)
						: (
							<LayoutLogado redirectTo={`/vagas/${this.state.vaga.id}`}>

								<PageMeta
									title="Candidatar a vaga"
									description="Description tela candidatar vaga"
									keywords="Vaga, Momentum, Recrutamento"
								/>

								<TituloCandidato titulo="Você está se candidatando"/>

								<div className="container">
									<InfosIniciaisVaga vaga={this.state.vaga} pagina="candidatar"/>

									{this.state.visible && <DescricaoVaga vaga={this.state.vaga}/>}
									<button
										onClick={this.handleShowDescription}
										className="btnDescricaoCompleta btnDefault btnDefault--btn1">
										{!this.state.visible ? 'Ver descrição completa' : 'Recolher descrição'}
									</button>

									<div className="line"/>

									<DadosPessoais titulo="Meus dados"/>

									<PerguntaLista vaga={this.state.vaga} metodo={post} candidatura={this.state.candidatura} />
								</div>
							</LayoutLogado>
						)
				)
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	alertaErro: (alertaTitulo) => dispatch(alertaErro(alertaTitulo)),
})

export default connectWithStore(null, mapDispatchToProps)(CandidatarVaga)