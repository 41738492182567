import React, {Component} from "react"
import Layout from '../../Components/Recrutador/Layout'
import Title from '../../Components/Recrutador/Title'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons"
import {
	Grid,
	RadioGroup,
	FormControlLabel,
	Radio
} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import {blue} from '@material-ui/core/colors'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import dicAuxiliar from "../../Data/dicionarioVaga.json"
import {get, post, put} from "../../Helpers/request"
import Loading from "../../Components/Loading"
import Erro from "../../Components/Modal/Erro"
import CircularProgress from "@material-ui/core/CircularProgress"
import {setToast} from "../../Actions/util"
import {Redirect} from "react-router-dom"
import connectWithStore from "../../Stores/connectWithStore"
import PageMeta from '../../Components/PageMeta'

/* Radio button config */
const BlueRadio = withStyles({
	root: {
		color: blue[400],
		'&$checked': {
			color: blue[600],
		},
	},
	checked: {},
})((props) => <Radio color="default" {...props} />)

class FormVaga extends Component {
	_isMounted = false
	state = {
		vaga: {
			titulo: "",
			periodo_trabalho: "",
			estado: "",
			cidade: "",
			local_trabalho: "",
			tipo_contratacao: "",
			salario: "",
			nivel_experiencia: "",
			descricao: "",
			pergunta: [],
		},

		loading: false,
		erroMensagem: undefined,
		erroCampos: [],

		loadingVaga: false,
		errorVaga: undefined,

		redirect: undefined,
	}

	onChange = (e) => {
		const value = e.target.value
		const name = e.target.name
		this.setState((prevState) => {
			prevState.vaga[name] = value
			return {vaga: prevState.vaga}
		})
	}

	handleChangePergunta = (i, event) => {
		const value = event.target.value
		this.setState((prevState) => {
			prevState.vaga.pergunta[i].pergunta = value
			return {
				vaga: prevState.vaga
			}
		})
	}

	handleAddPergunta = () => {
		this.setState((prevState) => {
			prevState.vaga.pergunta.push({pergunta: ""})
			return {
				vaga: prevState.vaga
			}
		})
	}

	handleRemovePergunta = (i) => {
		this.setState((prevState) => {
			prevState.vaga.pergunta = prevState.vaga.pergunta.filter((v, k) => k !== i)
			return {
				vaga: prevState.vaga
			}
		})
	}

	onSubmit = (e) => {
		e.preventDefault()
		this.setState(() => ({
			loading: true,
			erroMensagem: undefined,
			erroCampos: []
		}))
		const dados = this.state.vaga
		const metodo = this.props.match.params.id ? put : post
		metodo(`recrutador/vaga${this.props.match.params.id ? `/${this.props.match.params.id}` : ''}`, dados)
			.then(({data}) => {
				if (data.status) {
					this.props.setToast(data.message, 'success')

					this._isMounted && this.props.history.goBack()
				}
				else {
					this._isMounted && this.setState(() => ({
						erroMensagem: data.erros ? undefined : data.message,
						erroCampos: data.erros || {}
					}))
				}
			})
			.catch((err) => {
				//erro controlado
				if (err.response) {
					this._isMounted && this.setState(() => ({
						erroMensagem: err.response.data.erros ? undefined : err.response.data.message,
						erroCampos: err.response.data.erros || {}
					}))
				}
				//erro aleatório
				else {
					this._isMounted && this.setState(() => ({
						erroMensagem: err.response && err.response.data.message ? err.response.data.message : err.message,
					}))
				}
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})
	}

	getVaga = (e) => {
		e && e.preventDefault()

		this.setState(() => ({
			errorVaga: false,
			loadingVaga: true,
		}))

		get(`recrutador/vaga/${this.props.match.params.id}`)
			.then(({data}) => {
				if (data.vaga.status === 'archived') {
					this.props.setToast('Não é possível editar uma vaga que já foi arquivada', 'error')
					this._isMounted && this.setState(() => ({
						redirect: '/recrutador/vagas'
					}))
				}
				else {
					this._isMounted && this.setState(() => ({
						vaga: {
							titulo: data.vaga.titulo,
							periodo_trabalho: data.vaga.periodo_trabalho,
							estado: data.vaga.estado,
							cidade: data.vaga.cidade,
							local_trabalho: data.vaga.local_trabalho,
							tipo_contratacao: data.vaga.tipo_contratacao,
							salario: data.vaga.salario,
							nivel_experiencia: data.vaga.nivel_experiencia,
							descricao: data.vaga.descricao,
							pergunta: data.vaga.pergunta,
						}
					}))
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 404) {
					this.props.setToast(err.response.data.message, 'error')
					this._isMounted && this.setState(() => ({
						redirect: '/recrutador/vagas'
					}))
				}
				else {
					this._isMounted && this.setState(() => ({
						errorVaga: err.response && err.response.data.message ? err.response.data.message : err.message
					}))
				}
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loadingVaga: false,
				}))
			})
	}

	componentDidMount() {
		this._isMounted = true
		if (this.props.match.params.id) {
			this.getVaga()
		}
		else {
			this.handleAddPergunta()
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.state.vaga.pergunta.length === 0) {
			this.handleAddPergunta()
		}
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.setState(() => ({
				redirect: undefined
			}))
			this.getVaga()
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		if (this.state.redirect) {
			return (
				<Redirect to={{
					pathname:this.state.redirect,
					state: { redirect: undefined }
				}} />
			)
		}

		return (
			<Layout>
				<PageMeta
					title="Criar vaga"
					description="description da tela criar vaga"
					keywords="Vaga, Momentum, Recrutamento"
				/>
				<Title title={this.props.match.params.id ? 'Editando vaga' : 'Criar uma nova vaga'}/>
				{this.state.loadingVaga
					? (<Loading/>)
					: (
						this.state.errorVaga
							? (
								<Erro mensagem={this.state.errorVaga} tryAgainHandler={this.getVaga}/>
							)
							: (
								<section className="criar-vaga-wrap">
									<form className="criar-vaga-wrap__form" onSubmit={this.onSubmit}>
										<Grid container spacing={3}>
											<Grid item md={6} xs={12}>
												<div className="criar-vaga-wrap__item-form">
													<label htmlFor="titulo_vaga" className="labelDefault">
														Título da vaga*
													</label>
													<input
														type="text"
														className="inputDefault"
														name="titulo"
														id="titulo_vaga"
														placeholder="Digite um título para a vaga"
														maxLength={100}
														value={this.state.vaga.titulo}
														onChange={this.onChange}
													/>
													{this.state.erroCampos.titulo &&
													<Erro mensagem={this.state.erroCampos.titulo}/>}
												</div>
											</Grid>
											<Grid item md={6} xs={12}>
												<div className="criar-vaga-wrap__item-form">
													<label htmlFor="periodo_trabalho" className="labelDefault">
														Período do trabalho*
													</label>
													<div className="select-list">
														<select
															name="periodo_trabalho"
															id="periodo_trabalho"
															className="inputDefault"
															value={this.state.vaga.periodo_trabalho}
															onChange={this.onChange}
														>
															<option value="">Selecione uma opção</option>
															{dicAuxiliar.periodo_trabalho.map((v) => (
																<option value={v.value} key={v.value}>{v.label}</option>
															))}
														</select>
													</div>
													{this.state.erroCampos.periodo_trabalho &&
													<Erro mensagem={this.state.erroCampos.periodo_trabalho}/>}
												</div>
											</Grid>
											<Grid item md={1} xs={3}>
												<div className="criar-vaga-wrap__item-form">
													<label htmlFor="estado" className="labelDefault">
														Estado*
													</label>
													<div className="select-list">
														<select
															name="estado"
															id="estado"
															className="inputDefault"
															value={this.state.vaga.estado}
															onChange={this.onChange}
														>
															<option value="">UF</option>
															{dicAuxiliar.estado.map((v) => (
																<option value={v.value} key={v.value}>{v.value}</option>
															))}
														</select>
													</div>
													{this.state.erroCampos.estado &&
													<Erro mensagem={this.state.erroCampos.estado}/>}
												</div>
											</Grid>
											<Grid item md={5} xs={9}>
												<div className="criar-vaga-wrap__item-form">
													<label htmlFor="cidade" className="labelDefault">
														Cidade*
													</label>
													<input
														type="text"
														className="inputDefault"
														name="cidade"
														id="cidade"
														placeholder="Digite a cidade"
														maxLength={100}
														value={this.state.vaga.cidade}
														onChange={this.onChange}
													/>
													{this.state.erroCampos.cidade &&
													<Erro mensagem={this.state.erroCampos.cidade}/>}
												</div>
											</Grid>
											<Grid item md={6} xs={12}>
												<div className="criar-vaga-wrap__item-form">
													<label htmlFor="local_trabalho" className="labelDefault">
														Local de trabalho*
													</label>
													<input
														type="text"
														className="inputDefault"
														name="local_trabalho"
														id="local_trabalho"
														placeholder="Digite o local de trabalho"
														maxLength={100}
														value={this.state.vaga.local_trabalho}
														onChange={this.onChange}
													/>
													{this.state.erroCampos.local_trabalho &&
													<Erro mensagem={this.state.erroCampos.local_trabalho}/>}
												</div>
											</Grid>
											<Grid item md={12} xs={12}>
												<div className="criar-vaga-wrap__item-form">
													<label htmlFor="tipo_contratacao" className="labelDefault">
														Qual tipo de contratação?
													</label>
													<RadioGroup
														aria-label="contratacao"
														className="radioGroupvaga"
														name="tipo_contratacao"
														value={this.state.vaga.tipo_contratacao}
														onChange={this.onChange}
													>
														{dicAuxiliar.tipo_contratacao.map((v) => (
															<FormControlLabel
																value={v.value}
																key={v.value}
																label={v.label}
																control={<BlueRadio/>}
															/>
														))}
													</RadioGroup>
													{this.state.erroCampos.tipo_contratacao &&
													<Erro mensagem={this.state.erroCampos.tipo_contratacao}/>}
												</div>
											</Grid>
											<Grid item md={6} xs={12}>
												<div className="criar-vaga-wrap__item-form">
													<label htmlFor="salario" className="labelDefault">
														Qual o salário?*
													</label>
													<input
														type="text"
														className="inputDefault"
														name="salario"
														id="salario"
														placeholder="Digite o valor do salário"
														maxLength={100}
														value={this.state.vaga.salario}
														onChange={this.onChange}
													/>
													{this.state.erroCampos.salario &&
													<Erro mensagem={this.state.erroCampos.salario}/>}
												</div>
											</Grid>
											<Grid item md={6} xs={12}>
												<div className="criar-vaga-wrap__item-form">
													<label htmlFor="experiencia" className="labelDefault">
														Nível de experiência*
													</label>
													<div className="select-list">
														<select
															name="nivel_experiencia"
															id="experiencia"
															className="inputDefault"
															value={this.state.vaga.nivel_experiencia}
															onChange={this.onChange}
														>
															<option value="">Selecione uma opção</option>
															{dicAuxiliar.nivel_experiencia.map((v) => (
																<option value={v.value} key={v.value}>{v.label}</option>
															))}
														</select>
													</div>
													{this.state.erroCampos.nivel_experiencia &&
													<Erro mensagem={this.state.erroCampos.nivel_experiencia}/>}
												</div>
											</Grid>
											<Grid item xs={12}>
												<div className="criar-vaga-wrap__item-form">
													<label htmlFor="descricao" className="labelDefault">
														Descrição da vaga*
													</label>
													<CKEditor
														editor={ClassicEditor}
														onChange={(event, editor) => {
															this.onChange({
																target: {
																	value: editor.getData(),
																	name: 'descricao'
																}
															})
														}}
														config={{
															toolbar: ['bold', 'italic', 'numberedList', 'bulletedList'],
														}}
														data={this.state.vaga.descricao}
													/>
													{this.state.erroCampos.descricao &&
													<Erro mensagem={this.state.erroCampos.descricao}/>}
												</div>
											</Grid>
											<Grid item xs={12}>
												<div className="criar-vaga-wrap__item-form">
													<label htmlFor="pergunta" className="labelDefault">
														Questionário para entrevista*
													</label>
													{this.state.vaga.pergunta.map((v, k) => {
														return (
															<div key={`${v}-${k}`}
															     className="criar-vaga-wrap__box-pergunta">
																<label htmlFor={`pergunta${k + 1}`}>Pergunta {k + 1}</label>
																{v.id && (
																	<input
																		type="hidden"
																		value={v.id}
																	/>
																)}
																<input
																	type="text"
																	id={`pergunta${k + 1}`}
																	className="inputDefault inputDefault--pergunta-vaga"
																	placeholder="Digite sua pergunta"
																	value={v.pergunta}
																	onChange={e => this.handleChangePergunta(k, e)}
																/>
																<button className="btn-deletar-pergunta" type="button"
																        onClick={() => this.handleRemovePergunta(k)}>
																	<FontAwesomeIcon icon={faTrashAlt}/>
																</button>
															</div>
														)
													})}
													{this.state.erroCampos.pergunta &&
													<Erro mensagem={this.state.erroCampos.pergunta}/>}
													<button
														type="button"
														onClick={() => this.handleAddPergunta()}
														className="btnDefault btnDefault--btn4">
														Adicionar pergunta
													</button>
												</div>
											</Grid>
											<Grid item xs={12}>
												{this.state.erroMensagem &&
												<div className="criar-vaga-wrap__item-form">
													<Erro mensagem={this.state.erroMensagem}/>
												</div>
												}
												<div
													className="criar-vaga-wrap__item-form criar-vaga-wrap__item-form--buttons">
													<button
														type="submit"
														className="btnDefault btnDefault--btn4 btnDefault--loading"
														disabled={this.state.loading}
													>
														{this.state.loading ?
															<>
																<CircularProgress className="circle-loading" size={20} />
																{this.props.match.params.id? 'Atualizando': 'Cadastrando'}
															</>
															: (this.props.match.params.id? 'Atualizar': 'Cadastrar')}
													</button>
													<button
														type="button"
														className="btnDefault btnDefault--btn1"
														onClick={() => this.props.history.goBack()}
													>Cancelar
													</button>
												</div>
											</Grid>
										</Grid>
									</form>
								</section>
							)
				)
				}

			</Layout>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
})

export default connectWithStore(null, mapDispatchToProps)(FormVaga)
