import avatarBg from '../img/avatar.png'
import dicAuxiliarCandidato from '../Data/dicionarioCandidato.json'
import dicAuxiliarVaga from '../Data/dicionarioVaga.json'
import Hashids from 'hashids'

export const maskTelefone = (val) => {
	const mask = val.replace(/\D/g, '').length > 10 ?
		//'(00) 0 0000-0000' : '(00) 0000-00009'
		['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/,/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]:
		['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
	return mask
}

export const maskData = (val) => {
	return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
}

export const urlFotoPerfil = (foto, width=undefined, height=undefined, mode=undefined) => {
	if (foto) {
		let url = `${process.env.REACT_APP_API_URL}files/`
		if (width || height) {
			url += `thumb/${foto.id}/${width ? width : 'auto'}/${height ? height : 'auto'}`
			if (mode) {
				url += `/${mode}`
			}
		}
		else {
			url += `large/${foto.id}`
		}
		return url
	}
	return avatarBg
}

export const urlArquivo = (arquivo, acao='view') => {
	if (arquivo) {
		return `${process.env.REACT_APP_API_URL}files/${acao}/${arquivo.id}`
	}
	return null
}

export const labelDicAuxiliarCandidato = (campo, valor) => {
	const item = dicAuxiliarCandidato[campo].find((v) => v.value === valor)
	return item? item.label: ''
}

export const labelDicAuxiliarVaga = (campo, valor) => {
	const item = dicAuxiliarVaga[campo].find((v) => v.value === valor)
	return item? item.label: ''
}

export const whatsappUrl = (numero) => {
	let url = "https://wa.me/"
	numero = numero.replace(/[^0-9]/g, '')
	//celular ou telefone no formato internacional
	if (numero.length === 12 || numero.length === 13) {
		return `${url}${numero}`
	}
	//celular ou telefone sem código de pais
	if (numero.length === 11 || numero.length === 10) {
		return `${url}55${numero}`
	}
	//não vai funcionar, deixa como tel: mesmo
	return `tel:${numero}`
}

const HASHIDS_SALT = `Momentum PEM - ${process.env.REACT_APP_API_KEY}`
export const hashidEncode = (...ids) => {
	const hashids = new Hashids(HASHIDS_SALT, 6)
	return hashids.encode(ids)
}

export const hashidDecode = (hash) => {
	const hashids = new Hashids(HASHIDS_SALT)
	return hashids.decode(hash)
}

export const candidatoShareUrl = (idVaga, idVagaCandidato) => {
	return `${window.location.origin}/recrutador/share/${hashidEncode(idVaga, idVagaCandidato)}`
}

export const isDev = () => {
	return process.env.NODE_ENV && process.env.NODE_ENV === 'development'
}
