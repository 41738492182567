import React, {Component} from 'react'
import PropTypes from "prop-types"
import Pergunta from './Pergunta'
import {patch, post} from "../../../Helpers/request"
import {NavLink, Prompt} from "react-router-dom"
import {login, logout} from "../../../Actions/user"
import {alertaErro, alertaSucesso, setErros, setToast, unsetErros} from "../../../Actions/util"
import connectWithStore from "../../../Stores/connectWithStore"
import CircularProgress from "@material-ui/core/CircularProgress"
import {Beforeunload} from "react-beforeunload"

class PerguntasLista extends Component {
	_isMounted = false
	state = {
		loading: false,
		erroMensagem: undefined,
		resposta: [],
		podeSair: false,
	}

	componentDidMount() {
		this._isMounted = true
		if (this.props.candidatura) {
			this.setState(() => ({
				resposta: this.props.candidatura.respostas.map((v) => ({
					id_vaga_questionario: v.id_vaga_questionario,
					resposta: v.resposta.id
				}))
			}))
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	onSubmit = (e) => {
		e && e.preventDefault()

		this.setState(() => ({
			loading: true,
			erroMensagem: undefined,
		}))
		this.props.unsetErros()
		const dados = {
			resposta: this.state.resposta
		}

		this.props.metodo(`vaga/${this.props.vaga.id}/candidatura`, dados)
			.then(({data}) => {
				if (data.status) {
					this.props.alertaSucesso(data.message)
					this._isMounted && this.setState(() => ({
						podeSair: true,
					}), () => {
						this.props.history.push(`/vagas/${this.props.vaga.id}`)
					})
				}
				else {
					this._isMounted && this.props.alertaErro("", data.message)
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					this.props.setToast('Sua sessão expirou', 'error')
					this.props.logout()
					return
				}
				//erro controlado
				if (err.response) {
					this._isMounted && this.props.alertaErro("", err.response.data.message)
					if (err.response.data.erros) {
						this.props.setErros(err.response.data.erros)
					}
				}
				//erro aleatório
				else {
					this._isMounted && this.props.alertaErro(err.response && err.response.data.message ? err.response.data.message : err.message)
				}
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})
	}

	onResposta = (k, arquivo) => {
		this.setState((prevState) => {
			const resposta = [...prevState.resposta]
			resposta[k] = {
				id_vaga_questionario: this.props.vaga.pergunta[k].id,
				resposta: arquivo
			}
			return {
				resposta
			}
		}, () => {
			if (this.props.metodo === patch && this.state.resposta.length === this.props.vaga.pergunta.length) {
				this._isMounted && this.setState(() => ({
					podeSair: true,
				}), () => {
					this.props.alertaSucesso('Reposta reenviada com sucesso')
					this.props.history.push(`/vagas/${this.props.vaga.id}`)
				})
			}
		})
	}

	render() {
		return (
			<section className="questionario-wrap">
				<h1>Questionário de perguntas</h1>
				<h2>
					Todas as perguntas devem ser respondidas através de vídeo com até 2 minutos de duração.
				</h2>
				{this.state.resposta.length > 0 && !this.state.podeSair && (
					<>
						<Prompt when={!this.state.podeSair}
						        message={"Você ainda não concluiu a sua candidatura. Tem certeza que deseja sair?"}/>
						<Beforeunload onBeforeunload={(e) => "Tem certeza?"}/>
					</>
				)}
				{this.props.vaga.pergunta.map((v, k) => (
					<Pergunta
						pergunta={v}
						numero={k}
						status={this.state.resposta[k] ? "respondida" : (k > 0 && !this.state.resposta[k - 1] ? "pendente" : "ativa")}
						key={k}
						onResposta={this.onResposta}
						metodo={this.props.metodo}
					/>
				))}

				{this.props.metodo === post && (
					<div className="questionario-wrap__action-buttons">
						<form onSubmit={this.onSubmit}>
							<button
								type="submit"
								disabled={!this.state.resposta[this.props.vaga.pergunta.length - 1] || this.state.loading}
								className="btnDefault btnDefault--btn4 btnDefault--loading"
							>
								{this.state.loading ?
									<>
										<CircularProgress className="circle-loading" size={20}/>
										Enviando candidatura
									</>
									: "Enviar candidatura"
								}
							</button>
							<NavLink
								to={`/vagas/${this.props.vaga.id}`}
								className="btnDefault btnDefault--btn1">
								Cancelar
							</NavLink>
						</form>
					</div>
				)}
			</section>
		)
	}
}

PerguntasLista.propTypes = {
	vaga: PropTypes.object.isRequired,
	metodo: PropTypes.oneOf([post, patch]),
	candidatura: PropTypes.object,
}

const mapStateToProps = (state) => ({
	user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
	login: (user) => dispatch(login(user)),
	alertaErro: (alertaTitulo, alertaMensagem) => dispatch(alertaErro(alertaTitulo, alertaMensagem)),
	alertaSucesso: (alertaTitulo, alertaMensagem) => dispatch(alertaSucesso(alertaTitulo, alertaMensagem)),
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
	logout: () => dispatch(logout()),
	setErros: (erros) => dispatch(setErros(erros)),
	unsetErros: () => dispatch(unsetErros()),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(PerguntasLista)
