import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWhatsappSquare } from "@fortawesome/free-brands-svg-icons"
import PropTypes from 'prop-types'
import {whatsappUrl} from "../../Helpers/util"
import Tooltip from '@material-ui/core/Tooltip'

const BtnWhatsapp = props => {
    return (
        <Tooltip title={props.page === "dashboard" ? "" : "Editar Whatsapp"} arrow placement="bottom">
            <a className="btnActions btnActions--whatsapp" href={whatsappUrl(props.numero)}>
                <span><FontAwesomeIcon icon={faWhatsappSquare} /></span>
                Whatsapp
            </a>
        </Tooltip>
    )
}

BtnWhatsapp.propTypes = ({
    numero: PropTypes.string.isRequired
})

export default BtnWhatsapp