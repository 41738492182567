import React, {Component} from 'react'
import BtnAddCv from '../../Buttons/addCv'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons"
import connectWithStore from "../../../Stores/connectWithStore"
import Tooltip from '@material-ui/core/Tooltip'
import CurriculoEditor from './editor'
import PropTypes from 'prop-types'

class Curriculo extends Component {
	_isMounted = false

	state = {
		editando: !this.props.user.curriculo
	}

	toggleEditando = (e) => {
		e && e.preventDefault()
		this._isMounted && this.setState((prevProps) => ({
			editando: !prevProps.editando
		}))
	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		return (
			<>
				<h2 className="tituloSite2">
					{this.props.titulo}
				</h2>
				<section className="box-edicao-perfil-candidato box-edicao-perfil-candidato--curriculo">
					{this.props.user.curriculo && (
						<Tooltip title="Editar Currículo" arrow placement="bottom">
							<button className="btn-editar-cv" onClick={this.toggleEditando}>
								<FontAwesomeIcon icon={faPencilAlt}/>
							</button>
						</Tooltip>
					)}
					<BtnAddCv compacto={false} forceInput={this.state.editando} onUpdate={this.props.onUpdate? this.props.onUpdate: this.toggleEditando} />

					<CurriculoEditor onUpdate={this.props.onUpdate} />
				</section>
				
			</>
		)
	}
}

Curriculo.propTypes = {
	onUpdate: PropTypes.func,
}

const mapStateToProps = (state) => ({
	user: state.user
})

export default connectWithStore(mapStateToProps, null)(Curriculo)
