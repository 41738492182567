import React, {Component} from 'react'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faThumbsUp, faThumbsDown} from "@fortawesome/free-solid-svg-icons"
import PropTypes from 'prop-types'
import connectWithStore from "../../Stores/connectWithStore"
import {setToast} from "../../Actions/util"
import {patch} from "../../Helpers/request"
import Loading from "../Loading"
import Erro from "../ErrorMessage"
import Tooltip from '@material-ui/core/Tooltip'
import {setVagaCandidato} from "../../Actions/vagaCandidato"
import {logout} from "../../Actions/user"

class BtnAvaliacao extends Component {
	_isMounted = false
	state = {
		loading: false,
		error: undefined,
		avaliacao: undefined,
	}

	setAvaliacao = (avaliacao) => {
		this.setState(() => ({
			avaliacao
		}), this.enviaAvaliacao)
	}

	enviaAvaliacao = () => {
		this.setState(() => ({
			error: undefined,
			loading: true,
		}))

		patch(`recrutador/vaga/${this.props.vagaCandidato.vaga.id}/candidato/${this.props.vagaCandidato.id}/${this.state.avaliacao}`)
			.then(() => {
				this.props.setToast(`Candidato ${this.state.avaliacao} com sucesso`, 'success')
				const vagaCandidato = {...this.props.vagaCandidato}
				vagaCandidato.status = this.state.avaliacao
				this._isMounted && this.props.setVagaCandidato(vagaCandidato)

				//this.props.history.goBack()
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					this.props.setToast('Sua sessão expirou', 'error')
					this.props.logout()
					return
				}

				this._isMounted && this.setState(() => ({
					error: err.response && err.response.data.message ? err.response.data.message : err.message
				}))
			})
			.finally(() => {
				//this.props.setLoading(false)
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})

	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		return (
			this.state.loading
				? (<Loading/>)
				: (
					this.state.error
						? (<Erro mensagem={this.state.error} tryAgainHandler={this.enviaAvaliacao}/>)
						: (
							<>
								<h3 className="wrap-info-candidado__titulo-avaliacao">
									{this.props.titulo}
								</h3>
								<div className="wrap-info-candidado__botoes-avaliacao-wrap">
									<Tooltip title="Aprovar candidato" arrow placement="bottom">
										<button
											className={`wrap-info-candidado__botao-avaliacao wrap-info-candidado__botao-avaliacao--up${this.props.vagaCandidato.status === 'aprovado'? ' active': ''}`}
											onClick={() => this.setAvaliacao('aprovado')}
										>
											<FontAwesomeIcon icon={faThumbsUp}/>
										</button>
									</Tooltip>
									<Tooltip title="Reprovar candidato" arrow placement="bottom">
										<button
											className={`wrap-info-candidado__botao-avaliacao wrap-info-candidado__botao-avaliacao--down${this.props.vagaCandidato.status === 'reprovado'? ' active': ''}`}
											onClick={() => this.setAvaliacao('reprovado')}
										>
											<FontAwesomeIcon icon={faThumbsDown}/>
										</button>
									</Tooltip>
								</div>
							</>
						)
				)
		)
	}
}

BtnAvaliacao.propTypes = ({
	titulo: PropTypes.string.isRequired,
})

const mapStateToProps = (state) => ({
	vagaCandidato: state.vagaCandidato
})

const mapDispatchToProps = (dispatch) => ({
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
	setVagaCandidato: (vagaCandidato) => dispatch(setVagaCandidato(vagaCandidato)),
	logout: () => dispatch(logout()),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(BtnAvaliacao)
