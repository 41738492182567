import React, {Component} from 'react'
import queryString from "query-string"
import {withRouter} from "react-router"

class MenuTopo extends Component {
    state = {
        q: ''
    }

    onSubmit = (e) => {
        e.preventDefault()
        let qs = ''
        if (this.state.q.length > 0) {
            qs = `?${queryString.stringify({q: this.state.q})}`
        }
        this.props.history.push(`/recrutador/busca${qs}`)
    }

    onChange = (e) => {
        const obj = {}
        obj[e.target.name] = e.target.value
        this.setState(() => (obj))
    }

    componentDidMount() {
        const qs = queryString.parse(this.props.location.search)
        this.setState(() => ({
           q: qs.q || ""
        }))
    }

    render() {
        return (
            <section className="menuTopo">
                <div className="menuTopo__item menuTopo__item--formPesquisa">
                    <form onSubmit={this.onSubmit}>
                        <input
                            type="text"
                            name="q"
                            id="pequisar"
                            placeholder="Pesquisar"
                            className="inputDefault inputDefault--inputPesquisa"
                            value={this.state.q}
                            onChange={this.onChange}
                        />
                    </form>
                </div>
                {/* <div className="menuTopo__item menuTopo__item--navBar">
                <nav>
                    <a
                        className="menuTopo__links"
                        href="#"
                        title="Notificações"
                    >
                        Notificações <span>4</span>
                    </a>
                    <a
                        className="menuTopo__links"
                        href="#"
                        title="Meu Perfil"
                    >
                        Meu perfil
                    </a>
                    <a
                        className="menuTopo__links"
                        href="#"
                        title="Configurações"
                    >
                        Configurações
                    </a>
                </nav>
            </div> */}
            </section>
        )
    }
}

export default withRouter(MenuTopo)
