import React from 'react'
import { NavLink } from 'react-router-dom'

import logoDashboard from '../../../img/logoDashboard.png'

const Logo = () => (
    <div className="sidebarNav__item sidebarNav__item--logoWrapper">
        <NavLink to="/recrutador/" exact>
            <img src={logoDashboard} alt="PEM Dashboard" />
        </NavLink>
    </div>
)

export default Logo