import React, {Component} from 'react'
import Layout from '../../Components/Recrutador/Layout'
import Title from '../../Components/Recrutador/Title'
import InfoBioCandidato from '../../Components/Recrutador/InfoBioCandidato'
import VideosCandidato from '../../Components/Recrutador/ListaPerguntasCandidato'
import CandidatoSidebar from '../../Components/Recrutador/CandidatosSidebar'
import {get} from "../../Helpers/request"
import Loading from "../../Components/Loading"
import Erro from "../../Components/ErrorMessage"
import {setVagaCandidato, unsetVagaCandidato} from "../../Actions/vagaCandidato"
import connectWithStore from "../../Stores/connectWithStore"
import {setToast} from "../../Actions/util"
import {logout} from "../../Actions/user"
import PageMeta from '../../Components/PageMeta'
import Grid from '@material-ui/core/Grid'
import Avaliacao from '../../Components/Recrutador/Avaliacao'

class Candidato extends Component {
	_isMounted = false
	state = {
		loadingCandidato: true,
		errorCandidato: undefined,
	}

	getCandidato = (e) => {
		e && e.preventDefault()

		this.setState(() => ({
			errorCandidato: false,
			loadingCandidato: true,
		}))

		get(`recrutador/vaga/${this.props.match.params.idVaga}/candidato/${this.props.match.params.idVagaCandidato}`)
			.then(({data}) => {
				this._isMounted && this.props.setVagaCandidato(data.candidato)
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					this.props.setToast('Sua sessão expirou', 'error')
					this.props.logout()
					return
				}
				if (err.response && err.response.status === 404) {
					if (this._isMounted) {
						this.props.setToast(err.response.data.message, 'error')
						this.props.history.goBack()
					}
				}
				else {
					this._isMounted && this.setState(() => ({
						errorCandidato: err.response && err.response.data.message ? err.response.data.message : err.message
					}))
				}
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loadingCandidato: false,
				}))
			})
	}

	componentDidMount() {
		this._isMounted = true
		this.getCandidato()
	}

	componentWillUnmount() {
		this._isMounted = false
		this.props.unsetVagaCandidato()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.idVaga !== this.props.match.params.idVaga || prevProps.match.params.idVagaCandidato !== this.props.match.params.idVagaCandidato) {
			this.props.unsetVagaCandidato()
			this.getCandidato()
		}
	}

	render() {
		return (
			<Layout>
				<PageMeta
					title="Candidato"
					description="description da tela de vagas"
					keywords="Vaga, Momentum, Recrutamento"
				/>

				<Title title='Candidatos'/>

				<Grid container spacing={2}>
					<Grid item xs={12} sm={9} lg={8}>
						{this.state.loadingCandidato
							? (<Loading/>)
							: (
								this.state.errorCandidato
									? (
										<Erro mensagem={this.state.errorCandidato} tryAgainHandler={this.getCandidato}/>
									)
									: (
										<>
											<InfoBioCandidato/>

											<VideosCandidato/>

											<Avaliacao />
										</>
									)
							)
						}
					</Grid>
					<Grid item xs={12} sm={3} lg={4}>
						{this.props.vagaCandidato && (
							<CandidatoSidebar/>
						)}
					</Grid>
				</Grid>
			</Layout>
		)
	}
}

const mapStateToProps = (state) => ({
	vagaCandidato: state.vagaCandidato
})

const mapDispatchToProps = (dispatch) => ({
	setVagaCandidato: (vagaCandidato) => dispatch(setVagaCandidato(vagaCandidato)),
	unsetVagaCandidato: () => dispatch(unsetVagaCandidato()),
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
	logout: () => dispatch(logout()),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(Candidato)
