import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faFileDownload} from "@fortawesome/free-solid-svg-icons"
import PropTypes from 'prop-types'
import {urlArquivo} from "../../Helpers/util"
import {abreModal} from "../../Actions/util"
import connectWithStore from "../../Stores/connectWithStore"

const BtnCvRecrutador = ({curriculo, curriculoTexto, abreModal}) => {
	return (
		curriculo
			? (
				<a
					className="btnActions btnActions--cv"
					href={urlArquivo(curriculo)}
					target="_blank"
					rel="noopener noreferrer">
					<span><FontAwesomeIcon icon={faFileDownload}/></span>
					Currículo
				</a>
			)
			: (
				<button className="btnActions btnActions--cv"
				        onClick={() => abreModal('curriculo-recrutador', {curriculoTexto})}>
					<span><FontAwesomeIcon icon={faFileDownload}/></span>
					Currículo
				</button>
			)
	)
}

BtnCvRecrutador.propTypes = {
	curriculo: PropTypes.object,
	curriculoTexto: PropTypes.string,
}

const mapDispatchToProps = (dispatch) => ({
	abreModal: (tipo, modalExtra) => dispatch(abreModal(tipo, modalExtra)),
})

export default connectWithStore(null, mapDispatchToProps)(BtnCvRecrutador)
