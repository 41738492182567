import React from "react"
import PropTypes from 'prop-types'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Erro = (props) => {
    const mensagem = props.mensagem instanceof Array? props.mensagem: [props.mensagem]
    
    return (
        <Alert severity="error">
            {mensagem.map((v, k) => (
                <p key={k}>
                    {v}&nbsp;
                    {props.tryAgainHandler &&
                        <button
                            type="button"
                            className="button-handle-error"
                            onClick={props.tryAgainHandler}>
                            - Clique aqui para tentar novamente
                        </button>
                    }
                </p>
            ))}
        </Alert>
    )
}
Erro.propTypes = {
	mensagem: PropTypes.any.isRequired,
	tryAgainHandler: PropTypes.func
}

export default Erro