import React, { useState } from 'react'
import ModalVideo from '../../../Components/Modal/VideoHome'
import bannerVideo from '../../../img/videoHome.jpg'

const SobreMomentum = ({ titulo, texto1, texto2 }) => {

    const [modalIsOpen,setIsOpen] = useState(false)
    const openModal = () => {
        setIsOpen(true)
    }
    const closeModal = () => {
        setIsOpen(false)
    }

    return(
        <section className="sobreMomentum">
            <div className="container">
                <div className="sobreMomentum__wrap">
                    <div className="sobreMomentum__item">
                        <h1>{titulo}</h1>
                        <p>{texto1}</p>
                        <p>{texto2}</p>
                        <a href="https://www.momentum.com.br"
                            target="_blank" rel="nofollow noopener noreferrer"
                            className="btnDefault btnDefault--btn3">
                            Saiba mais
                        </a>
                    </div>
                    <div className="sobreMomentum__item">
                        <button type="button" onClick={openModal}>
                            <img src={bannerVideo} 
                                alt="Video Sobre Momentum" 
                                className="img-fluid" />
                        </button>
                    </div>
                </div>
            </div>

            <ModalVideo 
                modalIsOpen={modalIsOpen} 
                closeModal={closeModal}
            />
        </section>
    )
}

export default SobreMomentum