import React from 'react'
import Modal from 'react-modal'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTimes} from "@fortawesome/free-solid-svg-icons"

Modal.setAppElement('#root')

const ModalVideo = ({ modalIsOpen, closeModal }) => {
    return(
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={true}
            className="Modal Modal__video-home"
            overlayClassName="Overlay">
            
            <button onClick={closeModal} className="btn-fechar-video-home">
                <FontAwesomeIcon icon={faTimes} />
            </button>

            <iframe src='https://www.youtube.com/embed/zKzL7v-w8-s'
                frameBorder='0'
                allow='autoplay; encrypted-media'
                allowFullScreen
                title='Momentum'
            />
        </Modal>
    )
}

export default ModalVideo