import React from 'react'
import Layout from '../../Components/Recrutador/Layout'
import Title from '../../Components/Recrutador/Title'
import VagasLista from '../../Components/Recrutador/ListaVagas'
import BtnCriarVaga from '../../Components/Recrutador/BtnCriarVaga'
import PageMeta from '../../Components/PageMeta'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

const VagasRecrutador = () => {
	return (
		<Layout>
			<PageMeta
				title="Vagas"
				description="description da tela de vagas"
				keywords="Vaga, Momentum, Recrutamento"
			/>

			<Title title='Vagas Ativas'/>
			
			<Grid container spacing={2}>
				<Hidden mdUp>
					<Grid item xs={12} sm={2}>
						<BtnCriarVaga/>
					</Grid>
				</Hidden>
				<Grid item xs={12} sm={10}>
					<VagasLista
						tipo="live"
						limit={4}
						paginacao={true}
					/>
		
					<Title title='Vagas Arquivadas'/>
		
					<VagasLista
						tipo="archived"
						limit={4}
						paginacao={true}
					/>
				</Grid>
				<Hidden smDown>
					<Grid item xs={12} sm={2}>
						<BtnCriarVaga/>
					</Grid>
				</Hidden>
			</Grid>
		</Layout>
	)
}

export default VagasRecrutador