import React, {useState} from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { 
    faUser, 
    faFileAlt, 
    faSignOutAlt, 
    faKey } from "@fortawesome/free-solid-svg-icons"
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'
import {logout} from "../../Actions/user"
import {del} from "../../Helpers/request"
import connectWithStore from "../../Stores/connectWithStore"
import Hidden from '@material-ui/core/Hidden'
import {abreModal, setToast} from "../../Actions/util"

const BtnPerfil = ({logout, setToast, abreModal}) => {
    const [anchorEl, setAnchorEl] = useState(null)
  	const open = Boolean(anchorEl)

	const handleClickPerfil = event => {
		setAnchorEl(event.currentTarget)
	}
	
	const handleClosePerfil = () => {
		setAnchorEl(null)
    }

    const onLogout = (e) => {
        e.preventDefault()
        setToast(undefined)
        del('users/session')
            .then(({data}) => {
                logout()
            })
            .catch((err) => {
                const erroMensagem = err.response && err.response.data.message ? err.response.data.message : err.message
                setToast(erroMensagem, 'error')
            })
    }
    
    return (
        <>
            <button 
                type="button" 
                className="btnActions btnActions--perfil"
                aria-controls="fade-menu" 
                aria-haspopup="true" 
                onClick={handleClickPerfil}
            >
                <span><FontAwesomeIcon icon={faUser} /></span>
                <Hidden only="xs">Meu perfil</Hidden>
            </button>
            <Menu
                id="fade-menu"
                className="menu-perfil-site"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClosePerfil}
                TransitionComponent={Fade}>
                <MenuItem onClick={handleClosePerfil}>
                    <NavLink 
                        to="/candidato/perfil"
                        exact 
                        className="header__link-perfil"
                        activeClassName="active">
                        <FontAwesomeIcon icon={faFileAlt} />
                        Dados pessoais
                    </NavLink>
                </MenuItem>
                <MenuItem onClick={handleClosePerfil}>
                    <button
                        className="header__link-perfil"
                        onClick={() => abreModal('esqueci-nova-senha', {permiteFechar: true})}>
                        <FontAwesomeIcon icon={faKey} />
                        Alterar senha
                    </button>
                </MenuItem>
                <MenuItem onClick={handleClosePerfil}>
                    <button
                        onClick={onLogout}
                        className="header__link-perfil">
                        <FontAwesomeIcon icon={faSignOutAlt} />
                        Logout
                    </button>
                </MenuItem>
            </Menu>
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
    abreModal: (tipo, modalExtra) => dispatch(abreModal(tipo, modalExtra)),
})

export default connectWithStore(null, mapDispatchToProps)(BtnPerfil)