import React from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShareAlt } from "@fortawesome/free-solid-svg-icons"
import {candidatoShareUrl} from "../../Helpers/util"
import {withRouter} from "react-router"
import Clipboard from 'react-clipboard.js';
import {setToast} from "../../Actions/util"
import connectWithStore from "../../Stores/connectWithStore"

const BtnShare = (props) => {
    const onSuccess = () => {
        props.setToast('URL copiada com sucesso', 'success')
    }

    return (
        <Clipboard
            data-clipboard-text={candidatoShareUrl(props.match.params.idVaga, props.match.params.idVagaCandidato)} className="btnActions btnActions--share"
            onSuccess={onSuccess}
        >
            <span><FontAwesomeIcon icon={faShareAlt} /></span>
            Compartilhar
        </Clipboard>
    )
}

const mapDispatchToProps = (dispatch) => ({
    setToast: (toastMessage, toastType) => dispatch(setToast(toastMessage, toastType)),
})

export default connectWithStore(null, mapDispatchToProps)(withRouter(BtnShare))
