import React, {Component} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCamera} from "@fortawesome/free-solid-svg-icons"
import CircularProgress from "@material-ui/core/CircularProgress"
import {patch, post} from "../../Helpers/request"
import {login} from "../../Actions/user"
import connectWithStore from "../../Stores/connectWithStore"
import {alertaErro} from "../../Actions/util"
import {urlFotoPerfil} from "../../Helpers/util"

class Avatar extends Component {
	state = {
		loading: false
	}
	
	fileSelectedHandler = event => {
		const dados = new FormData()
		dados.append('foto', event.target.files[0])
		let fotoNova

		this.setState(() => ({
			loading: true
		}))

		post('upload/candidato_foto', dados)
			.then(({data}) => {
				fotoNova = data.arquivo
				return patch('users', {
					foto: data.id
				})
			})
			.then(() => {
				this.props.user.foto = fotoNova
				this.props.login({...this.props.user})
			})
			.catch((err) => {
				this.props.alertaErro(err.response && err.response.data.message ? err.response.data.message : err.message)
			})
			.finally(() => {
				this.setState(() => ({
					loading: false
				}))
			})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

	}

	render() {
		return (
			<div className="avatar-base">
				 {
					this.state.loading 
						? (<CircularProgress className="circle-loading" size={20} />)
						: (<div
							onClick={() => this.fileInput.click()}
							className={this.props.user.foto === null ? 'avatar' : 'avatar avatar--com-avatar'}
							style={{backgroundImage: `url(${urlFotoPerfil(this.props.user.foto, 150, 150, 'fit')})`}}>
							<input
								style={{display: 'none'}}
								type="file"
								name="avatar"
								id="avatar"
								onChange={this.fileSelectedHandler}
								ref={fileInput => this.fileInput = fileInput}
							/>
							<FontAwesomeIcon className="add-foto-avatar" icon={faCamera}/>
						</div>)
				 }
			</div>

		)
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
	login: (user) => dispatch(login(user)),
	alertaErro: (alertaTitulo, alertaMensagem) => dispatch(alertaErro(alertaTitulo, alertaMensagem)),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(Avatar)