const vagaCandidatoAvaliacaoReducer = (state = [], action) => {
	switch(action.type) {
		case 'SET_VAGA_CANDIDATO_AVALIACAO':
			return action.vagaCandidatoAvaliacao
		case 'UNSET_VAGA_CANDIDATO_AVALIACAO':
			return []
		case 'ADD_VAGA_CANDIDATO_AVALIACAO':
			return [...state, action.avaliacao]
		case 'EDIT_VAGA_CANDIDATO_AVALIACAO':
			return state.map((avaliacao) => {
				if (avaliacao.id === action.avaliacao.id) {
					return action.avaliacao
				}
				return avaliacao
			})
		case 'REMOVE_VAGA_CANDIDATO_AVALIACAO':
			return state.filter(({ id }) => id !== action.id)
		default:
			return state
	}
}

export default vagaCandidatoAvaliacaoReducer
