import React, {Component} from "react"
import {login, logout} from "../../../Actions/user"
import {fechaModal, setErros, setToast} from "../../../Actions/util"
import connectWithStore from "../../../Stores/connectWithStore"
import PropTypes from "prop-types"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTimes} from "@fortawesome/free-solid-svg-icons"
import CircularProgress from "@material-ui/core/CircularProgress"
import {maskData, maskTelefone} from "../../../Helpers/util"
import MaskedInput from "react-text-mask/dist/reactTextMask"
import {patch} from "../../../Helpers/request"
import moment from "moment"
import dicAuxiliarCandidato from '../../../Data/dicionarioCandidato.json'
import Modal from "react-modal"
import Erro from "../Erro"

const valorInicial = (valor, mask) => {
	if (!valor) {
		return ''
	}
	if (mask === 'data') {
		return moment(valor, 'YYYY-MM-DD').format('DD/MM/YYYY') || ''
	}
	return valor
}

class ModalInput extends Component {
	_isMounted = false
	state = {
		loading: false,
		erroMensagem: undefined,
		valor: valorInicial(this.props.user[this.props.campo], this.props.mask),
	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	onChange = (e) => {
		const obj = {}
		obj[e.target.name] = e.target.value
		this.setState(() => (obj))
	}

	inputMask = (props) => {
		const maskList = {
			telefone: maskTelefone,
			data: maskData
		}

		return (
			<MaskedInput mask={maskList[this.props.mask]} guide={false} {...props} />
		)
	}

	inputComum = (props) => {
		return (<input {...props} maxLength={255}/>)
	}

	onSubmit = (e) => {
		e && e.preventDefault()

		this.setState(() => ({
			loading: true,
			erroMensagem: undefined,
		}))
		const dados = {}
		dados[this.props.campo] = this.state.valor
		if (this.props.mask === "data") {
			//se der invalid date, vai gerar erro, então está tudo bem
			dados[this.props.campo] = moment(this.state.valor, 'DD/MM/YYYY').format('YYYY-MM-DD')
		}

		patch('users', dados)
			.then(({data}) => {
				if (data.status) {
					const user = {...this.props.user}
					user[this.props.campo] = this.props.mask === "data"
						? moment(this.state.valor, 'DD/MM/YYYY').format('YYYY-MM-DD')
						: this.state.valor
					this.props.login(user)

					if (this.props.erros) {
						const novosErros = Object.assign({}, this.props.erros)
						delete novosErros[this.props.campo]
						this.props.setErros(novosErros)
					}

					this._isMounted && this.onClose()
				}
				else {
					this._isMounted && this.setState(() => ({
						erroMensagem: data.message
					}))
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					this.props.setToast('Sua sessão expirou', 'error')
					this.props.fechaModal()
					this.props.logout()
					return
				}
				//erro controlado
				if (err.response) {
					this._isMounted && this.setState(() => ({
						erroMensagem: err.response.data.message,
					}))
				}
				//erro aleatório
				else {
					this._isMounted && this.setState(() => ({
						erroMensagem: err.response && err.response.data.message ? err.response.data.message : err.message,
					}))
				}
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})
	}

	onClose = () => {
		if (this.props.onClose) {
			this.props.onClose()
		}
		else {
			this.props.fechaModal()
		}
	}

	render() {
		const InputComponent = this.props.mask
			? this.inputMask
			: this.inputComum

		return (
			<>
				<Modal
					{...this.props}
					isOpen
					className="Modal Modal__login"
					overlayClassName="Overlay"
					onRequestClose={this.onClose}
				>
					<div className="modalContent">
						<button className="close-modal" onClick={this.onClose}>
							<FontAwesomeIcon icon={faTimes}/>
						</button>
						<h1 className="modalContent__titulo">Meus dados</h1>
						<form onSubmit={this.onSubmit}>
							<div className="modal-form__item">
								<label
									htmlFor="email"
									className="labelDefault">
									{this.props.placeholder}
								</label>
								{this.props.inputType === 'select' ? (
									<select
										name="valor"
										value={this.state.valor}
										onChange={this.onChange}
										autoFocus
										className="inputDefault">
										<option value="">Selecione</option>
										{dicAuxiliarCandidato[this.props.campo].map((v, k) => (
											<option value={v.value} key={k}>{v.label}</option>
										))}
									</select>

								) : (
									<InputComponent
										type={this.props.inputType}
										name="valor"
										onChange={this.onChange}
										value={this.state.valor}
										autoFocus
										className="inputDefault"
									/>
								)}
							</div>
							{this.state.erroMensagem && (
								<div className="modal-form__item">
									<Erro mensagem={this.state.erroMensagem}/>
								</div>
							)}
							<div className="modal-form__item submitWrap submitWrap--dadosPessoais">
								<button
									type="submit"
									className="btnDefault btnDefault--btn4 btnDefault--loading"
									disabled={this.state.loading}
								>
									{this.state.loading ?
										<>
											<CircularProgress className="circle-loading" size={20}/>
											Salvando
										</>
										: 'Salvar'}
								</button>
								<button
									type="button"
									className="btnDefault btnDefault--btn5"
									onClick={this.onClose}>
									Cancelar
								</button>
							</div>
						</form>
					</div>
				</Modal>
			</>
		)
	}
}

ModalInput.defaultProps = {
	readOnly: false,
	inputType: "text"
}

ModalInput.propTypes = {
	campo: PropTypes.string.isRequired,
	inputType: PropTypes.oneOf(["text", "email", "select"]),
	mask: PropTypes.oneOf(["telefone", "data"]),
	placeholder: PropTypes.string,
}


const mapStateToProps = (state) => ({
	user: state.user,
	erros: state.util.erros,
})

const mapDispatchToProps = (dispatch) => ({
	login: (user) => dispatch(login(user)),
	fechaModal: () => dispatch(fechaModal()),
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
	logout: () => dispatch(logout()),
	setErros: (erros) => dispatch(setErros(erros)),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(ModalInput)
