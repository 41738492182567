import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import App from './App'
import store from "./Stores/configureStore"
import {Provider} from "react-redux"
import { isDev } from './Helpers/util'

if (!isDev()) {
    Sentry.init({
        dsn: "https://f3cd75a547ec4903aaf9fb61ef240966@o479278.ingest.sentry.io/5523681",
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        tracesSampleRate: 1.0,
        release: "momentum-pem@" + process.env.npm_package_version,
    });
}

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
