import React, {Component} from 'react'
import PropTypes from "prop-types"
import axios from "axios"
import Loading from "../Loading"
import Erro from "../ErrorMessage"

class VideoDashboard extends Component {
	_isMounted = false
	state = {
		video: undefined,
		loading: false,
		error: undefined,
	}

	getVideo = () => {
		axios.get(this.props.url, {withCredentials: false, responseType: 'blob'})
			.then(({data, headers, ...resto}) => {
				const videoDataUrl = URL.createObjectURL(data)

				const video = document.createElement('video')
				video.src = videoDataUrl
				//quando permite que o video seja tocado, começa a tocar, mutado com velocidade máxima
				video.oncanplaythrough = () => {
					video.muted = true
					video.controls = true
					video.playbackRate = 16
					video.play().then(() =>{ })
					video.oncanplaythrough = undefined
				}

				//quando o vídeo está perto de acabar temos acesso a duração total do vídeo, isso quer dizer que
				// a barra de progresso já pode ser usada, então pausamos o vídeo, voltamos ao começo, resetamos a
				// velocidade para 1 e desmutamos
				video.ondurationchange = () => {
					if (video.duration !== Infinity) {
						video.pause()
						video.currentTime = 0
						video.playbackRate = 1
						video.muted = false

						//então mandamos o elemento para o estado
						this._isMounted && this.setState(() => ({
							video: video,
							loading: false,
						}))
					}

				}
			})
			.catch((err) => {
				if (err.response) {
					this._isMounted && this.setState(() => ({
						erroMensagem: err.response.data.message,
					}))
				}
				//erro aleatório
				else {
					this._isMounted && this.setState(() => ({
						erroMensagem: err.response && err.response.data.message ? err.response.data.message : err.message,
					}))
				}
			})
			.finally(() => {

			})
	}

	componentDidMount() {
		this._isMounted = true
		//this.getVideo()
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		const onLoadedMetadata = (e) => {
			if (e.currentTarget.videoHeight > e.currentTarget.videoWidth) {
				e.currentTarget.style.maxHeight = '500px'
				e.currentTarget.style.width = 'auto'
			}
		}
		return (
			this.state.loading
				? <Loading/>
				: (
					this.state.error
						? <Erro mensagem={this.state.error} tryAgainHandler={this.getVideo}/>
						: (
							<video controls className={this.props.class} onLoadedMetadata={onLoadedMetadata}>
								<source src={this.props.url} type="video/webm"/>
							</video>
							/*
							Trocar para este caso volte o esquema de pré carregar o vídeo
							<div ref={(div) => {
								if (div) {
									div.appendChild(this.state.video)
								}
							}}/>*/

						)

				)
		)
	}
}

VideoDashboard.propTypes = ({
	class: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired
})

export default VideoDashboard
