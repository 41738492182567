import React, {Component} from "react"
import PropTypes from "prop-types"
import {NavLink} from "react-router-dom"
import connectWithStore from "../../../Stores/connectWithStore"
import {get} from "../../../Helpers/request"
import Vazio from "../../ErrorMessage/vazio"
import Loading from "../../Loading"
import Erro from "../../ErrorMessage"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronDown} from "@fortawesome/free-solid-svg-icons"
import {urlFotoPerfil} from "../../../Helpers/util"

class CandidatosSidebarLista extends Component {
	_isMounted = false
	state = {
		lista: [],
		error: undefined,
		loading: false,
		pagina: 1,
		totalPaginas: undefined,
	}

	getLista = (e) => {
		e && e.preventDefault()

		this.setState(() => ({
			error: false,
			loading: true,
		}))

		get(`recrutador/vaga/${this.props.match.params.idVaga}/candidatos/${this.props.tipo}/${this.state.pagina}`, {
			limit: this.props.limit,
			ignorar: this.props.vagaCandidato.id
		})
			.then(({data}) => {
				if (data.candidatos) {
					this._isMounted && this.setState((prevState) => ({
						lista: prevState.lista.concat(data.candidatos.filter((v) => v.id !== this.props.vagaCandidato.id)),
						totalPaginas: data.total_paginas,
					}))
				}
			})
			.catch((err) => {
				this._isMounted && this.setState(() => ({
					error: err.response && err.response.data.message ? err.response.data.message : err.message
				}))
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})
	}

	onNextPage = (e) => {
		e.preventDefault()
		this.setState((state) => ({
			pagina: state.pagina + 1
		}))
	}

	componentDidMount() {
		this._isMounted = true
		this.getLista()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.pagina !== this.state.pagina || (this.props.vagaCandidato && prevProps.vagaCandidato.id !== this.props.vagaCandidato.id)) {
			this.getLista()
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		return (
			<>
				{!this.state.loading && !this.state.error && this.state.lista.length === 0 && (
					<Vazio message={"Nenhum candidato"}/>
				)}
				{this.state.lista.map((v, k) => (
					<div className={`candidato-box candidato-box--${v.status}`} key={k}>
						<NavLink to={`/recrutador/candidato/${v.vaga.id}/${v.id}`}>
							<div
								className="avatar"
								style={{backgroundImage: `url(${urlFotoPerfil(v.perfil.foto, 50, 50, 'fit')})`}}/>
						</NavLink>
						<div className="info">
							<NavLink to={`/recrutador/candidato/${v.vaga.id}/${v.id}`}>
								<h2>{v.perfil.first_name}</h2>
								<h3>{v.vaga.titulo}</h3>
							</NavLink>
						</div>
					</div>
				))}
				{this.state.loading
					? (<Loading/>)
					: (
						this.state.error
							? (<Erro mensagem={this.state.error} tryAgainHandler={this.getLista}/>)
							: (
								<>
									{this.props.paginacao && this.state.pagina < this.state.totalPaginas && (
										<section className="ver-mais-wrap">
											<button
												onClick={this.onNextPage}>
												Carregar mais
												<FontAwesomeIcon icon={faChevronDown}/>
											</button>
										</section>
									)}
								</>
							)
					)
				}
			</>
		)
	}
}

CandidatosSidebarLista.defaultProps = {
	limit: 3,
	paginacao: false,
}

CandidatosSidebarLista.propTypes = ({
	limit: PropTypes.number,
	paginacao: PropTypes.bool,
	tipo: PropTypes.oneOf(['incompleto', 'pendente', 'aprovado', 'reprovado'])
})

const mapStateToProps = (state) => ({
	vagaCandidato: state.vagaCandidato
})

export default connectWithStore(mapStateToProps, null)(CandidatosSidebarLista)
