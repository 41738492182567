import React from 'react'
import { NavLink } from 'react-router-dom'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faSuitcase, faUser } from "@fortawesome/free-solid-svg-icons"

const LinksMenu = ({ linkShrink, marginSVG }) => (
    <div className="sidebarNav__item sidebarNav__item--linksWrapper">
        <NavLink to="/recrutador/" exact className={linkShrink} activeClassName="active">
            <FontAwesomeIcon icon={faHome} className={marginSVG} />
            <span>Dashboard</span>
        </NavLink>
        <NavLink to="/recrutador/vagas" exact className={linkShrink} activeClassName="active">
            <FontAwesomeIcon icon={faSuitcase} className={marginSVG} />
            <span>Vagas</span>
        </NavLink>
        <NavLink to="/recrutador/candidatos/lista" exact className={linkShrink} activeClassName="active">
            <FontAwesomeIcon icon={faUser} className={marginSVG} />
            <span>Candidatos</span>
        </NavLink>
    </div>
)

export default LinksMenu