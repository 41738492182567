import React, {Component} from "react"
import PropTypes from 'prop-types'
import {setToast, updateListaTimestamp} from "../../Actions/util"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faArchive} from "@fortawesome/free-solid-svg-icons"
import {patch} from "../../Helpers/request"
import connectWithStore from "../../Stores/connectWithStore"
import CircularProgress from "@material-ui/core/CircularProgress"
import Tooltip from '@material-ui/core/Tooltip'
import {logout} from "../../Actions/user"

class BtnArquivarVaga extends Component {
	_isMounted = false
	state = {
		loading: false,
		error: undefined,
		avaliacao: undefined,
	}

	arquiva = () => {
		this.setState(() => ({
			error: undefined,
			loading: true,
		}))

		const dados = {
			status: 'archived'
		}
		patch(`recrutador/vaga/${this.props.idVaga}`, dados)
			.then(() => {
				this.props.setToast(`Vaga arquivada com sucesso`, 'success')
				this._isMounted && this.props.updateListaTimestamp()
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					this.props.setToast('Sua sessão expirou', 'error')
					this.props.logout()
					return
				}
				const error = err.response && err.response.data.message ? err.response.data.message : err.message
				this.props.setToast(error, 'error')
			})
			.finally(() => {
				//this.props.setLoading(false)
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})

	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		return (
			this.state.loading
				? (<CircularProgress className="circle-loading" size={20} />)
				: (
					<Tooltip title="Arquivar vaga" arrow placement="bottom">
						<button type="button" className="btn-arquivar-vaga" onClick={this.arquiva}>
							<FontAwesomeIcon icon={faArchive}/>
						</button>
					</Tooltip>
				)
		)
	}
}

BtnArquivarVaga.propTypes = {
	idVaga: PropTypes.number.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
	updateListaTimestamp: () => dispatch(updateListaTimestamp()),
	logout: () => dispatch(logout()),
})

export default connectWithStore(null, mapDispatchToProps)(BtnArquivarVaga)
