import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import {get} from "../../../Helpers/request"
import Loading from "../../Loading"
import Erro from "../../ErrorMessage"
import moment from "moment"
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronDown, faPencilAlt} from "@fortawesome/free-solid-svg-icons"
import Vazio from "../../ErrorMessage/vazio"
import connectWithStore from "../../../Stores/connectWithStore"
import BtnArquivarVaga from "../../Buttons/arquivarVaga"
import Tooltip from '@material-ui/core/Tooltip'
import {setToast} from "../../../Actions/util"
import {logout} from "../../../Actions/user"

class VagasLista extends Component {
	_isMounted = false
	state = {
		lista: [],
		error: undefined,
		loading: false,
		pagina: 1,
		totalPaginas: undefined,
	}

	getVagas = (e) => {
		e && e.preventDefault()

		this.setState(() => ({
			error: undefined,
			loading: true,
		}))

		const qs = {
			limit: this.props.limit
		}

		if (this.props.q) {
			qs.q = this.props.q
		}

		get(`recrutador/vaga/lista${this.props.tipo? `/${this.props.tipo}`: ''}/${this.state.pagina}`, qs)
			.then(({data}) => {
				if (data.vagas) {
					this._isMounted && this.setState((prevState) => ({
						lista: prevState.lista.concat(data.vagas),
						totalPaginas: data.total_paginas,
					}))
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					this.props.setToast('Sua sessão expirou', 'error')
					this.props.logout()
					return
				}
				this._isMounted && this.setState(() => ({
					error: err.response && err.response.data.message ? err.response.data.message : err.message
				}))
			})
			.finally(() => {
				//this.props.setLoading(false)
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})
	}

	onNextPage = (e) => {
		e.preventDefault()
		this.setState((state) => ({
			pagina: state.pagina + 1
		}))
	}

	componentDidMount() {
		this._isMounted = true
		this.getVagas()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.pagina !== this.state.pagina && this.state.totalPaginas !== undefined) {
			this.getVagas()
		}

		if (prevProps.listaTimestamp !== this.props.listaTimestamp || prevProps.q !== this.props.q) {
			this.setState(() => ({
				lista: [],
				error: undefined,
				loading: false,
				pagina: 1,
				totalPaginas: undefined,
			}), this.getVagas)
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		return (
			<>
				{!this.state.loading && !this.state.error && this.state.lista.length === 0 && (
					<Vazio message={this.props.q? "Nenhuma vaga encontrada": "Nenhuma vaga cadastrada"} />
				)}
				<section className="lista-vagas">
					{this.state.lista.map((v, k) => (
						<div
							className={v.status === "live" ? "lista-vagas__item" : "lista-vagas__item arquivado"}
							key={k}>
								{v.status === "live" && 
									<div className="actions">
										<Tooltip title="Editar vaga" arrow placement="bottom">
											<NavLink
												to={`/recrutador/vagas/editar/${v.id}`}
												className="btn-editar-vaga">
												<FontAwesomeIcon icon={faPencilAlt} />
											</NavLink>
										</Tooltip>
										<BtnArquivarVaga idVaga={v.id} />
									</div>
								}
								<span className={v.status === "live" ? "label-data" : "label-data arquivado"}>
									{moment(v.created_on, 'YYYY-MM-DD').format('DD/MM/YYYY')}
								</span>
								<h1 className='titulo-vaga'>{v.titulo}</h1>
								<div className='resumo-vaga'>
									<div className='resumo-vaga__item'>
										{v.resumo.total}
										<br/>
										<small>candidato{v.resumo.total !== 1? 's': ''}</small>
									</div>
									<div className='resumo-vaga__item'>
										{v.resumo.pendente}
										<br/>
										<small>pendente{v.resumo.pendente !== 1? 's': ''}</small>
									</div>
									<div className='resumo-vaga__item'>
										{v.resumo.aprovado}
										<br/>
										<small>aprovado{v.resumo.aprovado !== 1? 's': ''}</small>
									</div>
									<div className='resumo-vaga__item'>
										{v.resumo.reprovado}
										<br/>
										<small>reprovado{v.resumo.reprovado !== 1? 's': ''}</small>
									</div>
								</div>
								<NavLink 
									to={`/recrutador/vagas/detalhes/${v.id}`}
									className="btnDefault btnDefault--btn1">
									Detalhes
								</NavLink>
						</div>
					))}
					{this.state.loading
						? (<div className="wrap-spinner"><Loading/></div>)
						: (
							this.state.error
								? (<Erro mensagem={this.state.error} tryAgainHandler={this.getVagas}/>)
								: (
									this.props.mostraBtVerTodas && this.state.pagina < this.state.totalPaginas && (
										<NavLink to="/recrutador/vagas" className="btnDefault btnDefault--btn4">
											Ver todas
										</NavLink>
									)
								)
						)
					}
				</section>
				{!this.state.loading && this.props.paginacao && this.state.pagina < this.state.totalPaginas && (
					<section className="ver-mais-wrap">
						<button
							onClick={this.onNextPage}>
							Carregar mais
							<FontAwesomeIcon icon={faChevronDown}/>
						</button>
					</section>
				)}
			</>
		)
	}
}

VagasLista.defaultProps = {
	limit: 3,
	paginacao: false,
	mostraBtVerTodas: false,
}

VagasLista.propTypes = {
	limit: PropTypes.number,
	paginacao: PropTypes.bool,
	mostraBtVerTodas: PropTypes.bool,
	tipo: PropTypes.oneOf(['live', 'archived']),
	q: PropTypes.string,
}

const mapStateToProps = (state) => ({
	listaTimestamp: state.util.listaTimestamp
})

const mapDispatchToProps = (dispatch) => ({
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
	logout: () => dispatch(logout()),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(VagasLista)
