import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileDownload } from "@fortawesome/free-solid-svg-icons"
import PropTypes from 'prop-types'
import {urlArquivo} from "../../Helpers/util"

const BtnCv = props => {
    return (
        <a 
            className={props.compacto ? 'btnActions btnActions--cv' : 'btnAdd btnAdd--cv'}
            //className="btnAdd btnAdd--cv"
            href={urlArquivo(props.file)}
            target="_blank"
            rel="noopener noreferrer">
            <span><FontAwesomeIcon icon={faFileDownload} /></span>
            {props.compacto? 'Currículo': props.file.name}
        </a>
    )
}

BtnCv.defaultProps = {
    compacto: true,
}

BtnCv.propTypes = {
    file: PropTypes.object.isRequired,
    compacto: PropTypes.bool.isRequired
}

export default BtnCv