import React, {Component} from 'react'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
	faEnvelope,
	faPhoneAlt,
	faMapMarkerAlt,
	faBirthdayCake,
	faSuitcase,
	faGraduationCap
} from "@fortawesome/free-solid-svg-icons"

import {login} from "../../../Actions/user"
import connectWithStore from "../../../Stores/connectWithStore"
import Input from "./input"
import InputLocalidade from "./inputLocalidade"

class InfosContato extends Component {
	componentDidMount() {

	}

	render() {
		return (
			<div className="dados">
				<h2>{this.props.user.first_name}</h2>

				<ul>
					<li>
						<FontAwesomeIcon icon={faEnvelope}/>
						<strong>E-mail:</strong>
						<Input
							campo="email"
							inputType="email"
							placeholder="Seu e-mail"
							readOnly={true}
						/>
					</li>
					<li>
						<FontAwesomeIcon icon={faPhoneAlt}/>
						<strong>Telefone:</strong>
						<Input
							campo="telefone"
							mask="telefone"
							placeholder="Seu telefone"
						/>
					</li>
					<li>
						<FontAwesomeIcon icon={faMapMarkerAlt}/>
						<strong>Localização:</strong>
						<InputLocalidade />
					</li>
				</ul>
				<ul>
					<li>
						<FontAwesomeIcon icon={faBirthdayCake}/>
						<strong>Nascimento:</strong>
						<Input
							campo="data_nascimento"
							mask="data"
							placeholder="Sua data de nascimento"
						/>
					</li>
					<li>
						<FontAwesomeIcon icon={faSuitcase}/>
						<strong>Profissão:</strong>
						<Input
							campo="profissao"
							placeholder="Sua profissão"
						/>
					</li>
					<li>
						<FontAwesomeIcon icon={faGraduationCap}/>
						<strong>Escolaridade:</strong>
						<Input
							campo="escolaridade"
							inputType="select"
							placeholder="Sua escolaridade"
						/>
					</li>
				</ul>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.user
})

const mapDispatchToProps = (dispatch) => ({
	login: (user) => dispatch(login(user)),

})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(InfosContato)
