import React/*, {useState, useCallback, useMemo}*/ from 'react'
/* import Progressbar from './Progressbar' */
import VideoRecord from './VideoRecord'
//import {useDropzone} from 'react-dropzone'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
	/* faCloudUploadAlt, */ 
	/* faFileVideo, */ 
	faCamera } from "@fortawesome/free-solid-svg-icons"
import PropTypes from "prop-types"
import {alertaErro} from "../../../Actions/util"
import connectWithStore from "../../../Stores/connectWithStore"
//import {post} from "../../../Helpers/request"
import {isIE, isSafari, isMobileSafari} from 'react-device-detect'

/*const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fff',
	color: '#bdbdbd',
	outline: 'none',
	marginTop: '30px',
	transition: 'border .24s ease-in-out',
}

const activeStyle = {
	borderColor: '#2196f3'
}

const acceptStyle = {
	borderColor: '#00e676'
}

const rejectStyle = {
	borderColor: '#ff1744'
}*/

const DropZone = (props) => {
	/*const [duration, setVideoDuration] = useState(false)
	const [arquivo, setArquivo] = useState(undefined)
	const [progress, setProgress] = useState(0)
	const [isUpload, setIsUpload] = useState(false)

	const reset = () => {
		setVideoDuration(false)
		setArquivo(undefined)
		setProgress(0)
		setIsUpload(false)
	}

	const onDrop = useCallback((acceptedFiles) => {
		acceptedFiles.forEach(file => {
			//pega a duração do vídeo
			const vid = document.createElement('video')
			const fileURL = URL.createObjectURL(file)
			vid.src = fileURL

			vid.ondurationchange = () => {
				if (vid.duration !== Infinity && vid.duration > 200) {
					props.alertaErro('A duração máxima permitida para o vídeo é de 2 minutos', vid.duration)
				}
				else {
					const options = {
						onUploadProgress: function(progressEvent) {
							const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
							setProgress(percentCompleted)
						}
					}

					const dados = new FormData()
					dados.append('resposta', file)

					setVideoDuration(true)
					setIsUpload(true)
					post('upload/vaga_resposta', dados, options)
					.then(({data}) => {
							if (data.status) {
								setArquivo(data.arquivo.id)
							}
							else{
								props.alertaErro("Arquivo inválido")
								reset()
							}
						})
						.catch((err) => {
							props.alertaErro(err.response && err.response.data.message ? err.response.data.message : err.message)
							reset()
						})
				}
			}
		})
	}, [])

	const onDropRejected = useCallback((acceptedFiles) => {
		acceptedFiles.forEach(file => {
			props.alertaErro('Arquivo rejeitado', file)
		})
	})

	const {
		open,
		acceptedFiles,
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject
	} = useDropzone({
		noClick: true,
		noKeyboard: true,
		multiple: false,
		accept: 'video/!*',
		onDrop,
		onDropRejected
	})*//*const {
		open,
		acceptedFiles,
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject
	} = useDropzone({
		noClick: true,
		noKeyboard: true,
		multiple: false,
		accept: 'video/!*',
		onDrop,
		onDropRejected
	})

	const style = useMemo(() => ({
		...baseStyle,
		...(isDragActive ? activeStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isDragActive,
		isDragAccept,
		isDragReject
	])

	const onArquivoOk = () => {
		props.onResposta(arquivo)
	}*/

	const onVideoOk = arquivo => {
		props.onResposta(arquivo)
	}

	const checkBrowser = () => {
		if((isIE || isSafari) && !isMobileSafari) {
			return (
				<div className="gravacao-incompativel">
					<div className="ico">
						<FontAwesomeIcon icon={faCamera}/>
					</div>
					<p className="legenda-botao">
						Navegador incompatível com gravação de vídeo.
						<br/>
						Utilize outro navegador para ter acesso ao recurso.
					</p>
				</div>
			)
		}

		return <VideoRecord pergunta={props.pergunta} onArquivoOk={onVideoOk} />
	}

	return (
		<>
			<div className="botoes">
				{ checkBrowser() }
			</div>

			{/* <div className="dropzone-wrap" {...getRootProps({style})}>
				<input {...getInputProps()} />
			
				{duration && acceptedFiles.map((file, index) => {
					return (
						<div className="progress-box" key={index}>
							<div className="progress-box__wrap">
								<div className="progress-box__icone">
									<FontAwesomeIcon icon={faFileVideo}/>
								</div>
								<div className="progress-box__barra">
									<p className="nome-arquivo">{file.path}</p>
									<Progressbar
										arquivo={arquivo}
										progress={progress}
										onArquivoOk={onArquivoOk}
										onCancelar={reset}
									/>
								</div>
							</div>
						</div>
					)
				})}
			
				{isUpload 
					? !!arquivo
					: <div className="botoes">
						<div>
							<button className="btnVideo btnVideo--upload" onClick={open}>
								<FontAwesomeIcon icon={faCloudUploadAlt}/>
							</button>
							<p className="legenda-botao">
								Arraste e solte o arquivo de vídeo
								<br/>
								que você deseja enviar
							</p>
						</div>
			
						{checkBrowser()}
					</div>
				}
			</div> */}
		</>
	)
}

DropZone.propTypes = {
	onResposta: PropTypes.func.isRequired,
	pergunta: PropTypes.string.isRequired
}

const mapDispatchToProps = (dispatch) => ({
	alertaErro: (alertaTitulo, alertaMensagem, alertaExtra) => dispatch(alertaErro(alertaTitulo, alertaMensagem))
})

export default connectWithStore(null, mapDispatchToProps)(DropZone)
