import React from 'react'
import { Switch, Route } from 'react-router-dom'

/* Site */
import HomeSite from '../Pages/Site/Home'
import VagasSite from '../Pages/Site/Vagas'

/* Recrutador */
import HomeRecrutador from '../Pages/Recrutador/Home'
import VagasRecrutador from '../Pages/Recrutador/Vagas'
import DetalheVaga from '../Pages/Recrutador/DetalheVaga'
import ListaCandidato from '../Pages/Recrutador/ListaCandidatos'
import Candidato from '../Pages/Recrutador/Candidato'
import FormVaga from '../Pages/Recrutador/FormVaga'

/* Candidato */
import PerfilCandidato from '../Pages/Candidato/Perfil'
import CandidatarVaga from '../Pages/Candidato/Candidatar'
import Regravar from "../Pages/Candidato/Regravar"
import CandidatoCompartilhado from "../Pages/Recrutador/CandidatoCompartilhado"
import ResultadoBusca from "../Pages/Recrutador/ResultadoBusca"

//404
import Page404 from '../Pages/404'

const Routes = () => (
    <Switch>
        <Route path="/" exact component={HomeSite} />
        <Route path="/activate/:idUser([0-9]+)/:activationCode([0-9a-f]+)" exact component={HomeSite} />
        <Route path="/reset-pass/:forgottenPasswordCode([0-9a-f]+)" exact component={HomeSite} />
        <Route path="/login" component={HomeSite} />
        {/*  Estamos utilizando o parametro "id" para redirecionar o usuário após o cadastro.
          Favor não criar outro parametro chamado "id" na área aberta do site  */}
        <Route path="/vagas/:id([0-9]+)" exact component={VagasSite} />
        <Route path="/vagas/:id([0-9]+)/activate/:idUser([0-9]+)/:activationCode([0-9a-f]+)" exact component={VagasSite} />
        <Route path="/vagas/:id([0-9]+)/candidatar" exact component={CandidatarVaga} />
        <Route path="/vagas/:id([0-9]+)/regravar" exact component={Regravar} />

        <Route path="/recrutador/" exact component={HomeRecrutador} />
        <Route path="/recrutador/busca" exact component={ResultadoBusca} />
        <Route path="/recrutador/vagas" exact component={VagasRecrutador} />
        <Route path="/recrutador/vagas/detalhes/:id([0-9]+)" exact component={DetalheVaga} />
        <Route path="/recrutador/vagas/criar" exact component={FormVaga} />
        <Route path="/recrutador/vagas/editar/:id([0-9]+)" exact component={FormVaga} />
        <Route path="/recrutador/candidatos/lista" exact component={ListaCandidato} />
        <Route path="/recrutador/candidato/:idVaga([0-9]+)/:idVagaCandidato([0-9]+)" exact component={Candidato} />
        <Route path="/recrutador/share/:hash" exact component={CandidatoCompartilhado} />

        <Route path="/candidato/perfil" exact component={PerfilCandidato} />
        
        <Route component={Page404} />
    </Switch>
)

export default Routes