import React from 'react'
import bannerHome from '../../../img/bannerHome.png'

const BannerSite = ({ titulo, texto }) => (
    <section className="bannerHome">
        <div className="container">
            <div className="bannerHome__wrap">
                <div className="bannerHome__item">
                    <div className="bannerHome__txtWrap">
                        <h1 className="bannerHome__titulo">
                            {titulo}
                        </h1>
                        <p className="bannerHome__txt">
                            {texto}
                        </p>
                    </div>
                </div>
                <div className="bannerHome__item">
                    <img src={bannerHome} alt="Talento PEM" className="img-fluid" />
                </div>
            </div>
        </div>
    </section>
)

export default BannerSite