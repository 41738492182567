import React, {Component} from "react"
import moment from "moment"
import Box from "@material-ui/core/Box"
import Rating from "@material-ui/lab/Rating"
import PropTypes from 'prop-types'
import {faPencilAlt, faTrashAlt} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import connectWithStore from "../../../Stores/connectWithStore"
import Criar from "./criar"
import Tooltip from "@material-ui/core/Tooltip"
import {alerta, alertaErro, setToast} from "../../../Actions/util"
import {del} from "../../../Helpers/request"
import {removeVagaCandidatoAvaliacao} from "../../../Actions/vagaCandidatoAvaliacao"
import CircularProgress from "@material-ui/core/CircularProgress"

class AvaliacaoItem extends Component {
	_isMounted = false
	state = {
		editando: false,
		loading: false,
	}

	toggleEditando = (e) => {
		e && e.preventDefault()
		this.setState((prevState) => ({
			editando: !prevState.editando,
		}))
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.avaliacao !== this.props.avaliacao) {
			this.setState(() => ({
				editando: false,
			}))
		}
	}

	confirmDelete = (e) => {
		e && e.preventDefault()
		this.props.alertaErro('Tem certeza que deseja excluir este comentário?', undefined, {
			btAcao: (
				<div className="wrap-buttons">
					<button className="btnDefault btnDefault--btn4" onClick={() => this.onDelete()}>Sim</button>
					<button className="btnDefault btnDefault--btn1" onClick={() => this.props.fechaModal()}>Não</button>
				</div>
			)
		})

	}

	onDelete = (e) => {
		this.props.fechaModal()
		this.setState(() => ({
			loading: true,
		}))

		del(`recrutador/vaga/${this.props.vagaCandidato.vaga.id}/candidato/${this.props.vagaCandidato.id}/avaliacao/${this.props.avaliacao.id}`)
			.then(({data}) => {
				this.props.setToast(data.message, 'success')
				this._isMounted && this.props.removeVagaCandidatoAvaliacao(this.props.avaliacao.id)
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					this.props.setToast('Sua sessão expirou', 'error')
					this.props.logout()
					return
				}
				//erro controlado
				if (err.response) {
					this.props.setToast(err.response.data.message, 'error')
				}
				//erro aleatório
				else {
					this.props.setToast(err.response && err.response.data.message ? err.response.data.message : err.messagem, 'error')
				}

			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loading: false,
				}))

			})
	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		return (
			this.state.editando
				? (
					<Criar onComentario={this.props.onComentario} avaliacao={this.props.avaliacao}/>
				)
				: (
					<div className="listagem__item">
						<header className="listagem__header">
							<h1 className="listagem__titulo">
								{this.props.avaliacao.recrutador_nome}
								{' '}
								<small>{moment(this.props.avaliacao.created_on, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')}</small>
							</h1>
							<div className="listagem__wrapButtons">
								{!this.props.readOnly && this.props.avaliacao.owner && (
									<>
										<Tooltip title="Editar comentário" arrow placement="bottom">
											<button 
												className="btn-editar-avaliacao" 
												onClick={this.toggleEditando} 
												disabled={this.state.loading}
											>
												<FontAwesomeIcon icon={faPencilAlt}/>
											</button>
										</Tooltip>
										{this.state.loading
											? (
												<CircularProgress className="circle-loading" size={20}/>
											)
											: (
												<Tooltip title="Excluir comentário" arrow placement="bottom">
													<button 
														className="btn-excluir-avaliacao" 
														onClick={this.confirmDelete}
													>
														<FontAwesomeIcon icon={faTrashAlt}/>
													</button>
												</Tooltip>
											)}
									</>
								)}
							</div>
						</header>
						<Box component="fieldset" mb={3} borderColor="transparent">
							<Rating
								value={this.props.avaliacao.nota}
								readOnly={true}
							/>
						</Box>
						<p className="listagem__txtAvaliacao">
							{this.props.avaliacao.comentario}
							{this.props.avaliacao.created_on !== this.props.avaliacao.updated_on && (
								<small> (editado)</small>
							)}
						</p>
					</div>
				)
		)
	}
}

AvaliacaoItem.propTypes = {
	avaliacao: PropTypes.object,
}

const mapStateToProps = (state) => ({
	user: state.user,
	vagaCandidato: state.vagaCandidato,
})

const mapDispatchToProps = (dispatch) => ({
	alertaErro: (alertaTitulo, alertaMensagem, alertaExtra) => dispatch(alertaErro(alertaTitulo, alertaMensagem, alertaExtra)),
	fechaModal: () => dispatch(alerta(undefined)),
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
	removeVagaCandidatoAvaliacao: (id) => dispatch(removeVagaCandidatoAvaliacao(id))
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(AvaliacaoItem)
