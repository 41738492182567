import Modal from "react-modal"
import React, {Component} from "react"
import {patch} from "../../Helpers/request"
import {login} from "../../Actions/user"
import CircularProgress from "@material-ui/core/CircularProgress"
import Erro from "./Erro"
import connectWithStore from "../../Stores/connectWithStore"
import {abreModal, alertaSucesso, fechaModal} from "../../Actions/util"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTimes} from "@fortawesome/free-solid-svg-icons"
import PropTypes from "prop-types"

class EsqueciMinhaSenha extends Component {
	_isMounted = false
	state = {
		password: '',
		password_confirm: '',
		loading: false,
		erroMensagem: undefined,
		erroCampos: [],
	}

	onChange = (e) => {
		const obj = {}
		obj[e.target.name] = e.target.value
		this.setState(() => (obj))
	}

	onSubmit = (e) => {
		e.preventDefault()

		this.setState(() => ({
			loading: true,
			erroMensagem: undefined,
			erroCampos: []
		}))

		if (this.state.password.length > 0 && this.state.password !== this.state.password_confirm) {
			return this.setState(() => ({
				erroCampos: {
					password_confirm: 'O campo "Confirmar senha" deve ser igual ao campo "Senha"'
				}
			}))
		}

		if (this.state.password.length > 0 && this.state.password !== this.state.password_confirm) {
			this.setState(() => ({
				erroCampos: {password_confirm: 'O campo'}
			}))
		}

		const dados = {
			password: this.state.password,
		}
		patch('users', dados)
			.then(({data}) => {
				if (data.status) {
					this.props.onRequestClose()
					this.props.alertaSucesso(data.message)
				}
				else {
					this.setState(() => ({
						erroMensagem: data.erros ? undefined : data.message,
						erroCampos: data.erros || {}
					}))
				}
			})
			.catch((err) => {
				//erro controlado
				if (err.response) {
					this._isMounted && this.setState(() => ({
						erroMensagem: err.response.data.erros ? undefined : err.response.data.message,
						erroCampos: err.response.data.erros || {}
					}))
				}
				//erro aleatório
				else {
					this._isMounted && this.setState(() => ({
						erroMensagem: err.response && err.response.data.message ? err.response.data.message : err.message,
					}))
				}
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})
	}

	onRequestClose = (e) => {
		e && e.preventDefault()
		if (this.props.permiteFechar) {
			this.props.onRequestClose()
		}

	}

	componentDidUpdate(prevProps, prevState, snapshot) {
	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		return (
			<Modal
				{...this.props}
				isOpen
				className="Modal Modal__login"
				overlayClassName="Overlay"
				onRequestClose={this.onRequestClose}
			>
				<div className="modalContent">
					{this.props.permiteFechar && (
						<button className="close-modal" onClick={this.props.onRequestClose}>
							<FontAwesomeIcon icon={faTimes}/>
						</button>
					)}
					<h1 className="modalContent__titulo">Alterar senha</h1>
					<form className="modal-form" onSubmit={this.onSubmit}>
						<div className="modal-form__item">
							<label
								htmlFor="senha"
								className="labelDefault">
								Nova senha*
							</label>
							<input
								type="password"
								id="senha"
								name="password"
								className="inputDefault"
								placeholder="*******"
								maxLength={255}
								value={this.state.senha}
								onChange={this.onChange}
							/>
							{this.state.erroCampos.password &&
							<Erro mensagem={this.state.erroCampos.password}/>}
						</div>
						<div className="modal-form__item">
							<label
								htmlFor="password_confirm"
								className="labelDefault">
								Confirme a nova senha*
							</label>
							<input
								type="password"
								id="password_confirm"
								name="password_confirm"
								className="inputDefault"
								placeholder="*******"
								maxLength={255}
								value={this.state.password_confirm}
								onChange={this.onChange}
							/>
							{this.state.erroCampos.password_confirm &&
							<Erro mensagem={this.state.erroCampos.password_confirm}/>}
						</div>
						{this.state.erroMensagem &&
						<div className="modal-form__item">
							<Erro mensagem={this.state.erroMensagem}/>
						</div>
						}
						<div className="modal-form__item submitWrap">
							<button
								type="submit"
								className="btnDefault btnDefault--btn4 btnDefault--loading"
								disabled={this.state.loading}
							>
								{this.state.loading ?
									<>
										<CircularProgress className="circle-loading" size={20}/>
										Alterando
									</>
									: 'Alterar'}
							</button>
						</div>
					</form>
				</div>
			</Modal>
		)
	}
}

EsqueciMinhaSenha.defaultProps = {
	permiteFechar: false,
}

EsqueciMinhaSenha.propTypes = {
	permiteFechar: PropTypes.bool.isRequired
}

const mapDispatchToProps = (dispatch) => ({
	login: (user) => dispatch(login(user)),
	onRequestClose: () => dispatch(fechaModal()),
	abreModal: (tipo) => dispatch(abreModal(tipo)),
	alertaSucesso: (alertaTitulo, alertaMensagem) => dispatch(alertaSucesso(alertaTitulo, alertaMensagem)),
})

export default connectWithStore(null, mapDispatchToProps)(EsqueciMinhaSenha)
