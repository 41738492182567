import React, {Component} from 'react'
import BtnCv from './cv'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faFileDownload, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons"
import {patch, post} from "../../Helpers/request"
import {login} from "../../Actions/user"
import {alertaErro, setErros, setToast} from "../../Actions/util"
import connectWithStore from "../../Stores/connectWithStore"
import PropTypes from "prop-types"
import CircularProgress from "@material-ui/core/CircularProgress"
import Loading from "../Loading"
import Tooltip from '@material-ui/core/Tooltip'

class BtnAddCv extends Component {
	_isMounted = false
	state = {
		loading: false,
	}

	handleClick = e => {
		e.preventDefault()

		this.fileInput.click()
	}

	fileSelectedHandler = event => {
		const dados = new FormData()
		dados.append('curriculo', event.target.files[0])
		let curriculoNovo

		this.setState(() => ({
			loading: true
		}))

		post('upload/candidato_curriculo', dados)
			.then(({data}) => {
				curriculoNovo = data.arquivo
				return patch('users', {
					curriculo: data.id
				})
			})
			.then(() => {
				this.props.login({...this.props.user, curriculo: curriculoNovo, curriculo_texto: null})

				if (this.props.erros) {
					const novosErros = Object.assign({}, this.props.erros)
					delete novosErros.curriculo
					this.props.setErros(novosErros)
				}

				this.props.onUpdate && this.props.onUpdate()

				this.props.setToast('Currículo salvo com sucesso', 'success')
			})
			.catch((err) => {
				this.props.alertaErro(err.response && err.response.data.message ? err.response.data.message : err.message)
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loading: false
				}))
			})
	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		return (
			<>
				{this.state.loading
					? (
						this.props.compacto? <CircularProgress className="circle-loading" size={20}/>: <Loading />)
					: (
						!this.props.user.curriculo || this.props.forceInput
							? (
								<>
									<Tooltip title="Permitido arquivos .pdf, .doc e .docx" arrow placement="top">
										<button 
											className="btnAdd btnAdd--cv"
											onClick={this.handleClick}>
											<span><FontAwesomeIcon icon={faFileDownload}/></span>
											{this.props.compacto
												? <>
													Importar <br/> currículo
													{this.props.erros && this.props.erros.curriculo && (<FontAwesomeIcon icon={faExclamationTriangle}/>)}
												  </>

												: 'Clique aqui para importar seu currículo (PDF ou Word)'
											}
										</button>
									</Tooltip>

									<input
										style={{display: 'none'}}
										type="file"
										name="cv"
										id="cv"
										accept="application/pdf, .doc, .docx"
										onChange={this.fileSelectedHandler}
										ref={fileInput => this.fileInput = fileInput}
									/>
									{/* {!this.props.compacto && <small>*Permitido arquivos .pdf, .doc e .docx</small>} */}
								</>
							)
							: (
								<BtnCv file={this.props.user.curriculo} compacto={this.props.compacto}/>
							)
					)}
			</>
		)
	}
}

BtnAddCv.defaultProps = {
	compacto: true,
	forceInput: false,
}

BtnAddCv.propTypes = {
	compacto: PropTypes.bool.isRequired,
	forceInput: PropTypes.bool.isRequired,
	onUpdate: PropTypes.func,
}

const mapStateToProps = (state) => ({
	user: state.user,
	erros: state.util.erros,
})

const mapDispatchToProps = (dispatch) => ({
	login: (user) => dispatch(login(user)),
	alertaErro: (alertaTitulo, alertaMensagem) => dispatch(alertaErro(alertaTitulo, alertaMensagem)),
	setErros: (erros) => dispatch(setErros(erros)),
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(BtnAddCv)
