import React, {Component} from 'react'

import Carousel from 'react-elastic-carousel'
import Loading from '../../Loading'
import {get} from '../../../Helpers/request'
import Erro from "../../Modal/Erro"
import {NavLink} from "react-router-dom"
import PropTypes from "prop-types"
import {withRouter} from "react-router"
import {labelDicAuxiliarVaga} from "../../../Helpers/util"

class ListaOportunidades extends Component {
	_isMounted = false
	state = {
		listaOportunidades: [],
		error: undefined,
		loading: false,
	}

	componentDidMount() {
		this._isMounted = true
		this.getData()

		//console.log(this.state.listaOportunidades)
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	getData = (e) => {
		e && e.preventDefault()

		this.setState(() => ({
			error: undefined,
			loading: true,
		}))
		const queryString = {
			limit: 40
		}
		if (this.props.match.params.id) {
			queryString.ignorar = this.props.match.params.id
		}

		get(`vaga/lista`, queryString)
			.then(({data}) => {
				this._isMounted && this.setState(() => ({
					listaOportunidades: data.vagas? data.vagas: []
				}))
			})
			.catch((err) => {
				this._isMounted && this.setState(() => ({
					error: err.response && err.response.data.message ? err.response.data.message : err.message
				}))
			})
			.finally(() => {
				//this.props.setLoading(false)
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.getData()
		}
	}

	sliders() {
		return this.state.listaOportunidades.map((item, key) => {
			return (
				<div className="oportunidadesHome__item" key={key}>

					<h1>{item.titulo}</h1>
					<ul>
						<li>
							<strong>Nível de experiência</strong>
							<br/>
							{labelDicAuxiliarVaga('nivel_experiencia', item.nivel_experiencia)}
						</li>
						<li>
							<strong>Tipo de emprego</strong>
							<br/>
							{labelDicAuxiliarVaga('periodo_trabalho', item.periodo_trabalho)}
						</li>
						<li>
							<strong>Localização</strong>
							<br/>
							{item.cidade} - {item.estado}
						</li>
					</ul>
					<NavLink className="btnDefault btnDefault--btn1" to={`/vagas/${item.id}`}>
						Detalhes
					</NavLink>
				</div>
			)
		})
	}

	buildVaga() {
		return (
			<div className="oportunidadesHome__wrap">
				{this.state.listaOportunidades.map((item, key) => {
					return (
						<div className="oportunidadesHome__item" key={key}>
							<h1>{item.titulo}</h1>
							<ul>
								<li>
									<strong>Nível de experiência</strong>
									<br/>
									{labelDicAuxiliarVaga('nivel_experiencia', item.nivel_experiencia)}
								</li>
								<li>
									<strong>Tipo de emprego</strong>
									<br/>
									{labelDicAuxiliarVaga('periodo_trabalho', item.periodo_trabalho)}
								</li>
								<li>
									<strong>Localização</strong>
									<br/>
									{item.cidade} - {item.estado}
								</li>
							</ul>
							<NavLink className="btnDefault btnDefault--btn1" to={`/vagas/${item.id}`}>
								Detalhes
							</NavLink>
						</div>
					)
				})}
			</div>
		)
	}

	render() {
		const breakPoints = [
			{width: 1, itemsToShow: 1, itemsToScroll: 1},
			{width: 550, itemsToShow: 2, itemsToScroll: 2},
			{width: 768, itemsToShow: 3, itemsToScroll: 3},
			{width: 1200, itemsToShow: 4, itemsToScroll: 4}
		]

		return (
			<>
				{
					this.state.loading
						? (<Loading/>)
						: (
							this.state.error
								? (<Erro mensagem={this.state.error} tryAgainHandler={this.getData} />)
								: (

									this.props.pagina === "home"
										? (
											this.state.listaOportunidades.length === 0
												? <h1 className="msg-sem-vaga">Nenhuma vaga publicada</h1>
												: <Carousel 
													preventDefaultTouchmoveEvent={true} 
													breakPoints={breakPoints} 
													showArrows={false}>
														{this.sliders()}
												  </Carousel>
										)
										: this.buildVaga()
								)
						)
				}
			</>
		)
	}
}

ListaOportunidades.propTypes = ({
	pagina: PropTypes.oneOf(["home", "vagas"])
})

export default withRouter(ListaOportunidades)
