import React, {Component} from 'react'

import Title from '../../Components/Recrutador/Title'
import VideosCandidato from '../../Components/Recrutador/ListaPerguntasCandidato'
import {get} from "../../Helpers/request"
import Loading from "../../Components/Loading"
import Erro from "../../Components/ErrorMessage"
import {setVagaCandidato, unsetVagaCandidato} from "../../Actions/vagaCandidato"
import connectWithStore from "../../Stores/connectWithStore"
import {setToast} from "../../Actions/util"
import InfoBioCandidatoCompartilhado from "../../Components/Recrutador/Compartilhado/InfoBioCandidato"
import AvatarCompartilhado from "../../Components/Recrutador/Compartilhado/Avatar"
import InfoVagaCompartilhado from "../../Components/Recrutador/Compartilhado/InfoVaga"
import LayoutCompartilhado from "../../Components/Recrutador/Layout/compartilhado"
import BtnCvRecrutador from "../../Components/Buttons/cvRecrutador"
import Avaliacao from "../../Components/Recrutador/Avaliacao"

class CandidatoCompartilhado extends Component {
	_isMounted = false
	state = {
		loadingCandidato: true,
		errorCandidato: undefined,
	}

	getCandidato = (e) => {
		e && e.preventDefault()

		this.setState(() => ({
			errorCandidato: false,
			loadingCandidato: true,
		}))

		get(`vaga/compartilhado/${this.props.match.params.hash}`)
			.then(({data}) => {
				this._isMounted && this.props.setVagaCandidato(data.candidato)
			})
			.catch((err) => {
				if (err.response && err.response.status === 404) {
					if (this._isMounted) {
						this.props.history.replace('/404')
					}
				}
				else {
					this._isMounted && this.setState(() => ({
						errorCandidato: err.response && err.response.data.message ? err.response.data.message : err.message
					}))
				}
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loadingCandidato: false,
				}))
			})
	}

	componentDidMount() {
		this._isMounted = true
		this.getCandidato()
	}

	componentWillUnmount() {
		this._isMounted = false
		this.props.unsetVagaCandidato()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.idVaga !== this.props.match.params.idVaga || prevProps.match.params.idVagaCandidato !== this.props.match.params.idVagaCandidato) {
			this.props.unsetVagaCandidato()
			this.getCandidato()
		}
	}

	render() {
		return (
			<LayoutCompartilhado>
				{this.state.loadingCandidato
					? (<Loading/>)
					: (
						this.state.errorCandidato
							? (
								<Erro mensagem={this.state.errorCandidato} tryAgainHandler={this.getCandidato}/>
							)
							: (
								<>
									<Title title='Resumo da vaga'/>

									<InfoVagaCompartilhado vaga={this.props.vagaCandidato.vaga}/>
									<Title title='Dados do candidato'/>

									<section
										className="box-edicao-perfil-candidato box-edicao-perfil-candidato--dadosPessoais">
										<AvatarCompartilhado foto={this.props.vagaCandidato.perfil.foto} />
										<InfoBioCandidatoCompartilhado user={this.props.vagaCandidato.perfil}/>
										<div className="downloads">
											<BtnCvRecrutador curriculo={this.props.vagaCandidato.perfil.curriculo} curriculoTexto={this.props.vagaCandidato.perfil.curriculo_texto}/>
										</div>
									</section>

									<VideosCandidato readOnly={true}/>

									<Avaliacao readOnly={true}/>
								</>
							)
					)
				}
			</LayoutCompartilhado>
		)
	}
}


const mapStateToProps = (state) => ({
	vagaCandidato: state.vagaCandidato
})

const mapDispatchToProps = (dispatch) => ({
	setVagaCandidato: (vagaCandidato) => dispatch(setVagaCandidato(vagaCandidato)),
	unsetVagaCandidato: () => dispatch(unsetVagaCandidato()),
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(CandidatoCompartilhado)
