import React, {Component} from 'react'
import Layout from '../../Components/Recrutador/Layout'
import Title from '../../Components/Recrutador/Title'
import DetalheVagaContent from '../../Components/Recrutador/DetalheVaga'
import CandidatosLista from '../../Components/Recrutador/ListaCandidatos'
import PageMeta from '../../Components/PageMeta'

class DetalheVaga extends Component {
	render() {
		return (
			<Layout>
				<PageMeta
					title="Vagas"
					description="description da tela de vagas"
					keywords="Vaga, Momentum, Recrutamento"
				/>

				<Title title='Detalhes da vaga'/>

				<DetalheVagaContent
					id={this.props.match.params.id}
				/>

				<section className="ultimos-candidatos">
					<div className="label label--pendente">Pendentes</div>
					<CandidatosLista
						idVaga={this.props.match.params.id}
						tipo={'pendente'}
					/>
				</section>

				<section className="ultimos-candidatos">
					<div className="label label--aprovados">Aprovados</div>
					<CandidatosLista
						idVaga={this.props.match.params.id}
						tipo={'aprovado'}
					/>
				</section>

				<section className="ultimos-candidatos">
					<div className="label label--reprovados">Reprovados</div>
					<CandidatosLista
						idVaga={this.props.match.params.id}
						tipo={'reprovado'}
					/>
				</section>

			</Layout>
		)
	}
}

export default DetalheVaga