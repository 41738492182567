import React from 'react'
import {NavLink} from 'react-router-dom'
import PageMeta from '../../Components/PageMeta'
import Footer from '../../Components/Site/Footer'

const Page404 = () => (
    <>
        <section className="page404">
            <PageMeta title="Página não encontrada" />
            <div>
                <h1 className="tituloSite">Página não encontrada</h1>
                <p>Desculpe, a página que você tentou acessar não está disponível no momento.</p>
                <NavLink to="/" exact className="btnDefault btnDefault--btn1">
                    Página inicial
                </NavLink>
            </div>

        </section>
        <Footer />
    </>
)

export default Page404