import React, {Component} from "react"
import {login, logout} from "../../../Actions/user"
import {abreModal, alertaErro, setErros, setToast} from "../../../Actions/util"
import connectWithStore from "../../../Stores/connectWithStore"
import PropTypes from "prop-types"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck, faTimes, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons"
import CircularProgress from "@material-ui/core/CircularProgress"
import {labelDicAuxiliarCandidato, maskData, maskTelefone} from "../../../Helpers/util"
import MaskedInput from "react-text-mask/dist/reactTextMask"
import {patch} from "../../../Helpers/request"
import moment from "moment"
import dicAuxiliarCandidato from '../../../Data/dicionarioCandidato.json'
import Tooltip from '@material-ui/core/Tooltip'

const valorInicial = (valor, mask) => {
	if (!valor) {
		return ''
	}
	if (mask === 'data') {
		return moment(valor, 'YYYY-MM-DD').format('DD/MM/YYYY') || ''
	}
	return valor
}

class Input extends Component {
	_isMounted = false
	state = {
		loading: false,
		erroMensagem: undefined,
		valor: valorInicial(this.props.user[this.props.campo], this.props.mask),
		showInput: false,
	}

	showInput = (e) => {
		e && e.preventDefault()
		this.props.abreModal('input', {
			campo: this.props.campo,
			inputType: this.props.inputType,
			mask: this.props.mask,
			placeholder: this.props.placeholder,
		})

		/*!this.props.readOnly && this.setState(() => ({
			showInput: true
		}))*/
	}

	hideInput = (e) => {
		e && e.preventDefault()
		this.setState(() => ({
			showInput: false,
			valor: valorInicial(this.props.user[this.props.campo], this.props.mask)
		}))
	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	onChange = (e) => {
		const obj = {}
		obj[e.target.name] = e.target.value
		this.setState(() => (obj))
	}

	inputMask = (props) => {
		const maskList = {
			telefone: maskTelefone,
			data: maskData
		}

		return (
			<MaskedInput mask={maskList[this.props.mask]} guide={false} {...props} />
		)
	}

	inputComum = (props) => {
		return (<input {...props} maxLength={255}/>)
	}

	onSubmit = (e) => {
		e && e.preventDefault()

		this.setState(() => ({
			loading: true,
			erroMensagem: undefined,
		}))
		const dados = {}
		dados[this.props.campo] = this.state.valor
		if (this.props.mask === "data") {
			//se der invalid date, vai gerar erro, então está tudo bem
			dados[this.props.campo] = moment(this.state.valor, 'DD/MM/YYYY').format('YYYY-MM-DD')
		}

		patch('users', dados)
			.then(({data}) => {
				if (data.status) {
					const user = {...this.props.user}
					user[this.props.campo] = this.props.mask === "data"
						? moment(this.state.valor, 'DD/MM/YYYY').format('YYYY-MM-DD')
						: this.state.valor
					this.props.login(user)

					if (this.props.erros) {
						const novosErros = Object.assign({}, this.props.erros)
						delete novosErros[this.props.campo]
						this.props.setErros(novosErros)
					}

					this._isMounted && this.hideInput()
				}
				else {
					this._isMounted && this.props.alertaErro("", data.message)
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					this.props.setToast('Sua sessão expirou', 'error')
					this.props.logout()
					return
				}
				//erro controlado
				if (err.response) {
					this._isMounted && this.props.alertaErro("", err.response.data.message)
				}
				//erro aleatório
				else {
					this._isMounted && this.props.alertaErro(err.response && err.response.data.message ? err.response.data.message : err.message)
				}
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})
	}

	render() {
		const InputComponent = this.props.mask
			? this.inputMask
			: this.inputComum

			const WrapperTooltipInput = ({children, readOnly, onClick}) => readOnly 
				? <p>{children}</p>
				: <Tooltip arrow onClick={onClick} title={"Editar informações"}><p style={{cursor: 'pointer'}}>{children}</p></Tooltip>

		return (
			<>
				{this.state.loading
					? ( <CircularProgress className="circle-loading" size={20} /> ) 
					: (
						<>
							{!this.props.readOnly && !this.props.user[this.props.campo] && !this.state.showInput &&
							<button
								type="button"
								onClick={this.showInput}
								className={this.props.erros && this.props.erros[this.props.campo] ? "btn-add btn-error-alert" : "btn-add"}
							>
								Clique para adicionar
							</button>
							}
							{this.state.showInput
								? (
									<div className="box-edicao">
										<form onSubmit={this.onSubmit}>
											{this.props.inputType === 'select'?(
												<select
													name="valor"
													value={this.state.valor}
													onChange={this.onChange}
													autoFocus
													className="inputDefault inputDefault--edicao-dados-pessoais">
													<option value="">Selecione</option>
													{dicAuxiliarCandidato[this.props.campo].map((v, k) => (
														<option value={v.value} key={k}>{v.label}</option>
													))}
												</select>

												):(
												<InputComponent
													type={this.props.inputType}
													name="valor"
													onChange={this.onChange}
													value={this.state.valor}
													placeholder={this.props.placeholder}
													autoFocus
													className="inputDefault inputDefault--edicao-dados-pessoais"
												/>
											)}
											<button
												type="submit"
												className="btn-save-input">
												<FontAwesomeIcon icon={faCheck}/>
											</button>
											<button
												type="button"
												className="btn-fechar-input"
												onClick={this.hideInput}>
												<FontAwesomeIcon icon={faTimes}/>
											</button>
										</form>
									</div>
								)
								: (
									this.props.user[this.props.campo] && !this.state.showInput &&
									   <WrapperTooltipInput readOnly={this.props.readOnly} onClick={this.showInput}>
										  {this.props.inputType === 'select'
											 ? (
												labelDicAuxiliarCandidato(this.props.campo, this.props.user[this.props.campo])
											 )
											 : (
												this.props.mask === 'data'
												   ? moment(this.props.user[this.props.campo], 'YYYY-MM-DD').format('DD/MM/YYYY')
												   : this.props.user[this.props.campo]
											 )}
									   </WrapperTooltipInput>
								 )
							}
						</>
					)}
				{this.props.erros && this.props.erros[this.props.campo] && (<FontAwesomeIcon icon={faExclamationTriangle}/>)}
			</>
		)
	}
}

Input.defaultProps = {
	readOnly: false,
	inputType: "text"
}

Input.propTypes = {
	campo: PropTypes.string.isRequired,
	readOnly: PropTypes.bool,
	inputType: PropTypes.oneOf(["text", "email", "select"]),
	mask: PropTypes.oneOf(["telefone", "data"]),
	placeholder: PropTypes.string,
}


const mapStateToProps = (state) => ({
	user: state.user,
	erros: state.util.erros,
})

const mapDispatchToProps = (dispatch) => ({
	login: (user) => dispatch(login(user)),
	alertaErro: (alertaTitulo, alertaMensagem) => dispatch(alertaErro(alertaTitulo, alertaMensagem)),
	abreModal: (tipo, modalExtra) => dispatch(abreModal(tipo, modalExtra)),
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
	logout: () => dispatch(logout()),
	setErros: (erros) => dispatch(setErros(erros)),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(Input)
