import React from 'react'
import BtnAddWhatapp from '../../Buttons/addWhatsapp'
import InfosContato from './InfosContato'
import Avatar from '../../Avatar'
import PropTypes from "prop-types"
import BtnAddCvCompacto from "../../Buttons/addCvCompacto"

const DadosPessoais = props => (
	<>
		<h2 className="tituloSite2">{props.titulo}</h2>

		<section className="box-edicao-perfil-candidato box-edicao-perfil-candidato--dadosPessoais">
			<Avatar/>
			<InfosContato/>
			<div className="downloads">
				{props.exibeBtCv && <BtnAddCvCompacto/>}
				<BtnAddWhatapp/>
			</div>
		</section>
	</>
)

DadosPessoais.defaultProps = {
	exibeBtCv: true,
}

DadosPessoais.propTypes = {
	exibeBtCv: PropTypes.bool.isRequired
}

export default DadosPessoais