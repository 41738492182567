import store from '../Stores/configureStore'
export const isCandidato = () => {
	const state = store.getState()
	return state.user && state.user.tipo === 'candidato'
}
export const isRecrutador = () => {
	const state = store.getState()
	return state.user && state.user.tipo === 'recrutador'
}

export const isLoggedIn = () => {
	const state = store.getState()
	return !!state.user
}

