import React, {Component} from 'react'
import PropTypes from "prop-types"
import {urlFotoPerfil} from "../../../Helpers/util"

class AvatarCompartilhado extends Component {
	componentDidUpdate(prevProps, prevState, snapshot) {

	}

	render() {
		return (
			<div className="avatar-base">
				<div
					className={this.props.foto === null ? 'avatar share' : 'avatar avatar--com-avatar share'}
					style={{backgroundImage: `url(${urlFotoPerfil(this.props.foto, 150, 150, 'fit')})`}}>
				</div>

			</div>

		)
	}
}

AvatarCompartilhado.propTypes = {
	foto: PropTypes.object
}

export default AvatarCompartilhado
