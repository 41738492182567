import React from 'react'

const UnsupportedView = () => (
  <div style={{textAlign: 'center'}}>
      Esse navegador é incompatível com gravação de vídeo.
      <br /><br />
        Por favor utilize outro navegador para acessar esse recurso.
  </div>
)

export default UnsupportedView