import React, {Component} from "react"
import {alerta} from "../../Actions/util"
import connectWithStore from "../../Stores/connectWithStore"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTimes} from "@fortawesome/free-solid-svg-icons"
import Sucesso from "./Sucesso"
import Modal from "react-modal"
import ModalErro from "./ModalErro"

class ModalAlerta extends Component {
	onRequestClose = (e) => {
		this.props.alertaExtra && this.props.alertaExtra.onRequestClose? this.props.alertaExtra.onRequestClose(e): this.props.fechaModal()
	}
	componentDidMount() {
	}

	render() {
		return (
			<Modal
				{...this.props}
				isOpen
				className={`Modal Modal__alerta_${this.props.alertaTipo}`}
				overlayClassName="Overlay"
				onRequestClose={this.onRequestClose}
			>
				<div className="modalContent">
					<button className="close-modal" onClick={this.onRequestClose}>
						<FontAwesomeIcon icon={faTimes}/>
					</button>
					{this.props.alertaTipo === 'sucesso' && (
						<Sucesso titulo={this.props.alertaTitulo} texto={this.props.alertaMensagem} {...this.props.alertaExtra}/>
					)}
					{this.props.alertaTipo === 'erro' && (
						<ModalErro titulo={this.props.alertaTitulo} texto={this.props.alertaMensagem} {...this.props.alertaExtra}/>
					)}
				</div>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	alertaTipo: state.util.alertaTipo,
	alertaTitulo: state.util.alertaTitulo,
	alertaMensagem: state.util.alertaMensagem,
	alertaExtra: state.util.alertaExtra
})

const mapDispatchToProps = (dispatch) => ({
	fechaModal: () => dispatch(alerta(undefined))
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(ModalAlerta)
