import React from 'react'
import { NavLink } from 'react-router-dom'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const BtnCriarVaga = () => (
    <>
        <NavLink to="/recrutador/vagas/criar" className="btn-criar-vaga">
            <FontAwesomeIcon icon={faPlus} />
            Criar vaga
        </NavLink>
    </>
)

export default BtnCriarVaga