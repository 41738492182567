import Modal from "react-modal"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTimes} from "@fortawesome/free-solid-svg-icons"
import React, {Component} from "react"
import {post} from "../../Helpers/request"
import {login} from "../../Actions/user"
import CircularProgress from "@material-ui/core/CircularProgress"
import Erro from "./Erro"
import connectWithStore from "../../Stores/connectWithStore"
import {isLoggedIn} from "../../Helpers/user"
import {abreModal, alertaSucesso, fechaModal} from "../../Actions/util"

class EsqueciMinhaSenha extends Component {
	_isMounted = false
	state = {
		email: '',
		loading: false,
		erroMensagem: undefined,
		erroCampos: [],
	}

	onChange = (e) => {
		const obj = {}
		obj[e.target.name] = e.target.value
		this.setState(() => (obj))
	}

	onSubmit = (e) => {
		e.preventDefault()
		this.setState(() => ({
			loading: true,
			erroMensagem: undefined,
			erroCampos: []
		}))
		const dados = {
			email: this.state.email,
		}
		post('users/reset_pass', dados)
			.then(({data}) => {
				if (data.status) {
					this.props.onRequestClose()
					this.props.alertaSucesso(data.message)
				}
				else {
					this.setState(() => ({
						erroMensagem: data.erros ? undefined : data.message,
						erroCampos: data.erros || {}
					}))
				}
			})
			.catch((err) => {
				//erro controlado
				if (err.response) {
					this._isMounted && this.setState(() => ({
						erroMensagem: err.response.data.erros ? undefined : err.response.data.message,
						erroCampos: err.response.data.erros || {}
					}))
				}
				//erro aleatório
				else {
					this._isMounted && this.setState(() => ({
						erroMensagem: err.response && err.response.data.message ? err.response.data.message : err.message,
					}))
				}
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (isLoggedIn()) {
			this.props.onRequestClose()
		}
	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		return (
			<Modal
				{...this.props}
				isOpen
				className="Modal Modal__login"
				overlayClassName="Overlay"
			>
				<div className="modalContent">
					<button className="close-modal" onClick={this.props.onRequestClose}>
						<FontAwesomeIcon icon={faTimes}/>
					</button>
					<h1 className="modalContent__titulo">Recuperar senha</h1>
					<form className="modal-form" onSubmit={this.onSubmit}>
						<div className="modal-form__item">
							<label
								htmlFor="email"
								className="labelDefault">
								E-mail*
							</label>
							<input
								type="email"
								id="email"
								name="email"
								className="inputDefault"
								placeholder="Digite seu e-mail"
								maxLength={255}
								value={this.state.email}
								onChange={this.onChange}
							/>
						</div>
						{this.state.erroMensagem &&
						<div className="modal-form__item">
							<Erro mensagem={this.state.erroMensagem}/>
						</div>
						}
						<div className="modal-form__item submitWrap">
							<button
								type="submit"
								className="btnDefault btnDefault--btn4 btnDefault--loading"
								disabled={this.state.loading}
							>
								{this.state.loading ?
									<>
										<CircularProgress className="circle-loading" size={20} />
										Recuperando
									</>
									: 'Recuperar'}
							</button>
						</div>
					</form>
				</div>
			</Modal>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	login: (user) => dispatch(login(user)),
	onRequestClose: () => dispatch(fechaModal()),
	abreModal: (tipo) => dispatch(abreModal(tipo)),
	alertaSucesso: (alertaTitulo, alertaMensagem) => dispatch(alertaSucesso(alertaTitulo, alertaMensagem)),
})

export default connectWithStore(null, mapDispatchToProps)(EsqueciMinhaSenha)
