import React from 'react'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {logout} from "../../../Actions/user"
import connectWithStore from "../../../Stores/connectWithStore"
import {del} from "../../../Helpers/request"
import {setToast} from "../../../Actions/util"

const Logout = ({ linkShrink, marginSVG, logout, setToast, history }) => {
    const onLogout = (e) => {
        e.preventDefault()
        setToast(undefined)
        del('users/session')
            .then(({data}) => {
                history.push('/')
                logout()
            })
            .catch((err) => {
                const erroMensagem = err.response && err.response.data.message ? err.response.data.message : err.message
                setToast(erroMensagem, 'error')
            })
    }
    return(
        <div className="sidebarNav__item sidebarNav__item--logoutWrapper">
            <button
                type="button"
                className={linkShrink}
                onClick={onLogout}>
                    <FontAwesomeIcon icon={faSignOutAlt} className={marginSVG} />
                    <span>Fazer logout</span>
            </button>

        </div>
    )
}

const mapDispatchToProp = (dispatch) => ({
    logout: () => dispatch(logout()),
    setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
})

export default connectWithStore(null, mapDispatchToProp)(Logout)
