import styled from 'styled-components'

const Button = styled.button`
  background: ${props => props.backgroundColor};
  color: ${props => props.color};
  border: none;
  border-radius: 25px;
  display: inline-block;
  font-size: 15px;
  line-height: initial;
  padding: 9px 30px 10px 30px;
  font-weight: 700;
  transition: all .3s ease-in;
  margin: 0 10px;

  @media(max-width: 550px) {
    padding: 8px 20px;
  }

  :hover {
    box-shadow: inset 0 0 10px rgba(0,0,0,0.4);
  }
`

Button.defaultProps = {
	color: 'white',
	backgroundColor: '#f43b65'
}

export default Button