import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  background: ${props => props.backgroundColor};
  color: ${props => props.color};
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background: rgba(227, 73, 28, 0.8);
  outline: none;
  border: none;
  cursor: pointer;

  :hover {
    background: #fb6d42;
  }
`

const RecWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonBorder = styled.div`
  border: 5px solid rgba(255, 255, 255, 0.4);
  height: 70px;
  width: 70px;
  border-radius: 50%;
`
const Instructions = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 17px;
  color: #ffffff;
  display: flex;
  margin-bottom: 20px;
`

const InstuctionsHighlight = styled.div`
  font-weight: 700;
  color: #dc6547;
  padding: 0 5px;
`

Button.defaultProps = {
	color: 'black',
	backgroundColor: 'white'
}

export default props => (
	<RecWrapper>
		<Instructions>
      <p>Pressione</p> 
      <InstuctionsHighlight> REC </InstuctionsHighlight>
      <p>quando estiver pronto</p>
		</Instructions>

		<ButtonBorder>
			<Button {...props} />
		</ButtonBorder>
	</RecWrapper>
)