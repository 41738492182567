import Modal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import React, { Component } from 'react'
import { post } from '../../Helpers/request'
import CircularProgress from '@material-ui/core/CircularProgress'
import { maskTelefone } from '../../Helpers/util'
import Erro from './Erro'
import Sucesso from './Sucesso'
import { fechaModal, setToast } from '../../Actions/util'
import connectWithStore from '../../Stores/connectWithStore'
import MaskedInput from 'react-text-mask/dist/reactTextMask'
import { Tooltip } from '@material-ui/core'

class Contato extends Component {
  _isMounted = false
  state = {
    nome: this.props.user ? this.props.user.first_name : '',
    email: this.props.user ? this.props.user.email : '',
    telefone: this.props.user ? this.props.user.telefone : '',
    mensagem: '',
    chk_lgpd_contato: false,
    loading: false,
    erroMensagem: undefined,
    erroCampos: [],
    sucessoMensagem: undefined,
  }

  onSubmit = e => {
    e.preventDefault()

    this.setState(() => ({
      loading: true,
      erroMensagem: undefined,
      erroCampos: [],
    }))
    const dados = {
      nome: this.state.nome,
      email: this.state.email,
      telefone: this.state.telefone,
      mensagem: this.state.mensagem,
    }
    post('contato', dados)
      .then(({ data }) => {
        if (data.status) {
          if (this._isMounted) {
            this.setState(() => ({
              sucessoMensagem: data.message,
            }))
          } else {
            this.props.setToast(data.message, 'success')
          }
        } else {
          this._isMounted &&
            this.setState(() => ({
              erroMensagem: data.erros ? undefined : data.message,
              erroCampos: data.erros || {},
            }))
        }
      })
      .catch(err => {
        //erro controlado
        if (err.response) {
          this._isMounted &&
            this.setState(() => ({
              erroMensagem: err.response.data.erros
                ? undefined
                : err.response.data.message,
              erroCampos: err.response.data.erros || {},
            }))
        }
        //erro aleatório
        else {
          this._isMounted &&
            this.setState(() => ({
              erroMensagem:
                err.response && err.response.data.message
                  ? err.response.data.message
                  : err.message,
            }))
        }
      })
      .finally(() => {
        this._isMounted &&
          this.setState(() => ({
            loading: false,
          }))
      })
  }

  onChange = e => {
    const obj = {}
    obj[e.target.name] = e.target.value
    this.setState(() => obj)
  }

  onChangeCheckbox = e => {
    const obj = {}
    obj[e.target.name] = e.target.checked ? e.target.value : false
    this.setState(() => obj)
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    return (
      <Modal
        {...this.props}
        isOpen
        className="Modal Modal__contato"
        overlayClassName="Overlay"
      >
        <div className="modalContent">
          <button className="close-modal" onClick={this.props.onRequestClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          {this.state.sucessoMensagem ? (
            <Sucesso titulo={this.state.sucessoMensagem} />
          ) : (
            <>
              <h1 className="modalContent__titulo">
                Entre em contato conosco!
              </h1>
              <form className="modal-form" onSubmit={this.onSubmit}>
                <div className="modal-form__item">
                  <label htmlFor="nome" className="labelDefault">
                    Nome completo*
                  </label>
                  <input
                    type="text"
                    id="nome"
                    name="nome"
                    className={`inputDefault ${
                      this.state.erroCampos.nome ? 'erro' : ''
                    }`}
                    placeholder="Digite seu nome"
                    value={this.state.nome}
                    onChange={this.onChange}
                    maxLength="100"
                  />
                  {this.state.erroCampos.nome && (
                    <Erro mensagem={this.state.erroCampos.nome} />
                  )}
                </div>
                <div className="modal-form__item">
                  <label htmlFor="email" className="labelDefault">
                    E-mail*
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className={`inputDefault ${
                      this.state.erroCampos.email ? 'erro' : ''
                    }`}
                    placeholder="Digite seu e-mail"
                    value={this.state.email}
                    onChange={this.onChange}
                    maxLength="255"
                  />
                  {this.state.erroCampos.email && (
                    <Erro mensagem={this.state.erroCampos.email} />
                  )}
                </div>
                <div className="modal-form__item">
                  <label htmlFor="tel" className="labelDefault">
                    Telefone*
                  </label>
                  <MaskedInput
                    type="text"
                    id="tel"
                    name="telefone"
                    className={`inputDefault ${
                      this.state.erroCampos.telefone ? 'erro' : ''
                    }`}
                    placeholder="(xx) xxxx-xxxx"
                    value={this.state.telefone}
                    onChange={this.onChange}
                    mask={maskTelefone}
                    guide={false}
                  />
                  {this.state.erroCampos.telefone && (
                    <Erro mensagem={this.state.erroCampos.telefone} />
                  )}
                </div>
                <div className="modal-form__item">
                  <label htmlFor="mensagem" className="labelDefault">
                    Mensagem*
                  </label>
                  <textarea
                    className={`inputDefault inputDefault--textarea ${
                      this.state.erroCampos.mensagem ? 'erro' : ''
                    }`}
                    id="mensagen"
                    name="mensagem"
                    placeholder="Digite aqui a sua mensagem"
                    value={this.state.mensagem}
                    onChange={this.onChange}
                  ></textarea>
                  {this.state.erroCampos.mensagem && (
                    <Erro mensagem={this.state.erroCampos.mensagem} />
                  )}
                </div>
                <div className="modal-form__item">
                  <label htmlFor="chk-lgpd-contato" className="labelDefault">
                    <input
                      type="checkbox"
                      id="chk-lgpd-contato"
                      className=""
                      name="chk_lgpd_contato"
                      value="1"
                      onChange={this.onChangeCheckbox}
                    />
                    {` `}Ao enviar este formulário estou de acordo com a{` `}
                    <a
                      href="https://www.momentum.com.br/politica-de-privacidade"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Política de Privacidade
                    </a>{' '}
                    da Momentum.
                  </label>
                </div>
                {this.state.erroMensagem && (
                  <div className="modal-form__item">
                    <Erro mensagem={this.state.erroMensagem} />
                  </div>
                )}
                <div className="modal-form__item submitWrap">
                  {this.state.chk_lgpd_contato ? (
                    <button
                      type="submit"
                      className="btnDefault btnDefault--btn4 btnDefault--loading"
                      disabled={this.state.loading}
                    >
                      {this.state.loading ? (
                        <>
                          <CircularProgress
                            className="circle-loading"
                            size={20}
                          />
                          Enviando
                        </>
                      ) : (
                        'Enviar'
                      )}
                    </button>
                  ) : (
                    <Tooltip
                      title={
                        'Antes de enviar, confirme estar de acordo com a nossa Política de Privacidade.'
                      }
                      arrow
                      placement="top"
                    >
                      <span>
                        <button
                          type="submit"
                          className="btnDefault btnDefault--btn4 btnDefault--loading btnDefault--tooltip"
                          disabled={true}
                        >
                          {this.state.loading ? (
                            <>
                              <CircularProgress
                                className="circle-loading"
                                size={20}
                              />
                              Enviando
                            </>
                          ) : (
                            'Enviar'
                          )}
                        </button>
                      </span>
                    </Tooltip>
                  )}
                </div>
              </form>
            </>
          )}
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
})

const mapDispatchToProps = dispatch => ({
  setToast: (toastMessage, toastType, onToastClose) =>
    dispatch(setToast(toastMessage, toastType, onToastClose)),
  onRequestClose: () => dispatch(fechaModal()),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(Contato)
