import React, {Component} from 'react'
import {post, put} from "../../../Helpers/request"
import {setToast} from "../../../Actions/util"
import {logout} from "../../../Actions/user"
import connectWithStore from "../../../Stores/connectWithStore"
import Box from "@material-ui/core/Box"
import Rating from "@material-ui/lab/Rating"
import CircularProgress from "@material-ui/core/CircularProgress"
import Erro from "../../Modal/Erro"
import PropTypes from "prop-types"
import {addVagaCandidatoAvaliacao, editVagaCandidatoAvaliacao} from "../../../Actions/vagaCandidatoAvaliacao"

class Criar extends Component {
	_isMounted = false
	state = {
		loading: false,
		error: undefined,

		nota: 0,
		comentario: '',
	}

	componentDidMount() {
		this._isMounted = true
		if (this.props.avaliacao) {
			this.setState(() => ({
				nota: this.props.avaliacao.nota,
				comentario: this.props.avaliacao.comentario,
			}))
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	onSubmit = (e) => {
		e.preventDefault()
		this.setState(() => ({
			loading: true,
			error: undefined,
		}))

		const dados = {
			nota: this.state.nota,
			comentario: this.state.comentario,
		}
		//para evitar a mensagem de erro de que o campo deve ter um valor maior que zero
		if (dados.nota === 0) {
			delete dados.nota
		}

		let metodo = post
		let url = `recrutador/vaga/${this.props.vagaCandidato.vaga.id}/candidato/${this.props.vagaCandidato.id}/avaliacao`
		let onSuccess = this.props.addVagaCandidatoAvaliacao
		if (this.props.avaliacao) {
			url = `${url}/${this.props.avaliacao.id}`
			metodo = put
			onSuccess = this.props.editVagaCandidatoAvaliacao
		}
		metodo(url, dados)
			.then(({data}) => {
				this.props.setToast(data.message, 'success')
				this._isMounted && this.setState(() => ({
					nota: 0,
					comentario: '',
				}))
				onSuccess(data.avaliacao)
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					this.props.setToast('Sua sessão expirou', 'error')
					this.props.logout()
					return
				}
				//erro controlado
				if (err.response) {
					this._isMounted && this.setState(() => ({
						error: err.response.data.message,
					}))
				}
				//erro aleatório
				else {
					this._isMounted && this.setState(() => ({
						error: err.response && err.response.data.message ? err.response.data.message : err.message,
					}))
				}

			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})
	}

	onChange = (e) => {
		const obj = {}
		obj[e.target.name] = e.target.value
		this.setState(() => (obj))
	}

	onChangeNota = (e, nota) => {
		this.setState(() => ({
			nota
		}))
	}

	onCancel = (e) => {
		e.preventDefault()
		//força atualização para fechar a edição
		this.props.editVagaCandidatoAvaliacao(Object.assign({}, this.props.avaliacao))
	}

	render() {
		return (
			<section className="criar">
				<h1 className="criar__titulo">
					{this.props.avaliacao? 'Editar': 'Fazer'} comentário
				</h1>
				<form onSubmit={this.onSubmit}>
					<Box component="fieldset" mb={3} borderColor="transparent">
						<Rating
							name="nota"
							value={this.state.nota}
							onChange={this.onChangeNota}
						/>
					</Box>

					<textarea
						className="inputDefault"
						placeholder="Escreva aqui o seu comentário"
						name="comentario"
						value={this.state.comentario}
						onChange={this.onChange}
					></textarea>

					{this.state.error &&(
						<Erro mensagem={this.state.error}/>
					)}

					<div className="criar__wrapButtons">
						<button
							type="submit"
							className="btnDefault btnDefault--btn4 btnDefault--loading"
							disabled={this.state.loading}
						>
							{this.state.loading ?
								<>
									<CircularProgress className="circle-loading" size={20}/>
									Enviando comentário
								</>
								: 'Enviar comentário'}
						</button>
						{this.props.avaliacao && (
							<button
								onClick={this.onCancel}
								className="btnDefault btnDefault--btn1 "
							>
								Cancelar
							</button>
						)}
					</div>
				</form>
			</section>
		)
	}
}

Criar.propTypes = {
	avaliacao: PropTypes.object,
}

const mapStateToProps = (state) => ({
	vagaCandidato: state.vagaCandidato,
})

const mapDispatchToProps = (dispatch) => ({
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
	logout: () => dispatch(logout()),
	editVagaCandidatoAvaliacao: (avaliacao) => dispatch(editVagaCandidatoAvaliacao(avaliacao)),
	addVagaCandidatoAvaliacao: (avaliacao) => dispatch(addVagaCandidatoAvaliacao(avaliacao)),
})

export default connectWithStore(mapStateToProps, mapDispatchToProps)(Criar)
