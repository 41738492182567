import React, {Component} from "react"
import {login, logout} from "../../../Actions/user"
import {abreModal, alertaErro, setErros, setToast} from "../../../Actions/util"
import connectWithStore from "../../../Stores/connectWithStore"
import PropTypes from "prop-types"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck, faTimes, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons"
import CircularProgress from "@material-ui/core/CircularProgress"
import {patch} from "../../../Helpers/request"
import dicAuxiliarCandidato from '../../../Data/dicionarioCandidato.json'
import Tooltip from '@material-ui/core/Tooltip'

class InputLocalidade extends Component {
	_isMounted = false
	state = {
		loading: false,
		erroMensagem: undefined,
		estado: this.props.user.estado || '',
		cidade: this.props.user.cidade || '',
		showInput: false,
	}

	showInput = (e) => {
		e && e.preventDefault()
		this.props.abreModal('input-localidade')
		/*!this.props.readOnly && this.setState(() => ({
			showInput: true
		}))*/
	}

	hideInput = (e) => {
		e && e.preventDefault()
		this.setState(() => ({
			showInput: false,
			estado: this.props.user.estado || '',
			cidade: this.props.user.cidade || '',
		}))
	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	onChange = (e) => {
		const obj = {}
		obj[e.target.name] = e.target.value
		this.setState(() => (obj))
	}

	onSubmit = (e) => {
		e && e.preventDefault()

		this.setState(() => ({
			loading: true,
			erroMensagem: undefined,
		}))
		const dados = {
			cidade: this.state.cidade,
			estado: this.state.estado
		}
		patch('users', dados)
			.then(({data}) => {
				if (data.status) {
					const user = {...this.props.user}
					user.estado = this.state.estado
					user.cidade = this.state.cidade
					this.props.login(user)

					if (this.props.erros) {
						const novosErros = Object.assign({}, this.props.erros)
						delete novosErros.estado
						delete novosErros.cidade
						this.props.setErros(novosErros)
					}

					this._isMounted && this.hideInput()
				}
				else {
					this._isMounted && this.props.alertaErro("", data.message)
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					this.props.setToast('Sua sessão expirou', 'error')
					this.props.logout()
					return
				}
				//erro controlado
				if (err.response) {
					this._isMounted && this.props.alertaErro("", err.response.data.message)
				}
				//erro aleatório
				else {
					this._isMounted && this.props.alertaErro(err.response && err.response.data.message ? err.response.data.message : err.message)
				}
			})
			.finally(() => {
				this._isMounted && this.setState(() => ({
					loading: false,
				}))
			})
	}

	render() {
		return (
			<>
				{this.state.loading
					? ( <CircularProgress className="circle-loading" size={20} /> ) 
					: (
						<>
							{!this.props.readOnly && !(this.props.user.estado || this.props.cidade) && !this.state.showInput &&
							<button
								type="button"
								onClick={this.showInput}
								className={(this.props.erros && (this.props.erros.cidade || this.props.erros.estado)) ? "btn-add btn-error-alert" : "btn-add"}
							>
								Clique para adicionar
							</button>
							}
							{this.state.showInput
								? (
									<div className="box-edicao">
										<form onSubmit={this.onSubmit}>
											<select
												name="estado"
												value={this.state.estado}
												onChange={this.onChange}
												autoFocus
												className="inputDefault inputDefault--edicao-dados-pessoais estado">
												<option value="">UF</option>
												{dicAuxiliarCandidato.estado.map((v, k) => (
													<option value={v.value} key={k}>{v.value}</option>
												))}
											</select>
											<input
												type="text"
												name="cidade"
												onChange={this.onChange}
												value={this.state.cidade}
												placeholder="Sua cidade"
												maxLength={255}
												autoFocus
												className="inputDefault inputDefault--edicao-dados-pessoais cidade"
											/>
											<button
												type="submit"
												className="btn-save-input">
												<FontAwesomeIcon icon={faCheck}/>
											</button>
											<button
												type="button"
												className="btn-fechar-input"
												onClick={this.hideInput}>
												<FontAwesomeIcon icon={faTimes}/>
											</button>
										</form>
									</div>
								)
								: (
									this.props.user.estado && this.props.user.cidade && !this.state.showInput &&
									<Tooltip title="Editar informação" arrow placement="bottom">
										<p onClick={this.showInput} style={{cursor: 'pointer'}}>
											{this.props.user.cidade}, {this.props.user.estado}
										</p>
									</Tooltip>
								)
							}
						</>
					)}
				{(this.props.erros && (this.props.erros.cidade || this.props.erros.estado)) && (<FontAwesomeIcon icon={faExclamationTriangle}/>)}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
	erros: state.util.erros,
})

const mapDispatchToProps = (dispatch) => ({
	login: (user) => dispatch(login(user)),
	alertaErro: (alertaTitulo, alertaMensagem) => dispatch(alertaErro(alertaTitulo, alertaMensagem)),
	abreModal: (tipo, modalExtra) => dispatch(abreModal(tipo, modalExtra)),
	setToast: (toastMessage, toastType, onToastClose) => dispatch(setToast(toastMessage, toastType, onToastClose)),
	logout: () => dispatch(logout()),
	setErros: (erros) => dispatch(setErros(erros)),
})

InputLocalidade.defaultProps = {
	readOnly: false,
}

InputLocalidade.propTypes = {
	readOnly: PropTypes.bool,
}

export default connectWithStore(mapStateToProps, mapDispatchToProps)(InputLocalidade)
